//import library and types
import axios from 'axios';
import {Urls, Flight as FlightURL, getHeaderRequest} from "../Enums/APIAddress";

class Flight {

    searchFlightToken;

    searchFlight = async (param) => {
        if (this.searchFlightToken) {
            this.searchFlightToken.cancel('operation cancelled');
        }
        this.searchFlightToken = axios.CancelToken.source();
        let header = getHeaderRequest(false);
        try {
            let response = await axios.post(
                `${Urls.Type}${Urls.Flight}${FlightURL.GetFlights}`,
                param,
                {cancelToken: this.searchFlightToken.token, headers: {...header}}
            );
            this.searchFlightToken = null;
            return response.data.result;
        } catch (error) {
            if (axios.isCancel(error)) {
                return {cancelled: true};
            }
            return error
        }
    }
    validateFlight = async (param) => {
        let header = getHeaderRequest(false);
        try {
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.ValidateFlight}?key=${param.key}`, null, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    getPNR = async (param) => {
        try {
            let header = getHeaderRequest(false);
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.GetPNR}`, param, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    getReserveID = async (param) => {
        try {
            let header = getHeaderRequest(false);
            let response = await axios.get(`${Urls.Type}${Urls.Flight}${FlightURL.GetReserveByID}?${param}`, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    getPNRDetails = async (param) => {
        try {
            let header = getHeaderRequest(true);
            let response = await axios.get(`${Urls.Type}${Urls.Flight}${FlightURL.GetPNRDetails}?${param}`, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    getPNRPrice = async (param) => {
        try {
            let header = getHeaderRequest(true);
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.PricePNR}`, param, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    cancelPNR = async (param) => {
        try {
            let header = getHeaderRequest(true);
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.cancelPNR}`, param, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    IssuePNR = async (param) => {
        try {
            let header = getHeaderRequest(true);
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.IssuePNR}?${param}`, null, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    voidPNR = async (param) => {
        try {
            let header = getHeaderRequest(true);
            let response = await axios.post(`${Urls.Type}${Urls.Flight}${FlightURL.VoidTicket}`, param, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    GetDirectTicket = async (param) => {
        try {
            let header = getHeaderRequest(false);
            let response = await axios.get(`${Urls.Type}${Urls.Flight}${FlightURL.GetDirectTicketByID}?${param}`, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }
    GetFlightByReserveID=async(param)=>{
        try {
            let header = getHeaderRequest(false);
            let response = await axios.get(`${Urls.Type}${Urls.Flight}${FlightURL.GetFlightReserveByID}?${param}`, {headers: {...header}});
            return response.data.result;
        } catch (error) {
            return error
        }
    }

}

export default new Flight();