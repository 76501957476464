import React from 'react';
import PropTypes from 'prop-types';
import HotelCancellationPolicy from "./HotelCancellationPolicy";
import {Icon, Tooltip} from "antd";

HotelBoardPolicy.propTypes = {
    rate: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    rooms: PropTypes.array.isRequired
};

function HotelBoardPolicy(props) {
    return (
        <>
            <div className={"margin-bottom-light"}>
                <Tooltip title={props.rate.board.extra}>
                                                <span>
                                                    <strong className={"font-14 margin-right-light"}>{props.rate.board.name}</strong>
                                                    {props.rate.board.extra ? <Icon type="info-circle" className={"margin-right-light"}/> : null}
                                                </span>
                </Tooltip>
            </div>
            <ul className={"simple-bullet-list"}>
                <HotelCancellationPolicy rate={props.rate} rooms={props.rooms} date={props.date}/>
                {props.rate.board.description ? (
                    <li className={"has-list-icon"}>
                        <span className={"breakfast-icon itours-icon icon-small margin-right-light inline-block"}/>
                        <span className={"green inline-block"}> {props.rate.board.description}</span>
                    </li>
                ) : null}
            </ul>
        </>
    );
}

export default HotelBoardPolicy;