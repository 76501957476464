//import library
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {Form, Col, Row, Button, Icon, Pagination, Spin} from 'antd';
import queryString from "query-string";
import FilterForm from '../../../commond/FilterForm';
//import Model
import {Travel, Sort} from "../../../../Enums/Types";

import ListItem from './../../../commond/ListItem';
import FormSearch from "./HotelFormSearch";
//import Actions
import {searchHotel, setHotelSorting} from '../../../../Actions/HotelAction';
//import CSS
import "../../../../CSS/Hotel.scss";

class HotelSearch extends React.Component {
    paginationSize = 30;
    paginationSizeMinus1 = this.paginationSize - 1;

    state = {
        //search parameters
        locationName: null,
        locationCode: null,
        adults: [2],
        children: [0],
        ages: [0],
        night: 0,
        first: 0,
        last: this.paginationSizeMinus1,
        checkInDefault: null,
        checkoutDefault: null,
        //show result
        formValidationRequest: true,
        showHotelResult: false,
        dateInfo: null,
        NationalityCode: 0,
        typeSearch: null,
        //state sort
        salePrice: true,
        name: true,
        rating: true,
        //clickedSort
        sortSelect: 'salePrice'
    };
    //handleSort
    handleSort = (type, reserveMode) => {
        this.setState((prevState) => {
            return {[type]: !prevState[type], "sortSelect": type}
        }, () => {
            this.props.setHotelSorting({'typeSort': type, 'isReverse': reserveMode});
        })
    };
    //calculate number of night
    handleNight = e => {
        if (e.length !== 0) {
            this.setState({night: e[1].diff(e[0], 'days')})
        }
    };
    //receive page number form Page Component
    handleChangeValue = (e) => {
        debugger;
        const last = (e * this.paginationSize) - 1;
        const first = last - this.paginationSizeMinus1;
        this.setState({first: first, last: last});
    };
    //receive location name and code form Autocomplete Component
    bindingAutoComplete = (e) => {

        this.setState({
            locationName: e.selectedText,
            locationCode: e.selectedValue,
            typeSearch: e.type
        })
    };
    //receive Room parameters from ShowRoom Component
    bindingRoomSelect = (e) => {
        this.setState({
            adults: e.adult,
            children: e.children,
            ages: e.age
        })
    };

    componentDidMount() {
        //if in this page we have Search Param URL we call get hotel to search
        if (this.props.location.search) {
            this.getHotel();
            this.setState({
                first: 0,
                last: this.paginationSizeMinus1
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search) {
            if (this.props.location.search !== prevProps.location.search) {
                this.setState({
                    first: 0,
                    last: this.paginationSizeMinus1
                });
                //if in this page available if user change URL we have to search again
                this.getHotel();
            }
        }
        if (this.props.hotels !== prevProps.hotels) {
            this.setState({
                first: 0,
                last: this.paginationSizeMinus1
            })
        }
    }

    //convert URL to Search Param and call Search Action
    getHotel = () => {
        //first we take URL and convert it to URLSearchParam
        let parameters = queryString.parse(this.props.location.search);
        let params = new URLSearchParams();
        parameters.Adults.split(",").forEach((item) => params.append("adults", item));
        parameters.Children.split(",").forEach((item) => params.append("children", item));
        if (parameters.Ages && parameters.Ages.length !== 0) {
            parameters.Ages.split(",").forEach((item) => params.append("ages", item));
        }
        params.append("LocationId", parameters.LocationId);
        params.append("checkin", parameters.CheckIn);
        params.append("checkout", parameters.CheckOut);
        if (parameters.NationalityCode) {
            params.append("NationalityCode", parameters.NationalityCode);
        }
        //pass URLParam to Actions for Search
        this.props.searchHotel(`?${params.toString()}`);
        //change default value and read their new value from URL
        this.setState({
            showHotelResult: true
        });
    };

    render() {

        return (
            <>
                <div className={(this.state.showHotelResult)?"hidden-xs":null}>
                    <FormSearch/>
                </div>
                {this.props.showHotelLoad ?
                    (
                        <div className={"text-center loading-section"}>
                            <Spin tip="loading...">
                            </Spin>
                        </div>
                    ) :
                    this.state.showHotelResult ?
                        (<div className="resultssection">
                            <div className="container">
                                <div className="vr-padding">
                                    <Row gutter={15}>
                                        <Col lg={6} md={8} sm={24} xs={24}>
                                            <FilterForm/>
                                        </Col>
                                        <Col lg={18} md={16} sm={24} xs={24} className={"sortContainer"}>

                                            <div className="margin-bottom">
                                                <Row gutter={[10, 10]}>
                                                    <Col lg={14} xs={24} className={"buttonSort"}>
                                                        <span className={"font-18 green margin-right hotel-results-number"}><b>{this.props.hotels.length}</b> hotel(s) found</span>
                                                        <Button.Group>
                                                            <Button type={(this.state.sortSelect === "salePrice") ? "primary" : null} value={0} onClick={() => {
                                                                this.handleSort(Sort.price, this.state.salePrice)
                                                            }}>
                                                                <span> Price {!this.state.salePrice ? <Icon type="caret-down"/> : <Icon type="caret-up"/>} </span>
                                                            </Button>
                                                            <Button type={(this.state.sortSelect === "name") ? "primary" : null} value={1} onClick={() => {
                                                                this.handleSort(Sort.name, this.state.name)
                                                            }}>
                                                                <span>Name {!this.state.name ? <Icon type="caret-down"/> : <Icon type="caret-up"/>} </span>
                                                            </Button>
                                                            <Button type={(this.state.sortSelect === "rating") ? "primary" : null} value={2} onClick={() => {
                                                                this.handleSort(Sort.rate, this.state.rating)
                                                            }}>
                                                                <span>Rate {!this.state.rating ? <Icon type="caret-down"/> : <Icon type="caret-up"/>} </span>
                                                            </Button>
                                                        </Button.Group>
                                                    </Col>
                                                    <Col lg={10} xs={24} className={"paginationTop"}>
                                                        {this.props.hotels &&
                                                        <Pagination showLessItems={true} className="pull-right" pageSize={this.paginationSize}
                                                                    current={(this.state.last + 1) / this.paginationSize}
                                                                    total={this.props.hotels.length}
                                                                    onChange={this.handleChangeValue}/>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                            <ListItem data={this.props.hotels} last={this.state.last} start={this.state.first} type={Travel.hotel}/>
                                            {this.props.hotels &&
                                            <Pagination showLessItems={true} className="pull-right" pageSize={this.paginationSize}
                                                        current={(this.state.last + 1) / this.paginationSize}
                                                        total={this.props.hotels.length}
                                                        onChange={this.handleChangeValue}/>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>) : null
                }
            </>
        )
    }
}

const WrappedSearchHotelForm = Form.create({
    name: 'normal_login',
})(HotelSearch);
const WithRouter = withRouter(WrappedSearchHotelForm);
//MapToProp
const MapStateToProp = (state) => ({
    hotels: state.Hotel.hotels,
    showHotelLoad: state.Hotel.loadingHotel
});
const MapDispatchToProp = (dispatch) => ({
    searchHotel: (param) => {
        dispatch(searchHotel(param))
    },
    setHotelSorting: (param) => {
        dispatch(setHotelSorting(param))
    }
});
export default connect(MapStateToProp, MapDispatchToProp)(WithRouter);
