import React from 'react';
import {connect} from 'react-redux';
import {Button,Radio,Checkbox, DatePicker, Icon, Input,Card,Row,Col} from "antd";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import moment from "moment";
//import actions
import {getReserves} from "../../../../Actions/ReservesAction";
import {ReserveInput,ReserveType,PaymentType} from "../../../../Enums/Types";
import '../../../../CSS/Reserves.scss';
class FilterSide extends React.Component{
    state={
        param:{
            reserveTypeIds:null,
            reserveId:null,
            phoneNumber:null,
            email:null,
            lastname:null,
            departureFrom:null,
            departureTo:null,
            createFrom:null,
            createTo:null,
            reference:null,
            reserveStatus:null
        }
    }
    convertURLToJson=()=>{
        let param = new ReserveInput();
        let paramURL=queryString.parse(this.props.location.search);
        if(paramURL){
            // if(!Array.isArray(paramURL.reserveStatus)){
            //     paramURL.reserveStatus=[paramURL.reserveStatus];
            // }
            this.setState({
                param:{...param,...paramURL}
            });
        }

    }
    componentDidMount() {
        this.convertURLToJson();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location!==this.props.location){
            this.convertURLToJson();
        }
    }
    bindStatusFilter=(e)=>{
        ;
        let statusArr=this.state.param.reserveStatus;
        if(!statusArr){
            statusArr=[];
        }else{
            if(!Array.isArray(statusArr)){
                statusArr=[statusArr];
            }
        }
        if(e.target.checked){
            if(Array.isArray(e.target.value)){
                statusArr=statusArr.concat(e.target.value)
            }else{
                statusArr.push(e.target.value);

            }
        }else{
            if(Array.isArray(e.target.value)){
                let index=statusArr.indexOf(e.target.value[0]);
                statusArr.splice(index,e.target.value.length);
            }else{
                let index=statusArr.indexOf(e.target.value);
                statusArr.splice(index,1);
            }
        }
        this.setState({
            param:{
                ...this.state.param,
                reserveStatus:statusArr
            }

        })

    }
    clearStatusFilter=()=>{
        this.props.history.push("reserves");

    }
    bindReserveTypeIds(e){
        this.setState({param:{...this.state.param,reserveTypeIds:e.target.value}},()=>{
            this.bindFilter();
        })
    }
    bindFilter=()=>{
            let param = new ReserveInput();
            let paramURL=queryString.stringify({...param,...this.state.param,pageNumber:1});
            this.props.history.push("reserves?"+paramURL);

    }
    bindingDate=(e,fieldname)=>{
        let dep,ret;
        if(e.length){
            dep=moment(e[0]).format("YYYY-MM-DD");
            ret=moment(e[1]).format("YYYY-MM-DD");
        }else{
            dep=null;
            ret=null;
        }
        this.setState({
            param:{
                ...this.state.param,
                [fieldname[0]]:dep,
                [fieldname[1]]:ret
            }
        })
    }
    render() {
        return(
            <Card className="reserve-list-filter-card">
                <Radio.Group className="margin-bottom-light" value={this.state.param.reserveTypeIds} onChange={(e)=>this.bindReserveTypeIds(e)}>
                    <Radio.Button className="filter-reserves" value={[ReserveType.FLIGHT_GLOBAL,ReserveType.HOTEL_GLOBAL,ReserveType.PNR_OUTSIDE]}>All</Radio.Button>
                    <Radio.Button className="filter-reserves" value={ReserveType.FLIGHT_GLOBAL}>Flights</Radio.Button>
                    <Radio.Button className="filter-reserves" value={ReserveType.HOTEL_GLOBAL}>Hotels</Radio.Button>
                    <Radio.Button className="filter-reserves" value={ReserveType.PNR_OUTSIDE}>PNR Outside</Radio.Button>
                </Radio.Group>
                <div className="infoCardSearch-container">
                    <div className="margin-bottom-light">
                        <label className="form-label">Reserve Number</label>
                        <Input allowClear={true} className="inpt-reserveNumber" value={this.state.param.reserveId} onChange={(e)=>this.setState({param:{...this.state.param,reserveId:(e.target.value)?e.target.value:null}})} placeholder="Reserve Number" />
                    </div>
                    <div className="margin-bottom-light">
                        <label className="form-label">Reference Number / PNR Code</label>
                        <Input allowClear={true} className="inpt-reserveNumber" value={this.state.param.reference} onChange={(e)=>this.setState({param:{...this.state.param,reference:(e.target.value)?e.target.value:null}})} placeholder="Reference Number or PNR code" />
                    </div>
                    <div className="margin-bottom-light">
                        <label className="form-label">Tell Number</label>
                        <Input allowClear={true} className="inpt-tellNumber" value={this.state.param.phoneNumber} onChange={(e)=>this.setState({param:{...this.state.param,phoneNumber:(e.target.value)?e.target.value:null}})}   placeholder="Tell Number" />
                    </div>
                    <div className="margin-bottom-light">
                        <label className="form-label">Email</label>
                        <Input allowClear={true} className="inpt-email" value={this.state.param.email} onChange={(e)=>this.setState({param:{...this.state.param,email:(e.target.value)?e.target.value:null}})} placeholder="Email"  />
                    </div>
                    <div className="margin-bottom-light">
                        <label className="form-label">Last Name</label>
                        <Input allowClear={true} className="inpt-name" value={this.state.param.lastname} onChange={(e)=>this.setState({param:{...this.state.param,lastname:(e.target.value)?e.target.value:null}})}   placeholder="Last Name"/>
                    </div>

                </div>
                <div className="infoCardSearch-container">
                    <div className="margin-bottom-light">
                        <label className="form-label">Departure Date </label>
                        <DatePicker.RangePicker className="full-width" value={this.state.param.departureFrom && [moment(this.state.param.departureFrom,"YYYY-MM-DD"),moment(this.state.param.departureTo,"YYYY-MM-DD")]} onChange={e=>this.bindingDate(e,["departureFrom","departureTo"])} placeholder="From" />
                    </div>
                </div>
                <div className="infoCardBooking-container">
                    <div className="margin-bottom">
                        <label className="form-label">Booking Date</label>
                        <DatePicker.RangePicker className="full-width" value={this.state.param.createFrom && [moment(this.state.param.createFrom,"YYYY-MM-DD"),moment(this.state.param.createTo,"YYYY-MM-DD")]} onChange={e=>this.bindingDate(e,["createFrom","createTo"])} placeholder="From"/>
                    </div>
                </div>
                <div className="infoCardReserveStatus-container">
                    <div className="margin-bottom-light"><label className="form-label">Reserve Status</label></div>
                    {
                        Object.entries(PaymentType).map(item=>
                            <div className={item[0]+ " peyment-status-color margin-bottom-light"} key={item[1]}>
                                <Checkbox
                                    onChange={this.bindStatusFilter}
                                    value={item[1]}
                                    checked={(this.state.param.reserveStatus && this.state.param.reserveStatus.length>0)&&this.state.param.reserveStatus.indexOf((Array.isArray(item[1])?item[1][0]:item[1]))!==-1}>
                                    {item[0]}
                                </Checkbox>
                            </div>
                        )
                    }
                    <Row gutter={15} className="margin-top">
                        <Col xs={12}>
                            <Button block type="primary" onClick={this.bindFilter}><Icon type="search"/>Search</Button>
                        </Col>
                        <Col xs={12}>
                            <Button block onClick={this.clearStatusFilter}><Icon type="delete"/>Clear</Button>
                        </Col>
                    </Row>
                </div>
            </Card>
        )
    }
}
const mapDispatchToProps=(dispatch)=>({
    getReserves:(param)=>{dispatch(getReserves(param))}

});
const mapStatusToProps=(state)=>({
    reserveList:state.Reserve.reserveList
})
const withRouterHOC=withRouter(FilterSide);
export default connect(mapStatusToProps,mapDispatchToProps)(withRouterHOC);