import {HotelActions,FilterActions} from '../Enums/ActionTypes';
import {Filter} from '../Enums/Types';
import _ from "lodash";
//after that save parameters
export const searchHotel=(param)=>({type:HotelActions.SearchHotel,input:param});
export const saveHotelResult=(param)=>{
    if(param.date){
        param.date.checkIn=param.date.checkIn.split("T")[0];
        param.date.checkOut=param.date.checkOut.split("T")[0];
    }
    return  {type:HotelActions.SaveResult,input:{...param}}
};
export const setHotelLoading=(param)=>{
    return {type:HotelActions.SetHotelLoading,input:param}
};
export const setHotelSorting=(input)=>({type:FilterActions.Sort,input:{...input}});
export const setHotelFilterItems=(hotelData)=>{
    let filter=new Filter();
    hotelData.forEach((item)=>{
        //get all rate
        if(filter.rating.value.indexOf(item.rating)===-1){
            filter.rating.value.push(item.rating)
        }
        //get all facilities
        if(item.features!==null){
            item.features.forEach((item)=>{
                if(!(_.some(filter.features.value,item))){
                    filter.features.value.push(item)
                }
            })
        }
        //get all Board
        if(item.boards!==null){
            item.boards.forEach((item)=>{
                if(!(_.some(filter.boards.value,item))){
                    filter.boards.value.push(item)
                }
            })
        }
        //get all location
        if(item.regions!==null){
            item.regions.forEach(regionsitem=>{
                if(!(_.some(filter.regions.value,regionsitem))){
                    filter.regions.value.push(regionsitem)
                }
            })
        }
    });
    filter.rating.value.sort();
    filter.features.value.sort();
    filter.boards.value.sort();
    filter.regions.value.sort();
    return {type:HotelActions.SetHotelFilter,input:{...filter}}
};
export const setHotelFilterSelect=(param)=>({type:FilterActions.FilterSelect,input:param});
export const setHotelFilterApply=()=>({type:FilterActions.FilterApply});
export const searchRoom=(param)=>({type:HotelActions.SearchRoom,input:param});
export const setHotel=(param)=>({type:HotelActions.SetHotel,input:param});
export const setRooms=(param)=>({type:HotelActions.SetRoom,input:{...param}});
export const setRoomsKey=(key)=>({type:HotelActions.SetRoomKey,input:key});
export const getPassengerInforamtion=(key)=>({type:HotelActions.GetPassengerInfo,input:key});
export const saveRoomSelected=(param)=>({type:HotelActions.SaveRoomSelected,input:param});
export const reserve=(param)=>({type:HotelActions.Reserve,input:param});
export const book=(param)=>({type:HotelActions.HotelBook,input:param});
export const getHotelSelected=(param)=>({type:HotelActions.GetHotelSelected,input:param});
export const setHotelSelected=(param)=>({type:HotelActions.SetHotelSelected,input:param});
export const clearHotelSelected=()=>({type:HotelActions.ClearHotelSelected});
export const  getPreCancel=(param)=>({type:HotelActions.PreCancelHotel,input:param});
export const setPreCancel=(param)=>({type:HotelActions.SetCancelInformation,input:param});
export const cancel=(param)=>({type:HotelActions.CancelHotel,input:param});

