//start file
//import library
import React from 'react';
import ReactDOM from 'react-dom';

// import {BrowserRouter} from 'react-router-dom';

import {store, history} from './Reducers/configureStore'
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
//insert view file
import App from './Components/App';
//run ReactDOM JSX file
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));
