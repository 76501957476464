import {Form,Input,Button,Layout,Breadcrumb,Radio,Checkbox,DatePicker,Select,notification} from 'antd';
import {Link,withRouter} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {Flight, getHeaderRequest, Urls} from "../../../../Enums/APIAddress";
import {CurrencyType,ReserveType} from "../../../../Enums/Types";
import queryString from "query-string";
const {Content} = Layout;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';
class CommissionEdit extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        value: true,
        description: '',
        data: [],
        currencyType: Object.keys(CurrencyType),
        transferType: Object.keys(ReserveType),
        terminals:[],
        status:"Ready",
        mode:null,
        isDo:false

    };
    getAirline=(e)=>{
        if (e.length >=2) {
            this.setState({
                airlineCodes:[]
            },()=>{
                axios({
                    method: 'get',
                    url: `${Urls.Type + Urls.Flight +Flight.GetAirline}?value=${e}`,
                    headers: getHeaderRequest(false)
                }).then((responsive) => {
                    let result = responsive.data.result;
                    if (result.length !== 0) {
                        this.setState({
                            airlineCodes:result
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            })

        }
    }
    getLocation=(e,type)=>{
        if (e.length >=3) {

            this.setState({
                [type]:[]
            },()=>{
                axios({
                    method: 'get',
                    url: `${Urls.Type + Urls.Flight +Flight.GetLocation}?value=${e}`,
                    headers: getHeaderRequest(false)
                }).then((responsive) => {
                    let result = responsive.data.result;
                    if (result.length !== 0) {
                        this.setState({
                            [type]:result
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            })

        }
    }
   _getTerminals=()=>{
       let headers=getHeaderRequest(true)
        axios.post(Urls.GraphQL,
            {
                query:`
                    mutation{
                      terminals(filter:{}){
                        terminals{
                          name,
                          id
                        }
                      }
                    }            
                    `
            }, {headers: {...headers}})
            .then(data=>{
                this.setState({
                    terminals:data.data.data.terminals.terminals

                })
            })
    }
    _getData=async()=>{
       //determinate type
        let param=queryString.parse(this.props.location.search);
        this.setState({
            mode:param.type
        })
        ////
        await this._getTerminals();

        const {match: {params}} = this.props;
        let headers=getHeaderRequest(true);
        axios.post(Urls.GraphQL, {
            query:                `
            mutation getCommission($ID:ID!){
              commission(commissionId:$ID){
                airlineIds,
                amount,
                currencyId,
                dateFrom,
                dateTo,
                destinationIds,
                id,
                isEnabled,
                isPercentage,
                name,
                sourceIds,
                terminalIds,
                typeId,
                fareBasis,
                description
              }
            }
          `,
            variables: {
                "ID": params.id
            }
        }, {headers: {...headers}})
        .then(data=>{
            ;
            this.setState({data: data.data.data.commission},()=>{
                this.props.form.setFieldsValue({
                    airlineIds:this.state.data.airlineIds,
                    name: this.state.data.name,
                    isEnabled: this.state.data.isEnabled,
                    amount: this.state.data.amount,
                    isPercentage: this.state.data.isPercentage,
                    dateRange: [this.state.data.dateFrom&&moment(this.state.data.dateFrom,"YYYY-MM-DDTHH:mm:SS"),this.state.data.dateTo&&moment(this.state.data.dateTo,"YYYY-MM-DDTHH:mm:SS")],
                    currencyId:this.state.data.currencyId,
                    typeId:this.state.data.typeId,
                    terminalIds:this.state.data.terminalIds,
                    destinationIds:this.state.data.destinationIds,
                    sourceIds:this.state.data.sourceIds,
                    fareBasis:this.state.data.fareBasis,
                    description:this.state.data.description

                });
            });
        })
    }
    componentDidMount() {
        this._getData();
    };
    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            isDo:true
        },()=>{
            const btn = (
                <Button type="primary" size="small" onClick={()=>{notification.close('done');this.props.history.push(`/commission`)}}>
                    Ok
                </Button>
            );
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.setState({"status":"Processing"})
                    //create input schema
                    const {match: {params}} = this.props;
                    let param={
                        "airlineIds":values.airlineIds,
                        "id":params.id,
                        "name":values.name,
                        "dateRange":(values.dateRange.length)&&`${moment(values.dateRange[0]).format("YYYY/MM/DD")}-${moment(values.dateRange[1]).format("YYYY/MM/DD")}`,
                        "destinationIds":values.destinationIds,
                        "sourceIds":values.sourceIds,
                        "amount":values.amount,
                        "isEnabled":values.isEnabled,
                        "isPercentage":values.isPercentage,
                        "description":values.description,
                        "currencyId":values.currencyId,
                        "typeId":values.typeId,
                        "fareBasis":values.fareBasis,
                        "terminalIds":(values.terminalIds)?values.terminalIds:[]
                    };
                    let headers=getHeaderRequest(true);
                    axios.post(Urls.GraphQL,
                        {
                            query:`
                            mutation($commission:CommissionUpdateViewModelInputType!){
                              commissionUpdate(commission:$commission){
                                isSuccess
                              }
                            }
                         `,
                            variables: {
                                "commission": {
                                    ...param
                                }
                            }
                        }, {headers: {...headers}}
                    ).then(data=>{
                        try{
                            if(data.data.data.commissionUpdate.isSuccess){
                                this.setState({
                                    isDo:false
                                },()=>{
                                    notification.open({
                                        type:'success',
                                        message: 'Update commission done',
                                        btn,
                                        key:'done',
                                        placement:'topRight'
                                    });
                                })

                            }
                        }catch (e) {
                            notification.open({
                                type:'error',
                                message: 'Update commission failed',
                                btn,
                                key:'done',
                                placement:'topRight'
                            });

                        }

                    })


                }
            });
        })

    };
    setPercentage=e=>{
        this.setState({
            data:{
                ...this.state.data,
                isPercentage:e.target.checked
            }
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Content>
                <div className="breadcrumpt-section">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/commission'}>Commission</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Edit {(this.state.mode===ReserveType.FLIGHT_GLOBAL)?"flight":"hotel"} commission</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="container">
                        <div className={"user-form-box margin-top"}>
                            <Form  className="user-form" onSubmit={this.handleSubmit}>

                                <Form.Item label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your Name!',
                                            },
                                        ],
                                    })(<Input/>)}
                                </Form.Item>
                                <Form.Item label="Type"  >
                                    {getFieldDecorator('typeId', {
                                        initialValue:this.state.mode
                                    })(<Select showSearch placeholder="Select a Type" onChange={this.changeMode} overlay>
                                            {Object.keys(ReserveType).map(item=><Select.Option key={item} value={item}>{item}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Is Enable?" >
                                    {getFieldDecorator('isEnabled', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input  is Enable!',
                                            },
                                        ],
                                    })(
                                        <Radio.Group onChange={this.onChange}>
                                            <Radio value={true}>
                                                Enable
                                            </Radio>
                                            <Radio value={false}>
                                                Disable
                                            </Radio>
                                        </Radio.Group>
                                    )}


                                </Form.Item>
                                <Form.Item label="Data Range">
                                    {getFieldDecorator('dateRange', {
                                    })(
                                        <RangePicker
                                            format={dateFormat}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item  label={'Is Percentage?'}>
                                    {getFieldDecorator('isPercentage', {
                                        valuePropName: 'checked',
                                    })(
                                        <Checkbox onChange={()=>{this.setState(prevState=>({data:{...prevState.data,isPercentage:!prevState.data.isPercentage}}))}}>
                                            <span className={"hidden-xl hidden-lg hidden-md"}>Is Percentage</span>
                                        </Checkbox>
                                    )}
                                </Form.Item>
                                <Form.Item label={'Amount'} className={"commition-amount"}>
                                    {getFieldDecorator('amount', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input amount!'

                                            },
                                        ],
                                    })(<Input placeholder={"Please input amount"}/>)}
                                    {
                                        this.state.data.isPercentage &&<span className="persent-sign">%</span>
                                    }
                                </Form.Item>
                                {
                                    !this.state.data.isPercentage &&
                                    <Form.Item label="Currency" >
                                        {getFieldDecorator('currencyId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select currency!',
                                                },
                                            ],
                                        })(
                                            <Select  showSearch placeholder="Select a Currency" >
                                                <Select.Option value={null}>-</Select.Option>
                                                {this.state.currencyType.map(item=><Select.Option key={item} value={item}>{item}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                }
                                <Form.Item label={"Terminals"}>
                                    {getFieldDecorator('terminalIds', {
                                    })(
                                        <Select mode={"multiple"}  showSearch placeholder="Select a Terminals" >
                                            <Select.Option value={null}>All</Select.Option>
                                            {this.state.terminals.map(item=><Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label={"Description"}>
                                    {getFieldDecorator('description', {
                                    })(
                                        <Input.TextArea rows={3}></Input.TextArea>
                                    )}
                                </Form.Item>
                                {this.state.mode===ReserveType.FLIGHT_GLOBAL &&
                                <>
                                    <Form.Item label={"Fare Basis"}>
                                        {getFieldDecorator('fareBasis', {
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                    <Form.Item label={"Airline IDs"}>
                                        {getFieldDecorator('airlineIds', {
                                        })(
                                            <Select filterOption={false} mode={"multiple"} onSearch={this.getAirline} onFocus={()=>{this.setState({airlineCodes:[]})}}>
                                                {
                                                    this.state.airlineCodes && this.state.airlineCodes.map(item=>{
                                                        return(
                                                            <Select.Option value={item.Code}>{item.Name}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={"Source IDs"}>
                                        {getFieldDecorator('sourceIds', {
                                        })(
                                            <Select filterOption={false} mode={"multiple"} onSearch={(e)=>this.getLocation(e,'originsCode')} onFocus={()=>{this.setState({originsCode:[]})}}>
                                                {
                                                    this.state.originsCode && this.state.originsCode.map(item=>{
                                                        return(
                                                            <Select.Option key={item.Code} value={item.Code}>{item.Name}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={"Destination IDs"}>
                                        {getFieldDecorator('destinationIds', {
                                        })(
                                            <Select  filterOption={false} mode={"multiple"} onSearch={(e)=>this.getLocation(e,'destinationCode')} onFocus={()=>{this.setState({destinationCode:[]})}}>
                                                {
                                                    this.state.destinationCode && this.state.destinationCode.map(item=>{
                                                        return(
                                                            <Select.Option key={item.Name} value={item.Code}>{item.Name}</Select.Option>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        )}
                                    </Form.Item>
                                </>
                                }
                                <div className={"form-submit-row"}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={this.state.isDo}>
                                            Save Change
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>

                    </div>


            </Content>
        );
    }
}
const router=withRouter(CommissionEdit)
export default Form.create({name: 'CommissionEdit'})(router)
