import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from "moment";
import {Spin,Card,Icon,Modal,Alert,Form,Input} from "antd";
import Transaction from "../../../commond/Transaction";
import Gateways from "../../../commond/Gateways";
import Price from "../../../commond/Price";
import Rate from "../../../commond/Rate";
import {PaymentType} from "../../../../Enums/Types";
import {getPreCancel,setPreCancel,cancel} from "../../../../Actions/HotelAction";
import {
    getHotelInfo,
    getGatewaysInfo,
    getTransactionInfo
} from "../../../../Actions/ReservesAction";
import queryString from "query-string";
import {Button, Col, Row, Descriptions, Badge} from "antd";
class HotelDetails extends React.Component{
    state={
        showModal:false
    }
    componentDidMount() {
        this.getHotel();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.search!==prevProps.location.search){
            //if in this page available if user change URL we have to search again
            this.getHotel();
        }
        if(this.props.cancelInfo!==prevProps.cancelInfo){

            if(this.props.cancelInfo){
                this.setState({
                    showModal:true
                })
            }else{
                this.setState({
                    showModal:false
                });
            }
        }

    }
    submitForm(e){
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let param={reserveId:this.props.hotelInfo.reserveId,username:this.props.hotelInfo.reserver.username,...values};
                this.props.cancel(param);
            }
        });
    }
    getHotel=()=>{
        let parameters=queryString.parse(this.props.location.search);
        // this.props.getFlightInfo(10231);
        // this.props.getTransactionInfo(10231);
        this.props.getHotelInfo(parameters.id);
        this.props.getTransactionInfo(parameters.id);
        this.props.getGatewaysInfo(parameters.id);
    };
    getPreCancel=()=>{

        let param=new URLSearchParams();
        param.append("reserveId",this.props.hotelInfo.reserveId);
        param.append("username",this.props.hotelInfo.reserver.username);
        this.props.getPreCancel(param);
    }
    render() {
        const {hotelInfo,transactionsList,gatewaysLogs}=this.props;
        const { getFieldDecorator } = this.props.form;
        return(
            <>
                {(hotelInfo) ?
                    <div className="container vr-padding">
                        <Row gutter={15} className={"flightDetails"}>
                            <Col xs={24} sm={24} md={10} lg={8} xl={6} className={"aside"}>
                                <Descriptions bordered className="margin-bottom">
                                    <Descriptions.Item span={3} label={"Reserve Number"}>{hotelInfo.reserveId}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Status"}>
                                        <Badge 
                                            text={hotelInfo.status}
                                            status={
                                                (hotelInfo.status === "Issued")?"success"
                                                :(hotelInfo.status === "Refunded")?"purple"
                                                :(hotelInfo.status === "PeymentSuccessful")?"gold"
                                                :(hotelInfo.status === "Registered")?"purple"
                                                :(hotelInfo.status === "WebServiceUnsuccessful")?"error"
                                                :(hotelInfo.status === "Unavailable")?"error"
                                                :(hotelInfo.status === "Canceled")?"default"
                                                :(hotelInfo.status === "WebServiceCancel")?"gold"
                                                :(hotelInfo.status === "Undefined")?"error"
                                                :(hotelInfo.status === "Pending")?"processing"
                                                :(hotelInfo.status === "PriceChange")?"error"
                                                :"default"}                                            
                                            />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Departure Date"}>{moment(hotelInfo.date.checkIn,"YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Create Date"}>{moment(hotelInfo.bookingDatetime,"YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Regular Price"}><Price currency={hotelInfo.currency.code}>{hotelInfo.regularPrice}</Price></Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Sale Price"}><Price currency={hotelInfo.currency.code}>{hotelInfo.salePrice}</Price></Descriptions.Item>
                                </Descriptions>
                                <div>
                                    <Button type={"primary"} block onClick={()=>{window.open(`https://voucher.imt.as/en/Hotel/Voucher?reserveId=${hotelInfo.reserveId}&username=${hotelInfo.reserver.username}`, '_blank')}} icon={"printer"}>Print Voucher</Button>
                                </div>
                                {
                                    hotelInfo.status.toUpperCase()===PaymentType.Issued &&
                                    <div>
                                        <Button block type="danger" onClick={this.getPreCancel}>Cancel this voucher</Button>
                                    </div>
                                }


                            </Col>                            
                            <Col xs={24} sm={24} md={14} lg={16} xl={18} className={"flightInfo"}>
                                <div className={"ReserveInformation"}>
                                    <Card title={"Hotel Information"} className={"hotelInfo margin-bottom"}>
                                        <div className={"hotelName"}>{hotelInfo.accommodation.name} <Rate number={hotelInfo.accommodation.rating}/></div>
                                        <div className={"hotellocation"}>{hotelInfo.country.name} - {hotelInfo.city.name}</div>
                                        <div className={"address"}>{hotelInfo.accommodation.address}</div>
                                        <div className={"contact"}>
                                            <div><Icon type="phone" /><b> Telephone</b></div>
                                            {hotelInfo.accommodation.phones.map((item,index)=><div key={index}>{item.type} {item.value}</div>)}
                                            <div><Icon type="mail" /><b> Email</b></div>
                                            {hotelInfo.accommodation.emails.map((item,index)=><div key={index}>{item.value}</div>)}
                                        </div>
                                    </Card>
                                </div>
                                <div className={"ReserveInformation"}>
                                    <h5 className="normal-title">Room Reserved</h5>
                                    <div className="responsive-table-holder">
                                        <table className="itours-table text-center margin-bottom">
                                            <thead>
                                                <tr>
                                                    <th>Room Name</th>
                                                    <th>Board</th>
                                                    <th>Passenger<div>(type)</div></th>
                                                    <th>Regular Price</th>
                                                    <th>Intro Price</th>
                                                    <th>Sale Price</th>
                                                    <th>Discount Amount</th>
                                                    <th>Discount Percent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hotelInfo.accommodation.rooms.map((item,index)=>{
                                                    return (
                                                        <tr bordered={true} key={index}>
                                                            <td>{item.name}</td>
                                                            <td>{item.board.name}</td>
                                                            <td>
                                                                {item.passengers.map((item,index)=>{
                                                                    return(
                                                                        <div key={index}>
                                                                            {(index)?<hr/>:null}
                                                                            <div>{(item.gender)?"Mr":"Ms"} {item.firstname} {item.lastname}</div>
                                                                            <div> ({item.ageCategory})</div>
                                                                        </div>
                                                                    )
                                                                })}                                                           
                                                            </td>
                                                            <td><Price currency={hotelInfo.currency.code}>{item.regularPrice}</Price></td>
                                                            <td><Price currency={hotelInfo.currency.code}>{item.introPrice}</Price></td>
                                                            <td><Price currency={hotelInfo.currency.code}>{item.salePrice}</Price></td>
                                                            <td><Price currency={hotelInfo.currency.code}>{item.discountPrice}</Price></td>
                                                            <td><span>{item.discountPercent} %</span></td>
                                                        </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className={"ReserveInformation"}>
                                    <h5 className="normal-title">Reserver Information</h5>
                                        <Descriptions bordered={true}>
                                            <Descriptions.Item span={3} label={"Name"}>{(hotelInfo.reserver.gender)?"Mr":"Ms"} {hotelInfo.reserver.firstName} {hotelInfo.reserver.lastName}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={"Phone number"}>{hotelInfo.reserver.phoneNumber}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={"Email"}>{hotelInfo.reserver.email}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={"Username"}>{hotelInfo.reserver.username}</Descriptions.Item>
                                        </Descriptions>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div className="text-center loading-section"><Spin /><div>Loading Hotel Information ...</div></div>
                }
                {
                    <Modal title={"Hotel Cancel"} visible={this.state.showModal}  closable={false} footer={null}>
                        {this.props.cancelInfo && this.props.cancelInfo.cancellation.isRefundable ?
                            <div>
                                <Alert
                                    message="Refundable"
                                    description="This voucher is refundable."
                                    type="success"
                                    showIcon
                                />
                            </div>
                            :
                            <div>
                                <Alert
                                    message="Warning"
                                    description="This voucher is non-refundable."
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        }
                        <div>
                            <span>Refund Amount: <Price currency={this.props.cancelInfo && this.props.cancelInfo.cancellation.currencyType}>{this.props.cancelInfo && this.props.cancelInfo.cancellation.refundAmount}</Price></span>
                        </div>
                        <div>
                            <span>Damage Amount: <Price currency={this.props.cancelInfo && this.props.cancelInfo.cancellation.currencyType}>{this.props.cancelInfo && this.props.cancelInfo.cancellation.damageAmount}</Price></span>
                        </div>
                        <Form onSubmit={(e)=>this.submitForm(e)} id={"FrmASend"}>
                            <Form.Item label="Card Number">
                                {getFieldDecorator('cardNumber', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input card number',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Account Name">
                                {getFieldDecorator('accountName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your account name',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>
                            <div className={"footerPart"}>
                                <Button key="back" onClick={()=>{this.props.setPreCancel(null)}}>
                                    Dont Cancel it
                                </Button>
                                <Form.Item>
                                    <Button  htmlType="submit" type={"danger"}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Modal>
                }
                <div className="container  vr-padding">
                    <Row>
                        <Col xs={{span:24,offset:0}} md={{span:14,offset:10}} lg={{span:16,offset:8}} xl={{span:18,offset:6}}>
                            {
                                (transactionsList) ?
                                    (transactionsList.length)?
                                        <div className="container">
                                            <Transaction model={transactionsList} />
                                        </div>
                                        :
                                        <Alert
                                            message="Attention"
                                            description="There isn't any Transactions List"
                                            type="info"
                                            showIcon
                                            className={"margin-bottom"}
                                        />
                                :
                                <div>transcation loading</div>
                            }
                            {
                                (gatewaysLogs) ?
                                    (gatewaysLogs.length)?
                                        <div className="container">
                                            <Gateways model={gatewaysLogs} />
                                        </div>
                                        :
                                        <Alert
                                            message="Attention"
                                            description="There isn't any Gateways"
                                            type="info"
                                            className={"margin-bottom"}
                                            showIcon
                                        />
                                :
                                <div>gatewaysLogs loading</div>
                            }
                        </Col>
                    </Row>
                </div>
            </>

        )

    }
}
const mapDispatchToProps=(dispatch)=>({
    getTransactionInfo:(id)=>{dispatch(getTransactionInfo(id))},
    getGatewaysInfo:(id)=>{dispatch(getGatewaysInfo(id))},
    getHotelInfo:(id)=>{dispatch(getHotelInfo(id))},
    getPreCancel:(param)=>{dispatch(getPreCancel(param))},
    setPreCancel:(param)=>{dispatch(setPreCancel(param))},
    cancel:(param)=>{dispatch(cancel(param))}

})
const mapStateToProps=(state)=>({
    transactionsList:state.Reserve.transactionList,
    hotelInfo:state.Reserve.hotelDetails,
    gatewaysLogs:state.Reserve.gatewaysLogs,
    cancelInfo:state.Hotel.cancelInfo
})
const HotelDetailsRouter=withRouter(HotelDetails);
const FormEnable=Form.create({name:'cancelHotel'})(HotelDetailsRouter);
export default connect(mapStateToProps,mapDispatchToProps)(FormEnable);