import {
    Form,
    Input,
    Button,
    Layout,
    Breadcrumb,
    Radio,
    DatePicker,
    notification,
} from 'antd';
import {Link} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {getHeaderRequest, Account as AccountURL, Urls} from "../../../../Enums/APIAddress";
import IntlTelInput from "react-intl-tel-input";

const {Content} = Layout;
const dateFormat = 'YYYY/MM/DD';

class UserEdit extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        value: true,
        description: '',
        data: [],
        currencyType: [],
        supplierType: [],
        birthDay: '2015/01/01'
    };

    getFullNumber = () => {
        let phoneNumber;
        const phoneNumberInput = this.phoneNumberElement.current.state.value;
        const dialCode = this.phoneNumberElement.current.state.dialCode;
        if (phoneNumberInput.startsWith(dialCode)) {
            phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
        } else {
            phoneNumber = phoneNumberInput;
        }
        phoneNumber = dialCode.concat(phoneNumber);
        phoneNumber = phoneNumber.replace(/\s/g, '');
        return phoneNumber;
    }

    componentDidMount() {
        let headers = getHeaderRequest(true);
        const {match: {params}} = this.props;
        axios.get(`${Urls.Type}${Urls.User}${AccountURL.GetUsers}?id=${params.id}`, {
            headers: {
                ...headers
            }
        })
            .then(({data}) => {
                let item = data.result;
                this.setState({item});
            });
    }

    phoneNumberElement = React.createRef();
    handleSubmit = (e) => {
        e.preventDefault();
        let {birthDay} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err && birthDay) {
                const {match: {params}} = this.props;
                values.phoneNumber = this.getFullNumber();
                let param = {...values, id: params.id, birthDay,};
                let headers = getHeaderRequest(true);
                axios.put(`${Urls.Type}${Urls.User}${AccountURL.UpdateUsers}`, param, {
                    headers: {
                        ...headers
                    }
                }).then((data) => {
                    notification.info({
                        message: `success`,
                        description:
                            'user information updated',
                    });
                    this.props.history.push(`/user`);

                });
            } else {
                notification.info({
                    message: `Warning`,
                    description:
                        'Please complete the form',
                });
            }
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {item} = this.state;
        return (
            <Content>
                <div className="breadcrumpt-section">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/user'}>User</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="container">
                    {
                        item &&
                        <div className={"user-form-box margin-top"}>
                            <Form className="user-form" onSubmit={this.handleSubmit}>
                                <Form.Item label="First name">
                                    {getFieldDecorator('firstName', {
                                        initialValue: item && item.firstName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter first name',
                                            },
                                        ],

                                    })(<Input/>)}
                                </Form.Item>
                                <Form.Item label="Last name">
                                    {getFieldDecorator('lastName', {
                                        initialValue: item && item.lastName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter last name',
                                            },
                                        ],
                                    })(<Input/>)}
                                </Form.Item>
                                <Form.Item label="Phone number">
                                    <IntlTelInput
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control"
                                        fieldName={"phoneNumber"}
                                        placeholder={"Phone number"}
                                        defaultValue={item.phoneNumber === null ? '' : item.phoneNumber}
                                        useMobileFullscreenDropdown={true}
                                        ref={this.phoneNumberElement}
                                        separateDialCode={true}
                                    />
                                </Form.Item>

                                <Form.Item label="Birthday">
                                    {
                                        item.birthDay ?
                                            <DatePicker onChange={(birthDay) => this.setState({birthDay})}
                                                        defaultValue={moment(item.birthDay, dateFormat)} format={dateFormat}
                                            />
                                            :
                                            <DatePicker
                                                onChange={(birthDay) => this.setState({birthDay})}
                                                defaultValue={moment('2015/01/01', dateFormat)} format={dateFormat}/>
                                    }
                                </Form.Item>
                                <Form.Item hasFeedback label="Gender">
                                    {getFieldDecorator('gender', {
                                        initialValue: item && item.gender,

                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select gender',
                                            },
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value={true}>
                                                Male
                                            </Radio>
                                            <Radio value={false}>
                                                Female
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item hasFeedback label="Status">
                                    {getFieldDecorator('isActive', {
                                        initialValue: item && item.isActive,

                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select activation status',
                                            },
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value={true}>
                                                Active
                                            </Radio>
                                            <Radio value={false}>
                                                Deactive
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item hasFeedback label="Email status">
                                    {getFieldDecorator('isEmailConfirmed', {
                                        initialValue: item && item.isEmailConfirmed,

                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select email status',
                                            },
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value={true}>
                                                Confirmed
                                            </Radio>
                                            <Radio value={false}>
                                                Not confirmed
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item hasFeedback label="Newsletter status">
                                    {getFieldDecorator('isNewsLater', {
                                        initialValue: item && item.isNewsLater,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select newsletter subscription status',
                                            },
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value={true}>
                                                Enable
                                            </Radio>
                                            <Radio value={false}>
                                                Disable
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <div className={"form-submit-row"}>
                                    <Form.Item className="margin-bottom">
                                        <Button type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    }
                </div>

            </Content>

        );
    }
}

export default Form.create({name: 'UserEdit'})(UserEdit)
