//enums for action name in  redux
export const UserActions = {
    SetUser: "SETUSER",
    Login: 'LOGIN',
    Authentication: 'AUTHENTICATION',
    SendRecoverEmail: 'SENDRECOVEREMAIL',
    ChangePassword: 'CHANGEPASSWORD',
    Logout: 'LOGOUT',
    ChangeCurrency: 'CHANGECURRENCY',
    ResetPassword: 'RESETPASSWORD',
    UpdateUser: 'UPDATEUSER',
    GetTenant: "GETTENANT",
    SetTenant: "SETTENANAT",
    CheckTenant: "CHECKTENANT"
};
export const NotificationActions = {
    SetNotification: "SETNOTIFICATION",
    SetLoading: "SETLOADING"
};
export const HotelActions = {
    SearchHotel: 'SEARCHHOTEL',
    SaveResult: 'SAVEHOTELRESULT',
    SetHotelLoading: 'SETHOTELLOADING',
    SetHotelFilter: 'SETHOTELFILTER',
    SearchRoom: 'SEARCHROOM',
    SetHotel: 'SETHOTEL',
    SetRoom: 'SETROOM',
    SetRoomKey: 'SETROOMKEY',
    GetPassengerInfo: 'GETPASSENGERINFO',
    SaveRoomSelected: 'SAVEROOMSELECTED',
    Reserve: 'RESERVE',
    HotelBook: 'HOTELBOOK',
    GetHotelSelected: 'GETHOTELSELECTED',
    SetHotelSelected: 'SETHOTELSELECTED',
    ClearHotelSelected: 'CLEARHOTELSELECTED',
    PreCancelHotel: "PRECANCELHOTEL",
    SetCancelInformation: "SETCANCELINFORMATION",
    CancelHotel: "CANCELHOTEL"
};
export const FlightActions = {
    SetFlightLoading: 'SETFLIGHTLOADING',
    SearchFlight: 'SEARCHFLIGHT',
    SaveFlightResult: 'SAVEFLIGHTRESULT',
    SetFlightFilter: 'SETFLIGHTFILTER',
    ApplyFlightFilter: 'APPLYFLIGHTFILTER',
    ValidateFlight: 'VALIDATEFLIGHT',
    SetFlightSelected: 'SETFLIGHTSELECTED',
    getFlightPNR: 'GETFLIGHTPNR',
    setFlightPNR: 'SETFLIGHTPNR',
    getPNRDetails: 'GETPNRDETAILS',
    setPNRDetails: 'SETPNRDETAILS',
    pricePNR: 'PRICEPNR',
    issuePNR: 'ISSUEPNR',
    voidTicket: 'VOIDTICKET',
    cancelPNR: 'CANCELPNR',
    getDirectTicketByID: 'GETDIRECTTICKETBYID',
    sortFlightResult: 'SORTFLIGHTRESULT'
}

export const PaymentActions = {
    setReserveID: 'SETRESERVEID',
    getReserveByID: 'GETRESERVEBYID',
    setReserveInformation: 'SETRESERVEINFO',
    getPaymentInformation: 'GETPAYMENTINFO',
    setPaymentInformation: 'SETPAYMENTINFO',
    setPaymentMethod: 'SETPAYMENTMETHOD',
    getDeposits: 'GETDEPOSIT',
    setDeposits: 'SETDEPOSIT',
    getDepositsGateways: 'GETDEPOSITSGATEWAYS',
    setDepositsGateways: 'SETDEPOSITSGATEWAYS',
    setDepositMethod: 'SETDEPOSITMETHOD',
    getDepositBalance: 'GETDEPOSITBALANCE',
    setDepositBalance: 'SETDEPOSITBALANCE',
    confirmDeposit: 'CONFIRMDEPOSIT',
    refundDeposit: 'REFUNDDEPOSIT',
    setBookStatus: 'SETBOOKSTATUS',
    confirmPayment: 'CONFIRMPAYMENT'
};
export const FilterActions = {
    FilterSelect: 'FILTERSELECT',
    FilterApply: 'FILTERAPPLY',
    Sort: 'Sort'
};
export const ReserveActions = {
    GetReserves: 'GETRESERVES',
    SetReserves: 'SETRESERVES',
    getFlightInfo: 'GETFLIGHTINFO',
    setFlightInfo: 'SETFLIGHTINFO',
    getHotelInfo: 'GETHOTELINFO',
    setHotelInfo: 'SETHOTELINFO',
    getTransaction: 'GETTRANSACTION',
    setTransaction: 'SETTRANSACTION',
    getGatewaysLog: 'GETGATEWAYLOGS',
    setGatewaysLog: 'SETGATEWAYSLOGS',
    setReserveStatus: 'SETRESERVESTATUS',
    getReserveStatus: 'GETRESERVESTATUS'
};
export const SharedActions = {
    GetIpLocation: 'GETIPLOCATION',
    SetIpLocation: 'SETIPLOCATION'
};
