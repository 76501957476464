import React from 'react';
import {connect} from 'react-redux';
import {Form, Input, Button, Icon, Col, Row, Select,DatePicker,Card} from 'antd';
import moment from "moment";
//import model and action

import {updateUser} from "../../../../Actions/AccountAction";
class ChangeInformation extends React.Component{
    handleSubmit=e=>{
        e.preventDefault();
        this.props.form.validateFields((err,value)=>{
            if(!err){
                this.props.updateUser({...value})
            }
        })
    };
    state={
        title:this.props.userInformation.gender,
        firstName:this.props.userInformation.firstName,
        lastName:this.props.userInformation.lastName,
        birthday:this.props.userInformation.birthDay && moment(this.props.userInformation.birthDay,"YYYY-MM-DDTHH:mm:SS"),
        editMode:false,
    };
    activeEdit=()=>{
        this.setState({
            editMode:true
        })
    }
    deactiveEdit=()=>{
        this.setState({
            editMode:false
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="editInformation-form">
                <Card title="Change information" className="min-height-400">
                    <Row gutter={15}>
                        <Col xs={24} md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Gender</label>
                                <Form.Item>
                                    {getFieldDecorator('gender', {
                                        initialValue:this.state.title,
                                    })( <Select style={{ width: '100%' }} disabled={(this.state.editMode)?false:true } onChange={(value)=>{this.setState({title:value})}}>
                                            <Select.Option value={true}>Mr</Select.Option>
                                            <Select.Option value={false}>Ms</Select.Option>
                                        </Select>)}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={24} md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">First name</label>
                                <Form.Item>
                                    {getFieldDecorator('firstName', {
                                        initialValue:this.state.firstName,
                                        rules: [{ required: true, message: 'Please input your first name' }]
                                    })(<Input prefix={<Icon type="user" className={'user'} />}  disabled={(this.state.editMode)?false:true} onChange={(e)=>{this.setState({firstName:e.target.value})}}/>,)}
                                </Form.Item>

                            </div>
                        </Col>
                        <Col xs={24} md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Last name</label>
                                <Form.Item>
                                    {getFieldDecorator('lastName', {
                                        initialValue:this.state.lastName,
                                        rules: [{ required: true, message: 'Please input your first name' }]
                                    })(<Input prefix={<Icon type="user" className={'user'} />} disabled={(this.state.editMode)?false:true} onChange={(e)=>{this.setState({lastName:e.target.value})}}/>,)}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col xs={24} md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Birthday</label>
                                <Form.Item>
                                    {getFieldDecorator('birthDay', {
                                        initialValue:this.state.birthday,
                                        rules: [{ required: true, message: 'Please input your brithday' }]
                                    })(<DatePicker className="full-width"  disabled={(this.state.editMode)?false:true} onChange={(value)=>{this.setState({birthday:value})}} />)}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={24} md={16} className="gutter-row">
                            <div className="gutter-box">
                                <div className="align-with-labeled text-right">
                                    {
                                        (this.state.editMode)?
                                        <>
                                            <Button htmlType={"button"} className="margin-right" onClick={this.deactiveEdit}>Cancel</Button>
                                            <Button loading={this.props.showInlineLoad} type="primary" htmlType={'submit'} >Save changes</Button>
                                        </>
                                        :
                                        <Button type="primary" htmlType={"button"} onClick={this.activeEdit}>Edit informations</Button>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Form>

        )
    }
}
const mapStateToProps=(state)=>({
    userInformation:state.User
});
const mapDispatchToProps=(dispatch)=>({
    updateUser:(param)=>{dispatch(updateUser(param))}
})
const WrappedEditInformationForm = Form.create({ name: 'editInformation-form' })(ChangeInformation);
export default connect(mapStateToProps,mapDispatchToProps)(WrappedEditInformationForm)