import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router';

//import reducers
import {User} from './AccountReducer';
import {Notification} from './NotificationReducer';
import {Hotel} from './HotelReducer';
import {Payment} from './PaymentReducer';
import {Flight} from './FlightReducer';
import {Reserve} from './ReservesReducer';
import {Shared} from "./SharedReducer";
//combination

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    User,
    Notification,
    Hotel,
    Payment,
    Flight,
    Reserve,
    Shared
});

export default createRootReducer;
