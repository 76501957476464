import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from  "moment";
import {Row, Col, Spin, Descriptions, Badge, Button,Alert} from "antd";
import {SupplierType,PaymentType} from "../../../../Enums/Types";
import {Urls} from "../../../../Enums/APIAddress";
import {
    getFlightInfo,
    getGatewaysInfo,
    getTransactionInfo
} from "../../../../Actions/ReservesAction";
import queryString from "query-string";
import Transaction from "../../../commond/Transaction";
import Gateways from "../../../commond/Gateways";
import FlightSummary from "../Flight/FlightSummary";
class FlightDetails extends React.Component{
    componentDidMount() {
        this.getFlight();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.search!==prevProps.location.search){
            //if in this page available if user change URL we have to search again
            this.getFlight();
        }
    }
    getFlight=()=>{
        let parameters=queryString.parse(this.props.location.search);
        this.props.getFlightInfo(parameters.id);
        this.props.getTransactionInfo(parameters.id);
        this.props.getGatewaysInfo(parameters.id);
    };
    opratingFlight=()=>{
        let idSupplier=SupplierType.filter(item=>item.name===this.props.flightInfo.providerName)[0].value;
        this.props.history.push(`/flight/ticketing?`+
            `&pnrCode=${this.props.flightInfo.pnrCode}`+
            `&supplierId=${idSupplier}`
            )
    };
    render() {
        const {flightInfo,transactionsList,gatewaysLogs}=this.props;
        const parameters=queryString.parse(this.props.location.search);
        return(
            <>
            {
                (flightInfo)?
                <div className="container vr-padding">
                    <Row gutter={15} className={"flightDetails"}>
                        <Col xs={24} sm={24} md={10} lg={8} xl={6} className={"aside"}>
                            <Descriptions  bordered>
                            <Descriptions.Item span={3} label={"PNR Code"}>{flightInfo.pnrCode}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Status"}>
                                <Badge 
                                status={
                                (flightInfo.reserveStatus === "Issued")?"success"
                                :(flightInfo.reserveStatus === "Refunded")?"purple"
                                :(flightInfo.reserveStatus === "PeymentSuccessful")?"gold"
                                :(flightInfo.reserveStatus === "Registered")?"purple"
                                :(flightInfo.reserveStatus === "WebServiceUnsuccessful")?"error"
                                :(flightInfo.reserveStatus === "Unavailable")?"error"
                                :(flightInfo.reserveStatus === "Canceled")?"default"
                                :(flightInfo.reserveStatus === "WebServiceCancel")?"gold"
                                :(flightInfo.reserveStatus === "Undefined")?"error"
                                :(flightInfo.reserveStatus === "Pending")?"processing"
                                :(flightInfo.reserveStatus === "PriceChange")?"error"
                                :"default"}                                
                                 text={flightInfo.reserveStatus} />
                                            
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Reserve Number"}>{parameters.id}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Departure Date"}>{moment(flightInfo.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime,"YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Create Date"}> {moment(flightInfo.bookedDateTime,"YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Supplier"}> {flightInfo.providerName}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Intro Price"}>{flightInfo.flightFare.baseFare}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Sale Price"}>{flightInfo.flightFare.totalFare}</Descriptions.Item>
                            <Descriptions.Item span={3} label={"Tax Price"}>{flightInfo.flightFare.totalTaxes}</Descriptions.Item>
                            </Descriptions>
                            <div>
                                {
                                    flightInfo.reserveStatus.toUpperCase()===PaymentType.Issued &&
                                    <Button type={"primary"} block onClick={()=>{window.open(`${Urls.FlightTicket}?reserveId=${parameters.id}`, '_blank')}} icon={"printer"}>Print Ticket</Button>

                                }
                            </div>
                            <div>
                                <Button type={"primary"} block onClick={()=>this.opratingFlight()} >Ticketing/Show PNR</Button>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={14} lg={16} xl={18} className={"flightInfo"}>
                            <FlightSummary showButton={true}
                                        model={flightInfo.airItinerary.originDestinationOptions}
                                        currencyCode={flightInfo.currency}
                                        fareInfo={flightInfo.passengersFare}
                                        totalPrice={flightInfo.flightFare.totalFare}
                                        feild={"fare"}
                            />
                            <div className={"ReserveInformation margin-bottom"}>
                                <h5 className={"normal-title"}>Reserver Information</h5>
                                <Descriptions bordered={true}>
                                    <Descriptions.Item span={3} label={"Name"}>{(flightInfo.reserver.gender)?"Mr":"Mrs"} {flightInfo.reserver.firstName} {flightInfo.reserver.lastName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Phone Number"}>{flightInfo.reserver.phoneNumber}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Email"}>{flightInfo.reserver.email}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={"Username"}>{flightInfo.reserver.username}</Descriptions.Item>
                                </Descriptions>
                            </div>
                            <div className="flight-prices-info responsive-table-holder">
                                <h5 className={"normal-title"}>Passenger Information</h5>
                                <div className="responsive-table-holder">
                                <table className="itours-table">
                                    <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Code</th>
                                        <th>Ticket Number</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        flightInfo.passengers
                                        &&
                                        flightInfo.passengers.map((item)=>{
                                            return(
                                                <tr key={item.passengerId}>
                                                    <td className="no-wrap">
                                                        {item.title?"Mr":"Ms"}
                                                    </td>
                                                    <td>
                                                        {item.firstName}
                                                    </td>
                                                    <td className="no-wrap">
                                                        {item.lastName}
                                                    </td>
                                                    <td className="no-wrap">
                                                        {(item.code ==="ADT")&& "Adult"}
                                                        {(item.code ==="CHD")&& "Child"}
                                                        {(item.code ==="INF")&& "Infant"}
                                                    </td>
                                                    <td className="no-wrap">
                                                        {item.ticketNumber}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>


                        </Col>
                    </Row>
                </div>
                :
                <div className="text-center loading-section"><Spin /><div>Loading Flight Information ...</div></div>
            }
            <div className="container vr-padding">
                <Row>
                    <Col xs={{span:24,offset:0}} md={{span:14,offset:10}} lg={{span:16,offset:8}} xl={{span:18,offset:6}}>
                    {
                        (transactionsList)?
                            (transactionsList.length)?
                            <div className="container margin-bottom">
                                <Transaction model={transactionsList} />
                            </div>
                                :
                                <Alert
                                    message="Attention"
                                    description="There isn't any Transactions List"
                                    type="info"
                                    showIcon
                                    className={"margin-bottom"}
                                />
                            :
                            <div>loading transaction data</div>
                    }

                    {
                        (gatewaysLogs)?
                            (gatewaysLogs.length)?
                            <div className="container margin-bottom">
                                <Gateways model={gatewaysLogs} />
                            </div>
                                :
                            <Alert
                                message="Attention"
                                description="There isn't any Gateways"
                                type="info"
                                className={"margin-bottom"}
                                showIcon
                            />
                            :
                            <div>loading gatewaysLogs data</div>
                    }
                    </Col>
                </Row>
            </div>
            </>
        )

    }
}
const mapDispatchToProps=(dispatch)=>({
    getTransactionInfo:(id)=>{dispatch(getTransactionInfo(id))},
    getGatewaysInfo:(id)=>{dispatch(getGatewaysInfo(id))},
    getFlightInfo:(id)=>{dispatch(getFlightInfo(id))},
})
const mapStateToProps=(state)=>({
    transactionsList:state.Reserve.transactionList,
    flightInfo:state.Reserve.flightDetails,
    gatewaysLogs:state.Reserve.gatewaysLogs
})
const FlightDetailsRouter=withRouter(FlightDetails);
export default connect(mapStateToProps,mapDispatchToProps)(FlightDetailsRouter);
