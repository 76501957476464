import React from 'react';
import {withRouter} from "react-router-dom";
import {Button,Result} from 'antd';
class NotFound extends React.Component{
    render() {
        return(
            <div className="section-vertical-padding">
                <div className="page-container">
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                        extra={<Button type="primary" onClick={()=>{this.props.history.push("/")}}>Back Home</Button>}
                       
                    />
                </div>
            </div>
        )
    }
}
export default withRouter(NotFound);