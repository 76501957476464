import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, Icon, Row, Col, Tag, Tooltip, Modal} from 'antd';
import Price from "../../../commond/Price";
import '../../../../CSS/Reserves.scss';
import {Urls} from '../../../../Enums/APIAddress';
import {PaymentType, PNRStatus, SupplierType} from '../../../../Enums/Types';
import {CancelPNR,VoidPNR} from '../../../../Actions/FlightAction';
class InfoCard extends React.Component{
    opratingFlight=()=>{
        let idSupplier=SupplierType.filter(item=>item.name===this.props.user.supplier.id)[0].value;
        this.props.history.push(`/flight/ticketing?`+
            `&pnrCode=${this.props.user.reference}`+
            `&supplierId=${idSupplier}`
        )
    }
    getWeekday(date){
        var weekday =  new Date(date).getDay();
        var options = { weekday: 'long'};
        return new Intl.DateTimeFormat('en-US', options).format(weekday)        
    }
    createPDF(){

        if(this.props.user.type==="HOTEL_GLOBAL"){
            window.open(`${Urls.HotelTicket}?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`, '_blank');
        }else{
           // <a href={`https://voucher.imt.as/en/flight/ticket?reserveId=${parameters.id}`} target={"_blank"}>Print this Ticket</a>
            window.open(`${Urls.FlightTicket}?reserveId=${this.props.user.reserveNumber}`, '_blank');
        }
    }
    getMonthName(date){
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthNumber = (new Date(date).getMonth()+1);
        return monthNames[monthNumber];
    }
    getFormatDate(date){
        return `${this.getWeekday(date)}, ${this.getMonthName(date)} ${new Date(date).getDate()}
        ${new Date(date).getFullYear()} - ${new Date(date).getHours()}:${new Date(date).getMinutes()} `
    }
    voidIssued=()=>{
        Modal.confirm({
            title:'Attention',
            content:'After voiding this ticket you have to cancel it,if you don\'t do it,you must pay charges',
            onOk:()=>{
                this.props.VoidPNR({pnrCode:this.props.user.reference,supplierId:this.props.user.supplier.id})
            },
            onCancel(){}
        })

    };
    cancelPNR=()=>{
        this.props.CancelPNR({pnrCode:this.props.user.reference,supplierId:this.props.user.supplier.id})
    }
    render() {
        const isHottel = this.props.user.type;
        let icon,page;
        // if (isHottel === "HOTEL_GLOBAL") {
        //     icon = <Link to={"/hotel"} className="full-width"><span className={"icon-large hotel-orange-icon itours-icon"} /> </Link>;
        //     page = "hotel";
        // } else {
        //     icon = <Link to={"/flight"} className="full-width"><span className={"icon-large flight-icon itours-icon"} /></Link>
        //     page = "flight";
        // }
        if (isHottel === "HOTEL_GLOBAL") {
            icon = <span className={"infocard-item-icon margin-right-half hotel-orange-icon itours-icon"} />;
            page = "hotel";
        } else {
            icon = <span className={"infocard-item-icon margin-right-half flight-icon itours-icon"} />
            page = "flight";
        }
        return(  
            <div className="infoCard-container">
                <Card className="margin-bottom">
                    <h3 className="reserve-title">
                        <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
                            {icon} 
                            {/* %%% طبق طرح اینجا باید اسم هتل یا مبدا و مقصد پرواز باشد */}
                            {this.props.user.firstName}  {this.props.user.lastName}
                        </Link>
                    </h3>
                    <Row className="reserve-item-grid-row">
                        <Col xs={24} lg={24} xl={7}>
                            {/* %%% <div className="margin-bottom-light"><b>{this.props.user.firstName}  {this.props.user.lastName}</b></div> */}
                            {(this.props.user.reference)?
                                (isHottel === "FLIGHT_GLOBAL")?<div className="margin-bottom-light"><b>PNR code: </b> {this.props.user.reference}</div>:null
                            :null}
                            {(isHottel === "FLIGHT_GLOBAL")?<div className="margin-bottom-light"><b>Supplier name: </b> {this.props.user.supplier.id}</div>:null}
                            {(this.props.user.phoneNumber)?<div className="margin-bottom-light"><Icon type="mobile" className="margin-right-light orange" />{this.props.user.phoneNumber}</div>:null}
                            {(this.props.user.email)?<div className="margin-bottom-light"><Icon type="mail" className="margin-right-light red"/>{this.props.user.email}</div>:null}
                            {(this.props.user.terminal.name)?<div className="margin-bottom-light"><Icon type="global" className="margin-right-light blue" />{this.props.user.terminal.name}</div>:null}
                        </Col>
                        <Col xs={24} lg={24} xl={9}>
                            <div className="margin-bottom-light">
                                <b>Reserve Number: </b>
                                <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
                                    {this.props.user.reserveNumber}
                                </Link>
                            </div>

                            <div className={"margin-bottom-light"}>
                                <b>Booking Date: </b>
                                <span className="font-13">{this.getFormatDate(this.props.user.createDate)}</span>
                            </div>
                            <div className={"margin-bottom-light"}>
                                <b>Departure Date: </b>
                                <span className="font-13">{this.getFormatDate(this.props.user.departureDate)}</span>
                            </div>
                        </Col>
                        <Col xs={24} lg={24} xl={8} className="status-price-block-col">
                            <div className="status-price-block">
                                <div className="margin-right-light">
                                    <div className="grey margin-bottom-light">Status</div>
                                    <div>
                                        <Tag 
                                        color={
                                            (this.props.user.paymentStatus === "ISSUED")?"green"
                                            :(this.props.user.paymentStatus === "REFUNDED")?"purple"
                                            :(this.props.user.paymentStatus === "PAYMENT_SUCCESSFUL")?"gold"
                                            :(this.props.user.paymentStatus === "REGISTERED")?"purple"
                                            :(this.props.user.paymentStatus === "WEB_SERVICE_UNSUCCESSFUL")?"red"
                                            :(this.props.user.paymentStatus === "UNAVAILABLE")?"red"
                                            :(this.props.user.paymentStatus === "CANCELED")?""
                                            :(this.props.user.paymentStatus === "WEB_SERVICE_CANCEL")?"gold"
                                            :(this.props.user.paymentStatus === "UNDEFINED")?"red"
                                            :(this.props.user.paymentStatus === "PENDING")?"blue"
                                            :(this.props.user.paymentStatus === "PRICE_CHANGE")?"red"
                                            :""}
                                        >
                                            {this.props.user.paymentStatus}
                                        </Tag>
                                    </div>
                                </div>
                                <div>
                                    <div className="grey margin-bottom-light">Price</div>
                                    <div>
                                        <Price currency={this.props.user.saleCurrency.code}>{this.props.user.salePrice}</Price>
                                    </div>
                                    {(this.props.user.discountPrice === 0)?null:((this.props.user.salePrice - this.props.user.introPrice)>0)?
                                        <div className={"green"}>
                                            <div className="margin-bottom-light">
                                                <Icon type="check-circle" className={"font-22 margin-right-light"} />
                                                Your Profit: <Price currency={this.props.user.introCurrency.code}>{this.props.user.salePrice - this.props.user.introPrice}</Price>
                                            </div>
                                            <div className="margin-bottom-light">
                                                Profit Percentage: 
                                                <span className="no-wrap"> { Math.round( (this.props.user.salePrice - this.props.user.introPrice) *100 /this.props.user.salePrice) } %</span>
                                            </div>

                                        </div>
                                        :
                                        <div className={"red"}>
                                            <div className="margin-bottom-light">
                                                <Tooltip placement="top" title={" Commission is negative! "}>
                                                    <Icon type="warning" className={"font-22 margin-right-light"} />
                                                </Tooltip>
                                                Your Loss: <Price currency={this.props.user.introCurrency.code}>{ this.props.user.introPrice - this.props.user.salePrice}</Price>
                                            </div>
                                            <div className="margin-bottom-light">
                                                Loss Percentage: 
                                                <span className="no-wrap">
                                                    { Math.round( (this.props.user.introPrice - this.props.user.salePrice) *100 /this.props.user.salePrice) }   % 
                                                </span>
                                            </div>
                                        </div>
                                    }                                        
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={24} xl={17} className="pull-right-lg">
                                <div className="reserve-button-group margin-top text-right">
                                    {this.props.user.paymentStatus===PaymentType.Issued &&
                                    <button type="button" className="button voucher-ticket-btn" onClick={()=>this.createPDF()}>
                                        <Icon type="printer" className="margin-right-light"/>
                                        {(isHottel === "HOTEL_GLOBAL")?'Voucher':'Ticket'}
                                    </button>
                                    }
                                    {
                                        (isHottel === "FLIGHT_GLOBAL")&&
                                        <button type="button" className="button voucher-ticket-btn" onClick={()=>this.opratingFlight()}>
                                            Ticketing/Show PNR
                                        </button>
                                    }

                                    {this.props.user.paymentStatus==="ISSUED" &&
                                        <a className="button invoice-btn" target={'_blank'} href={`https://voucher.imt.as/en/factor/${(isHottel === "HOTEL_GLOBAL")?"hotel":"flight"}?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`}>Invoice</a>
                                    }
                                    {
                                        this.props.user.pNRStatus===PNRStatus.Issued &&
                                        <button type="button" className="button voucher-ticket-btn" onClick={()=>this.voidIssued()}>
                                            Void
                                        </button>
                                    }
                                    {
                                        (this.props.user.pNRStatus===PNRStatus.Voided || this.props.user.pNRStatus===PNRStatus.Priced || this.props.user.pNRStatus===PNRStatus.Made)&&
                                        <button type="button" className="button voucher-ticket-btn" onClick={()=>this.cancelPNR()}>
                                            Cancel
                                        </button>
                                    }

                                    <Link className="button details-btn" to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>Details</Link>
                                    {this.props.user.paymentStatus==="PENDING" && isHottel === "HOTEL_GLOBAL" &&
                                        <Link className="button payment-btn" to={`/payment/choice?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`}>Payment</Link>
                                    }


                                    {/* %%% */}
                                    {/* <button className="button invoice-btn">Invoice</button>
                                    <button className="button voucher-ticket-btn">Voucher</button>
                                    <a href="#" className="button details-btn">Details</a>
                                    <a href="#" className="button ticketing-showPNR-btn">Ticketing/Show PNR</a>
                                    <a href="#" className="button cancel-btn">Cancel</a>
                                    <a href="#" className="button void-btn">Void</a>
                                    <a href="#" className="button payment-btn">Payment</a> */}
                                </div>
            
                        </Col>
                    </Row>
                    {/* <Row type={'flex'}>
                        <Col xs={24}  md={7} className="infoCard-names">

                            <h2 className={"item-title"}>
                                <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
                                    {this.props.user.firstName}  {this.props.user.lastName}
                                </Link>
                            </h2>

                            {(this.props.user.reference)?
                                (isHottel !== "HOTEL_GLOBAL")?<p className="infoCard-supplier">PNR code: {this.props.user.reference}</p>:<p className="infoCard-supplier">Reference number: {this.props.user.reference}</p>
                                :null}
                            {(this.props.user.phoneNumber)?<p className="infoCard-phone"><Icon type="mobile" />{this.props.user.phoneNumber}</p>:null}
                            {(this.props.user.email)?<p className="infoCard-email"><Icon type="mail" />{this.props.user.email}</p>:null}
                            <p className="infoCard-site"><Icon type="global" />{this.props.user.terminal.name}</p>
                        </Col>
                        <Col xs={24} md={7} className="infoCard-date">
                            <div className={"margin-bottom-half"}>
                                <p className={"margin-bottom-light"}><span>Reserve Number: </span>
                                    <span className="reserve-number">
                                    <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
                                        {this.props.user.reserveNumber}
                                    </Link>
                                </span>
                                </p>
                            </div>
                            <div className={"margin-bottom-half"}>
                                <p>Booking Date</p>
                                <p>{this.getFormatDate(this.props.user.createDate)}</p>
                            </div>
                            <div>
                                <p>Departure Date</p>
                                <p>{this.getFormatDate(this.props.user.departureDate)}</p>
                            </div>
                        </Col>
                        <Col xs={24} md={5} className="infoCard-price">
                            <div className={"margin-bottom-half bold-price" }>
                                <Price currency={this.props.user.saleCurrency.code}>{this.props.user.salePrice}</Price>
                            </div>
                            {(this.props.user.discountPrice === 0)?null:((this.props.user.salePrice - this.props.user.introPrice)>0)?
                                <div className={"green discount-status-box"}>
                                    <Icon type="check-circle" className={"font-22 margin-right-light"} />
                                    <div className={"inline-block"}>
                                        <p>Your Profit: <Price currency={this.props.user.introCurrency.code}>{this.props.user.salePrice - this.props.user.introPrice}</Price></p>
                                        <p>Profit Percentage: { Math.round( (this.props.user.salePrice - this.props.user.introPrice) *100 /this.props.user.salePrice) }   %</p>
                                    </div>

                                </div>
                                :
                                <div className={"red discount-status-box"}>
                                    <Tooltip placement="top" title={" Commission is negative! "}>
                                        <Icon type="warning" className={"font-22 margin-right-light"} />
                                    </Tooltip>
                                    <div>
                                        <p>Your Loss: <Price currency={this.props.user.introCurrency.code}>{ this.props.user.introPrice - this.props.user.salePrice}</Price></p>
                                        <p>Loss Percentage: { Math.round( (this.props.user.introPrice - this.props.user.salePrice) *100 /this.props.user.salePrice) }   % </p>
                                    </div>
                                </div>

                            }
                        </Col>
                        <Col xs={24} md={5} className="infoCard-button">
                            <div>
                                <Tag 
                                color={
                                    (this.props.user.paymentStatus === "ISSUED")?"green"
                                    :(this.props.user.paymentStatus === "REFUNDED")?"purple"
                                    :(this.props.user.paymentStatus === "PAYMENT_SUCCESSFUL")?"gold"
                                    :(this.props.user.paymentStatus === "REGISTERED")?"purple"
                                    :(this.props.user.paymentStatus === "WEB_SERVICE_UNSUCCESSFUL")?"red"
                                    :(this.props.user.paymentStatus === "UNAVAILABLE")?"red"
                                    :(this.props.user.paymentStatus === "CANCELED")?""
                                    :(this.props.user.paymentStatus === "WEB_SERVICE_CANCEL")?"gold"
                                    :(this.props.user.paymentStatus === "UNDEFINED")?"red"
                                    :(this.props.user.paymentStatus === "PENDING")?"blue"
                                    :(this.props.user.paymentStatus === "PRICE_CHANGE")?"red"
                                    :""}
                                >
                                    {this.props.user.paymentStatus}
                                </Tag>
                            </div>
                            <Row gutter={5} type="flex">
                                <Col  xs={(this.props.user.paymentStatus==="ISSUED")?12:24}>
                                    <Button className={"margin-bottom-light"} onClick={()=>this.createPDF()} block><Icon type="printer"/> {(isHottel === "HOTEL_GLOBAL")?'Voucher':'Ticket'} </Button>
                                </Col>
                                {this.props.user.paymentStatus==="ISSUED" &&
                                <Col xs={12}>
                                <Button className={"margin-bottom-light"}  type={"dashed"} block><a target={'_blank'} href={`https://voucher.imt.as/en/factor/${(isHottel === "HOTEL_GLOBAL")?"hotel":"flight"}?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`}>Invoice</a></Button>
                                </Col>
                                }
                            </Row>
                            <div>
                                <Button className={"margin-bottom-light"}  type={"dashed"} block><Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>Details</Link></Button>
                            </div>
                            <div>
                                {this.props.user.paymentStatus==="PENDING" && isHottel === "HOTEL_GLOBAL" &&
                                <Button block type={"primary"}><Link to={`/payment/choice?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`}>Payment</Link></Button>
                                }
                                
                            </div>


                        </Col>
                    </Row> */}
                </Card>
            </div>                                                         
        )
    }
}
const RouterHOC=withRouter(InfoCard);
const mapDispatchToProps=(dispatch)=>({
    VoidPNR: (param)=>dispatch(VoidPNR(param)),
    CancelPNR:(param)=>dispatch(CancelPNR(param))
})
export default connect(null,mapDispatchToProps)(RouterHOC)