//import model and types
import {FlightActions} from '../Enums/ActionTypes';
//actions
export const setFlightLoading=(param)=>({type:FlightActions.SetFlightLoading,input:param});
export const searchFlight=(param)=>({type:FlightActions.SearchFlight,input:param});
export const saveFlightResult=(param)=>({type:FlightActions.SaveFlightResult,input:param});
export const setFlightFilter=(param)=>({type:FlightActions.SetFlightFilter,input:param});
export const applyFlightFilter=()=>({type:FlightActions.ApplyFlightFilter});
export const validateFlight=(param)=>({type:FlightActions.ValidateFlight,input:param});
export const setSelectedFlight=(param)=>({type:FlightActions.SetFlightSelected,input:param});
export const getPNRFlight=(param)=>({type:FlightActions.getFlightPNR,input:param});
export const setPNRFlight=(param)=>({type:FlightActions.setFlightPNR,input:param});
export const getPNRDetails=(param)=>({type:FlightActions.getPNRDetails,input:param});
export const setPNRDetails=(param)=>({type:FlightActions.setPNRDetails,input:param});
export const PricingPNR=(param)=>({type:FlightActions.pricePNR,input:param});
export const IssuePNR=(param)=>({type:FlightActions.issuePNR,input:param});
export const VoidPNR=(param)=>({type:FlightActions.voidTicket,input:param});
export const CancelPNR=(param)=>({type:FlightActions.cancelPNR,input:param});
export const  getDirectTicket=(param)=>({type:FlightActions.getDirectTicketByID,input:param});
export const setFlightSorting=(input)=>({type:FlightActions.sortFlightResult,input:{...input}});



