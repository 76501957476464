import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Col,Row} from 'antd';
export default class DatePickerDrop extends React.PureComponent{
    static  propTypes={
        format:PropTypes.string.isRequired,
        defaultValue:PropTypes.string.isRequired,
        name:PropTypes.string,
        min:PropTypes.string.isRequired,
        max:PropTypes.string.isRequired,
    };

    state={
        year:moment(this.props.min,this.props.format).get("year"),
        month:moment(this.props.min,this.props.format).get("month")+1,
        day:moment(this.props.min,this.props.format).get("date"),
        yearArr:[],
        monthArr:[],
        dateArr:[],
        output:null
    };
    componentDidMount() {
        this.initilRange();
    }
    handleDate=(feild,value)=>{
        let cashData=this.state;
        cashData[feild]=parseInt(value);
        this.setState({
            ...cashData
        },()=>{
            this.initilRange();
        })
    }
    initilRange=()=>{
        let yearArr=[];
        let monthArr=[];
        let dateArr=[];
        let minYear=moment(this.props.min,this.props.format).get("year");
        let maxYear=moment(this.props.max,this.props.format).get("year");
        let minMonth=(this.state.year===minYear)?moment(this.props.min,this.props.format).get("month")+1:1;
        let maxMonth=(this.state.year===maxYear)?moment(this.props.max,this.props.format).get("month")+1:12;
        let minDay=(this.state.month===minMonth && this.state.year===minYear)?moment(this.props.min,this.props.format).get("date"):1;
        //cal max date
        let maxDay=null;
        if((this.state.month===maxMonth && this.state.year===maxYear)){
            maxDay=moment(this.props.max,this.props.format).get("date");
        }else{
            let arr31=[1,3,5,7,8,10,12];
            let arr30=[4,6,9,11];
            if(arr31.indexOf(this.state.month)!==-1){
                maxDay=31;
            }else if(arr30.indexOf(this.state.month)!==-1){
                maxDay=30;
            }else{
                if(this.state.year%4===0){
                    maxDay=29;
                }else{
                    maxDay=28;
                }
            }
        }
        for(let yearValue=minYear;yearValue<=maxYear;yearValue++){
            yearArr.push(yearValue);
        }
        for(let monthValue=minMonth;monthValue<=maxMonth;monthValue++){
            monthArr.push(monthValue)
        }
        for(let dateValue=minDay;dateValue<=maxDay;dateValue++){
            dateArr.push(dateValue)
        }
        this.setState({
            yearArr,monthArr,dateArr
        },()=>this.createOutput(moment().set('year',this.state.year).set('month',this.state.month-1).set('date',this.state.day)))
    };
    createOutput=(value)=>{
        this.setState({
            output:value.format(this.props.format)
        },()=>{(this.props.change)&&this.props.change(value)})

    };
    render() {
        return(
            <div className={"date-picker-drop"}>
                <Row gutter={10}>
                    <Col xs={8}>
                        <select value={this.state.year} onChange={(e)=>{this.handleDate('year',e.target.value)}}>
                        {
                            this.state.yearArr.map((item,index)=><option key={index} value={item}>{item}</option>)
                        }
                        </select>
                    </Col>
                    <Col xs={8}>
                        <select value={this.state.month} onChange={(e)=>{this.handleDate('month',e.target.value)}}>
                        {
                            this.state.monthArr.map((item,index)=><option key={index} value={item}>{item}</option>)
                        }
                        </select>
                    </Col>
                    <Col xs={8}>
                        <select value={this.state.day} onChange={(e)=>{this.handleDate('day',e.target.value)}}>
                        {
                            this.state.dateArr.map((item,index)=><option key={index} value={item}>{item}</option>)
                        }
                        </select>
                    </Col>
                </Row>
                <input type={'hidden'} name={this.props.name} value={(this.state.output)?this.state.output:""}/>
            </div>
        )
    }
}