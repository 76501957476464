import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
//import React components
import Autocomplete from '../../../commond/Autocomplete';
import SelectRoom from '../../../commond/SelectRoom';
import {Button, Col, DatePicker, Form, Row} from "antd";
import {HeaderRequest, HotelV2, Urls} from "../../../../Enums/APIAddress";
import {Country} from "../../../../Enums/Types";
import queryString from "query-string";
import moment from "moment";

class HotelFormSearch extends React.Component {
    state = {
        //search parameters
        locationName: null,
        locationCode: null,
        adults: [2],
        children: [0],
        ages: [0],
        night: 0,
        first: 0,
        last: 9,
        checkInDefault: null,
        checkoutDefault: null,
        //show result
        formValidationRequest: true,
        showHotelResult: false,
        dateInfo: null,
        NationalityCode: 0,
        typeSearch: null,
        showVisualLoad: true
    };

    componentDidMount() {
        //if in this page we have Search Param URL we call get hotel to search
        if (this.props.location.search) {
            this.getHotel();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search) {
            if (this.props.location.search !== prevProps.location.search) {
                //if in this page available if user change URL we have to search again
                this.getHotel();
            }
        }
    }

    //convert URL to Search Param and call Search Action
    getHotel = () => {
        //first we take URL and convert it to URLSearchParam
        let parameters = queryString.parse(this.props.location.search);
        //detect CheckIn or Checkin
        let checkIn;
        let checkOut;
        if (window.location.pathname === "/hotel/details") {
            checkIn = parameters.Checkin;
            checkOut = parameters.Checkout;
        } else {
            checkIn = parameters.CheckIn;
            checkOut = parameters.CheckOut;
        }

        //change default value and read their new value from URL
        this.setState({
            locationName: parameters.Location,
            locationCode: parameters.LocationId,
            NationalityCode: parameters.NationalityCode,
            checkInDefault: moment(checkIn, 'YYYY-MM-DD'),
            checkoutDefault: moment(checkOut, 'YYYY-MM-DD'),
            adults: parameters.Adults.split(",").map(item => parseInt(item)),
            children: parameters.Children.split(",").map(item => parseInt(item)),
            ages: (parameters.Ages) ? parameters.Ages.split(",").map(item => parseInt(item)) : null,
            showHotelResult: true
        });
    };
    //calculate number of night
    handleNight = e => {
        if (e.length !== 0) {
            this.setState({night: e[1].diff(e[0], 'days')})
        }
    };
    //receive location name and code form Autocomplete Component
    bindingAutoComplete = (e) => {
        this.setState({
            locationName: e.selectedText,
            locationCode: e.selectedValue,
            typeSearch: e.type
        })
    };
    //receive Room parameters from ShowRoom Component
    bindingRoomSelect = (e) => {
        this.setState({
            adults: e.adult,
            children: e.children,
            ages: e.age
        })
    };
    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            formValidationRequest: false
        });
        this.props.form.validateFields((err, value) => {
            if (!err && this.state.locationCode) {
                if (this.state.typeSearch === 6) {
                    this.props.history.push(`/hotel/details?Id=${this.state.locationCode}&Adults=${this.state.adults}&Children=${this.state.children}&Ages=${this.state.ages}&Checkin=${value.datePicker[0].format("YYYY-MM-DD")}&Checkout=${value.datePicker[1].format("YYYY-MM-DD")}&time=${Date.now()}`);
                } else {
                    let address = `/hotel/result?LocationId=${this.state.locationCode}&Location=${this.state.locationName}&CheckIn=${value.datePicker[0].format("YYYY-MM-DD")}&CheckOut=${value.datePicker[1].format("YYYY-MM-DD")}&Adults=${this.state.adults}&Children=${this.state.children}`;
                    if (this.state.ages && this.state.ages.length !== 0) {
                        address += `&Ages=${this.state.ages}`;
                    } else {
                        address += `&Ages=0`;
                    }
                    if (this.state.NationalityCode) {
                        address += `&NationalityCode=${this.state.NationalityCode}`;
                    }
                    address += `&time=${Date.now()}`;
                    this.props.history.push(address);
                }
            }
        })
    };

    render() {
        const autocompleteValidation = (this.state.formValidationRequest) ? true : this.state.locationCode;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className={(this.props.home === "true")?"search-section hotel-search-section":"search-section hotel-search-section bg-grey"}>
                <div className="container">
                    <Row gutter={15}>
                        <Col xs={24} sm={12} md={12} lg={(this.props.home === "true")?24:3} className="gutter-row">
                            <div className="gutter-box">
                                <div className="form-group">
                                    <label htmlFor={"locationName"}>Destination</label>
                                    <Autocomplete id={"locationName"} defaultText={this.state.locationName} defaultValue={this.state.locationCode} validate={autocompleteValidation}
                                                  getValue={this.bindingAutoComplete} placeholder={"Enter your destination from list"} url={Urls.Type + Urls.HotelV2 + HotelV2.GetLocation}
                                                  min={3} header={HeaderRequest} param={"value"} ref={this.autocomplete}/>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={(this.props.home === "true")?24:5} className="gutter-row">
                            <div className="gutter-box">
                                <div className="form-group">
                                    <label>Enter check in and check out</label>
                                    <Form.Item>
                                        {
                                            (this.state.checkInDefault) &&
                                            getFieldDecorator('datePicker', {
                                                initialValue: [this.state.checkInDefault, this.state.checkoutDefault],
                                                rules: [{required: true, message: 'Please select check-in and check-out date'}]
                                            })(
                                                <DatePicker.RangePicker onChange={(e) => {
                                                    this.handleNight(e)
                                                }} format={'DD/MM/YYYY'}/>
                                            )
                                        }
                                        {
                                            (!this.state.checkInDefault) &&
                                            getFieldDecorator('datePicker', {
                                                rules: [{required: true, message: 'Please select check-in and check-out date'}]
                                            })(
                                                <DatePicker.RangePicker onChange={(e) => {
                                                    this.handleNight(e)
                                                }} format={'DD/MM/YYYY'}/>
                                            )
                                        }

                                        {/*<label>Night</label>*/}
                                        {/*<Input className='night' value={this.state.night} />*/}

                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={(this.props.home === "true")?24:11}>
                            <SelectRoom onSelectItem={this.bindingRoomSelect} defaultAdult={this.state.adults} defaultChildren={this.state.children} defaultAges={this.state.ages}/>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={(this.props.home === "true")?24:2}>
                            <div className="form-group">
                                <label htmlFor={"nationality"}>Nationality: </label>
                                <select value={this.state.NationalityCode} id={"nationality"} onChange={(e) => {
                                    this.setState({NationalityCode: e.target.value})
                                }}>
                                    <option value={0}>-</option>
                                    {Country.map((itemCountry, index) => <option key={index} value={itemCountry.Code}>{itemCountry.Nationality}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={(this.props.home === "true")?24:3} className="clearfix">
                            <Button block icon={this.props.showHotelLoad ? "loading" : "search"} type="primary" loading={false} htmlType={'submit'}
                                    onMouseDown={() => this.setState({showVisualLoad: false})} onMouseUp={() => this.setState({showVisualLoad: true})}
                                    className="align-labels pull-right margin-bottom">
                                Search
                            </Button>
                        </Col>

                    </Row>
                </div>
                <div className={(this.props.showHotelLoad && this.state.showVisualLoad) ? "progress loading-bar" : "loading-bar"}/>
            </Form>
        )
    }
}

const WrappedSearchHotelForm = Form.create({
    name: 'hotel_search',
})(HotelFormSearch);
const MapStateToProp = (state) => ({
    showHotelLoad: state.Hotel.loadingHotel
});
const WithRouter = withRouter(WrappedSearchHotelForm);
export default connect(MapStateToProp)(WithRouter);
