import {PaymentActions} from '../Enums/ActionTypes';
export const setReserveID=(param)=>({type:PaymentActions.setReserveID,input:param});
export const getReserveByID=(param)=>({type:PaymentActions.getReserveByID,input:param});
export const setReserveInformation=(param)=>({type:PaymentActions.setReserveInformation,input:param});
export const getPaymentList=(param)=>({type:PaymentActions.getPaymentInformation,input:param});
export const setPaymentList=(param)=>({type:PaymentActions.setPaymentInformation,input:param});
export const sendPaymentMethod=(param)=>({type:PaymentActions.setPaymentMethod,input:param});
export const getDeposits=()=>({type:PaymentActions.getDeposits});
export const setDeposits=(param)=>({type:PaymentActions.setDeposits,input:param});
export const getDepositsGateways=(param)=>({type:PaymentActions.getDepositsGateways,input:param});
export const setDepositsGateways=(param)=>({type:PaymentActions.setDepositsGateways,input:param});
export const sendDepositMethod=(param)=>({type:PaymentActions.setDepositMethod,input:param});
export const getDepositBalance=(param)=>({type:PaymentActions.getDepositBalance,input:param});
export const setDepositBalance=(param)=>({type:PaymentActions.setDepositBalance,input:param});
export const confirmDeposit=(param)=>({type:PaymentActions.confirmDeposit,input:param});
export const refundDeposit=(param)=>({type:PaymentActions.refundDeposit,input:param});
export const setBookStatus=(param)=>({type:PaymentActions.setBookStatus,input:param});
export const confirmPayment=(param)=>({type:PaymentActions.confirmPayment,input:param});