import {UserActions} from './../Enums/ActionTypes';
import {HeaderRequest} from './../Enums/APIAddress';

export const authentication = (input => ({type: UserActions.Authentication, input: {...input}}));
export const loginUser = (input) => ({type: UserActions.Login, input: {...input}});
export const editUser = (input) => ({type: UserActions.SetUser, input: {...input}});
export const sendEmailRecoverUser = (input) => ({type: UserActions.SendRecoverEmail, input: {...input}});
export const changePassword = (input) => ({type: UserActions.ChangePassword, input: {...input}});
export const logoutUser = () => ({type: UserActions.Logout});
export const changeCurrency = (input) => {
    HeaderRequest["bs.currency"] = input;
    return {type: UserActions.ChangeCurrency, input: input}
};
export const resetPassword = (input) => ({type: UserActions.ResetPassword, input: {...input}});
export const updateUser = (input) => ({type: UserActions.UpdateUser, input: {...input}});
export const getTenant = (input) => ({type: UserActions.GetTenant, input: {...input}});
export const setTenant = (input) => ({type: UserActions.SetTenant, input: input});
