import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import ChangeInformation from "./ChangeInformation";
import {Urls,Account} from "../../../../Enums/APIAddress"
import React from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {
    Button,
    Table,
    Icon,
    Breadcrumb, Layout, notification, Modal, Form, Col, Input, Select, Row
} from "antd";


import {connect} from "react-redux";
import {getPNRDetails, PricingPNR} from '../../../../Actions/FlightAction';
import axios from "axios";
import {getHeaderRequest} from "../../../../Enums/APIAddress";

const {Option} = Select;

const {Content} = Layout;

class AccountIndex extends React.Component {
    state = {
        data: [],
        visible: false,
        password: '',
        id: '',
        statusUser: "all",
        Keyword: '',
        visibleDelete:false,
        spinner: true
    };

    //delete user
    confirmDelete=(id)=>{
        let header=getHeaderRequest(true);
        axios.delete(`${Urls.Type}${Urls.User}${Account.DeleteUsers}?id=${id}`,{
            headers: {
                ...header
            }
        })
        .then((data) => {
                if (data.error) {
                    notification.info({
                        message: "error",
                        description: data.error.message,
                    });
                } else {
                    this._getData()
                    notification.info({
                        message: "success",
                        description: "user deleted",
                    });
                }
                Modal.destroyAll();
            }).catch(
            (e) => {
                notification.info({
                    message: "error",
                    description: "error",
                });
                Modal.destroyAll();
            }
        );
    }
    delete = (id) => {
        const {confirm}=Modal;
        confirm({
            title:'delete user',
            content: 'Are you sure to delete?',
            okText:'Yes',
            cancelText:'No',
            onCancel:() => Modal.destroyAll(),
            onOk:() => this.confirmDelete(id)
        })

    }
    componentDidMount() {
        //get data user
        this._getData();
    }
    //get data filter
    _filter = () => {
        let {statusUser, Keyword} = this.state;
        let param=new URLSearchParams();
        param.append("MaxResultCount",100);
        param.append("SkipCount",0);
        param.append("Keyword",Keyword);
        if(this.state.statusUser!=="all"){
            param.append("IsActive",statusUser);
        }
        let header=getHeaderRequest(true)
        axios.get(`${Urls.Type}${Urls.User}${Account.GetAll}?${param}`, {
            headers: {
                ...header
            }
    }).then(({data}) => {
        this.setState({data: data.result.items})

        });

    };
    //list user
    _getData = () => {
        let req = {
            MaxResultCount: 100,
            SkipCount: 0,
        };
        let param=new URLSearchParams();
        param.append("MaxResultCount",req.MaxResultCount);
        param.append("SkipCount",req.SkipCount);
        let header=getHeaderRequest(true);
        axios.get(`${Urls.Type}${Urls.User}${Account.GetAll}?${param}`, {
            headers: {
                ...header
            }
        }).then(({data}) => {
            this.setState({
                data: data.result.items,
                spinner:false})
        })
    };
    // clear data filter to get all user
    clear = () => {
        this.setState({
            statusUser: "all",
            Keyword: '',
        });
        this._getData()
    };
    _handleOk = () => {
        let {id, password} = this.state;
        let header=getHeaderRequest(true);
        if(this.state.confirmPassword!==this.state.password){
            notification.error({
                message: "Error",
                description: "The passwords not equal",
            });
        }else{
            axios.post(`${Urls.Type}${Urls.User}${Account.ResetPasswordUser}`,{
                    Id:id,
                    NewPassword:password
                },
                {
                    headers: {
                        ...header
                    }
                }
            ).then((data) => {
                notification.info({
                    message: "success",
                    description: "The password changed",
                });
                this.setState({
                    visible: false,
                    password: '',
                    confirmPassword:''
                });
            });
        }

    };
    //close modal
    _handleCancel = e => {
        this.setState({
            visible: false,
            password: '',
            confirmPassword:''
        });
    };
    render() {
        let {data} = this.state;
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Email address',
                dataIndex: 'emailAddress',
                key: 'emailAddress',
            },
            {
                title: 'Name',
                key: 'name',
                render: (data) => <span>
            {
                data.gender ? 'Mr ' : 'Ms '
            }
                    {data.firstName} {' '} {data.lastName}
            </span>
            },
            {
                title: 'Phone number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: 'Status',
                key: 'isActive',
                render: (data) => (
                    data.isActive ?
                    <span><Icon style={{color: 'green'}} type="check"/> Enable</span>
                    :
                    <span><Icon style={{color: 'red'}} type="close"/> Disable</span>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (data) => (
                    <div className="action-btns">
                        <Link to={`/user/edit/${data.id}`}>
                            <Button
                                className="margin-bottom-light margin-right-light"
                                type="primary"
                                icon="edit"
                                title="Edit"><span className="hidden-xs hidden-sm hidden-md hidden-lg" >Edit</span>
                            </Button>
                        </Link>
                        <Button
                            onClick={() => this.delete(data.id)}
                            type="danger"
                            icon="delete"
                            className="margin-right-light margin-bottom-light"
                            title="Delete"><span className="hidden-xs hidden-sm hidden-md hidden-lg">Delete</span>
                        </Button>
                        <Button
                            className="margin-bottom-light"
                            onClick={() => {
                                this.setState({password: '', id: data.id, visible: true});
                            }}
                            icon="unlock" 
                            title="Change password"><span className="hidden-xs hidden-sm hidden-md hidden-lg" >Change Password</span>
                        </Button>
                    </div>
                ),
            },
        ];


        return (
            <Content>
                <div className="breadcrumpt-section">
                    <div className="container">                        
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/user'}>user</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="container">
                    <Link to={`/user/create`}>
                        <Button
                            type="primary"
                            className="margin-bottom">
                            create
                            a
                            new user
                            <Icon type="right"/>
                        </Button>
                    </Link>
                    <Modal
                        title="Change Password"
                        visible={this.state.visible}
                        onOk={() => this._handleOk()}
                        onCancel={() => this._handleCancel()}
                    >
                        <label>Insert new Password for changes</label>
                        <Input.Password value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}/>
                        <label>Confirm new Password</label>
                        <Input.Password value={this.state.confirmPassword} onChange={(e) => this.setState({confirmPassword: e.target.value})}/>
                    </Modal>
                    <Form>
                        <Row gutter={10}>
                            <Col xs={24} sm={10} md={8} lg={6} xl={5}>
                                <label>
                                    Keyword
                                </label>
                                <Form.Item>
                                    <Input placeholder={"Search ..."} value={this.state.Keyword}   onChange={(e) => this.setState({Keyword: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={5} lg={3} xl={2}>
                                <label>User Active </label>
                                <Select
                                    showSearch
                                    placeholder="Select a User Status"
                                    optionFilterProp="children"
                                    value={this.state.statusUser}
                                    onChange={(value) => this.setState({statusUser: value})}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option value={"all"}>All</Option>
                                    <Option value={'true'}>Active</Option>
                                    <Option value={'false'}>Disable</Option>
                                </Select>
                            </Col>

                            <Col xs={24} sm={6} md={6} lg={4} xl={3}>
                                <label> filter </label>
                                <Form.Item>
                                    <Button
                                        onClick={() => this._filter()}
                                        htmlType="submit"
                                        type="primary" 
                                        className="margin-right-light">
                                        <Icon type="filter"/>
                                    </Button>
                                    <Button
                                        onClick={() => this.clear()}
                                        htmlType="reset"
                                        className="margin-right-light">
                                        <Icon type="close"/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table className="itours-table users-list-table" loading={this.state.spinner} rowKey="id" columns={columns} dataSource={data}/>
                    <Switch>
                        <Route path="/user/resetpassword" exact component={ResetPassword}/>
                        <Route path="/user/changepassword" exact component={ChangePassword}/>
                        <Route path="/user/changeinformation" exact component={ChangeInformation}/>
                    </Switch>
                </div>
            </Content>
        )
    }


}

const MapStateToProps = (state) => ({
    PNRDetails: state.Flight.PNRDetails
});
const DispatchToProps = (dispatch) => ({
    getPNRDetails: (param) => {
        dispatch(getPNRDetails(param))
    },
    PricingPNR: (param) => {
        dispatch(PricingPNR(param))
    }
});
export default connect(MapStateToProps, DispatchToProps)(AccountIndex);
