//import library
import axios from 'axios';
import {getHeaderRequest,Urls} from '../Enums/APIAddress';
//Declaration
class Reserves{
    defaultParam={
        "reserveTypeIds": null,
        "reserveId": null,
        "phoneNumber": null,
        "email": null,
        "lastname": null,
        "departureFrom": null,
        "reference":null,
        "departureTo": null,
        "createFrom": null,
        "createTo": null,
        "reserveStatus": null,
        "pageNumber": 0,
        "pageSize": 20
    };
    getReserves=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response = await axios.post(Urls.GraphQL,
                {
                    query: `
                        mutation getReserves($filter:ReserveInput){
                        reserves(filter:$filter){
                          reserves{
                            type:typeId,
                            firstName,
                            lastName,
                            phoneNumber,
                            email,
                            username,
                            reserveNumber:id,
                            createDate,
                            departureDate,
                            salePrice,
                            reference,
                            saleCurrency{
                              code
                            },
                            introPrice,
                            introCurrency{code},
                            discountPercent,
                            discountPrice
                            paymentStatus:statusId,
                            paymentStatus:statusId,
                            pNRStatus,
                            terminal{
                              name
                            },
                            supplier{
                              id
                             }
                          },
                          totalCount
                        }
                        }
                    `,
                    variables: {
                        "filter": {
                            ...this.defaultParam,
                            ...param
                        }
                    }
                }, {headers: {...header}});
            return response.data.data.reserves;
        }catch (error) {
            return error;
        }
    }
    getTransactions=async(id)=>{

        let header=getHeaderRequest(true);
        try{
            let response=await axios.post(Urls.GraphQL,{
                    query:`
                        mutation getTransactions($filter:TransactionInput!){
                        transactions(filter:$filter){
                          transactions{
                            id,
                            isConfirmed,
                            transferType,
                            transactionType,
                            gateway{
                              name
                            },
                            createDateTime,
                            amount,
                            currency{
                              code
                            },
                            cardNumber,
                            accountName
                          },
                          totalCount
                        }
                        }
                    `,
                    variables:
                        {
                          "filter": {
                            "reserveId": id
                            }
                        }

                },{headers: {...header}});
                return response.data.data.transactions;
        }catch (e) {
            return e;
        }
    }
    getGatewayLogs=async(id)=>{
        let header=getHeaderRequest(true)
        try{
            let response=await axios.post(Urls.GraphQL,{
                query:`
                    mutation gatewayLogs($filter:GatewayLogInput){
                    gatewayLogs(filter:$filter){
                      gatewayLogs{
                        id,
                        isConfirmed,
                        gateway{
                        name
                        },
                        requestDateTime,
                        amount,
                        currency{
                        code
                        },
                        responseDateTime
                          }
                    
                    }
                    }
                    `,
                variables:
                    {
                         filter:{
                             reserveId: id,
                             pageSize: 200,
                             pageNumber: 1
                        }
                    }
            },{headers: {...header}});
            return response.data.data;
        }catch (e) {
            return e;
        }
    }
    getReserveStatus=async()=>{
        let header=getHeaderRequest(true)
        try{
            let response=await axios.post(Urls.GraphQL,{
                query:`
                        query{
                          reserveStatusType{
                            text,value
                          }
                        }
                    `
            },{headers: {...header}});
            return response.data.data;
        }catch (error) {
            return error;
        }
    }

}
export default new Reserves();