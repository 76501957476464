//import library
//react core
import React from 'react';
//import component
import Stage from './view/Stage';

class App extends React.Component {
    render() {
        return <Stage/>
    }
}

export default App;
