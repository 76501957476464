import React, {PureComponent }  from 'react';
import moment from 'moment';
import {Icon,Tooltip,Tag} from 'antd';


class Itineraries extends PureComponent{
    render(){
        return(
            <div className="itinery-details">
                    {
                        this.props.model.map((itineryItem, index) => {
                            return (
                                <div key={index} className="itinery-item text-center">
                                    <div className={"text-left vr-padding itinerary-header"}>
                                        <div className="text-left  font-18  margin-right" >
                                            <b>{itineryItem.flightSegments[0].departureAirport.cityName} </b>
                                            <Icon type="arrow-right" className="margin-right-half margin-left-half" />
                                            <b> {itineryItem.flightSegments[itineryItem.flightSegments.length - 1].arrivalAirport.cityName}</b>
                                        </div>
                                        <strong className="no-wrap "> Duration: {((itineryItem.journeyDuration).indexOf(".")>0)?moment(itineryItem.journeyDuration, "d.HH:mm:ss").format("d [day(s)] HH[h] mm[m]"):moment(itineryItem.journeyDuration, "HH:mm:ss").format("HH[h] mm[m]")} </strong>
                                    </div>
                                    <div className={"responsive-table-holder"}>
                                        <table className="itours-table">
                                            <thead>
                                            <tr>
                                                <th className="detail-airline">Airline</th>
                                                <th className="detail-f-number">Flight Number</th>
                                                <th className="detail-class">
                                                    Cabin Class (Code)
                                                </th>
                                                <th>Baggage(s)</th>
                                                <th className="detail-location">Departure</th>
                                                <th className="detail-time">Departure Time</th>
                                                <th className="detail-location">Arrival</th>
                                                <th className="detail-time">Arrival Time</th>
                                                <th className="detail-duration">Duration</th>
                                            </tr>
                                            </thead>
                                            {
                                                itineryItem.flightSegments.map((item, index2 ,array) => {
                                                    return (
                                                        <tbody key={item.flightNumber}>
                                                            <tr >
                                                                <td className="detail-airline">
                                                                    <div>
                                                                        <img
                                                                            src={item.marketingAirline.photoUrl}
                                                                            alt={item.marketingAirline.name}
                                                                            className="airline"/>
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-13">{item.marketingAirline.name}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="detail-f-number">
                                                                    {item.flightNumber}
                                                                </td>
                                                                <td className="detail-class">
                                                                    <div className="font-12"><b>{item.cabinClass.name} ({item.cabinClass.code})</b> </div>
                                                                    <Tag title="resBookDesigCode" className="no-margin">{item.resBookDesigCode}</Tag>
                                                                </td>
                                                                <td>
                                                                    {item.baggageInformation.map((baggageItem=><div key={baggageItem.passengerType} className={"no-wrap"}>{baggageItem.baggageAllowance} {baggageItem.unitType} for {baggageItem.passengerType}</div>))}
                                                                </td>
                                                                <td className="detail-location">
                                                                    <span className="font-13">{item.departureAirport.locationName} </span>
                                                                    <b>({item.departureAirport.locationCode})</b>
                                                                    {item.departureAirport.terminalID &&
                                                                    <div>Terminal {item.departureAirport.terminalID}</div>
                                                                    }
                                                                    <div className="font-13">
                                                                        <span>{item.departureAirport.cityName}, </span>
                                                                        <span>{item.departureAirport.countryName} </span>
                                                                    </div>

                                                                </td>
                                                                <td className="detail-time">
                                                                    <div className="no-wrap">{moment(item.departureDateTime).format("YYYY-MM-DD")}</div>
                                                                    <div className="font-13">{moment(item.departureDateTime).format("dddd")} </div>
                                                                    <div className="font-13"> {moment(item.departureDateTime).format("HH:mm")}</div>
                                                                </td>
                                                                <td className="detail-location">
                                                                    <span className="font-13">{item.arrivalAirport.locationName} </span>
                                                                    <b>({item.arrivalAirport.locationCode})</b>
                                                                    {item.arrivalAirport.terminalID &&
                                                                    <div className="small">Terminal  {item.arrivalAirport.terminalID}</div>
                                                                    }

                                                                    <div className="font-13">
                                                                        <span>{item.arrivalAirport.cityName}, </span>
                                                                        <span> {item.arrivalAirport.countryName} </span>
                                                                    </div>
                                                                </td>
                                                                <td className="detail-time">
                                                                    <div className="no-wrap">{moment(item.arrivalDateTime).format("YYYY-MM-DD")}</div>
                                                                    <div className="font-13">{moment(item.arrivalDateTime).format("dddd")} </div>
                                                                    <div className="font-13"> {moment(item.arrivalDateTime).format("HH:mm")}</div>
                                                                </td>
                                                                <td className="detail-duration">
                                                                    <b className="no-wrap">{moment(item.flightDuration, "HH:mm:ss").format("HH[h] mm[m]")}</b>
                                                                </td>
                                                            </tr>

                                                            {
                                                                (item.stopTime !== "00:00:00") &&
                                                                <tr className={(!(item.arrivalAirport.locationCode === array[index2+1].departureAirport.locationCode))?
                                                                    "stop-info change-airport"
                                                                    :(item.arrivalAirport.terminalID === array[index2+1].departureAirport.terminalID)?
                                                                        "stop-info "
                                                                        :
                                                                        "stop-info change-terminal"
                                                                }>
                                                                    <td colSpan={8} >
                                                                        {(!(item.arrivalAirport.locationCode === array[index2+1].departureAirport.locationCode))?
                                                                            <div className="text-left">
                                                                                <Icon type="clock-circle" />
                                                                                <span>
                                                                                    <b> Stop for airport change: </b> You will be changing airports (<b>{item.arrivalAirport.locationCode}</b> to <b>{array[index2+1].departureAirport.locationCode}</b>). A transit visa may be required.
                                                                                    <Tooltip title={<span>You will have to change airport from <u> {item.arrivalAirport.locationName} </u> to <u>  {array[index2+1].departureAirport.locationName} </u> to check into and board your next flight, and may need a transit visa as you’ll be existing thee airport.</span>}>
                                                                                        <Icon type="info-circle" className="margin-left-light" />
                                                                                    </Tooltip>
                                                                                </span>
                                                                            </div>

                                                                            :(!(item.arrivalAirport.terminalID === array[index2+1].departureAirport.terminalID))?
                                                                                <div className="text-left">
                                                                                    <Icon type="clock-circle" />
                                                                                    <span> <b> Terminal change:</b> you will be changing terminals (<b>{item.arrivalAirport.terminalID} </b> to <b> {array[index2+1].departureAirport.terminalID}</b>) at </span>
                                                                                    <span> {item.arrivalAirport.locationName} </span>
                                                                                </div>
                                                                                :
                                                                                <div className="text-left">
                                                                                    <Icon type="clock-circle" />
                                                                                    <b> Waiting time in </b>
                                                                                    <b> {item.arrivalAirport.locationName} </b>
                                                                                </div>
                                                                        }
                                                                    </td>
                                                                    <td><b className="pull-end font-14 no-wrap">{moment(item.stopTime, "HH:mm:ss").format("HH[h] mm[m]")}</b></td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>

                                </div>

                            )
                        })
                    }
                </div>
            )
    }
}
export default Itineraries;
// render(){
//     return(
//         <div className="itinery-details">
//
//
//             {
//                 this.props.model.map((itineryItem, index) => {
//                     return (
//                         <div key={index} className="itinery-item text-center">
//                             <div className={"text-left vr-padding itinerary-header"}>
//                                 <div className="text-left  font-18  margin-right" >
//                                     <b>{itineryItem.flightSegments[0].departureAirport.cityName} </b>
//                                     <Icon type="arrow-right" className="margin-right-half margin-left-half" />
//                                     <b> {itineryItem.flightSegments[itineryItem.flightSegments.length - 1].arrivalAirport.cityName}</b>
//                                 </div>
//                                 <strong className="no-wrap "> Duration: {((itineryItem.journeyDuration).indexOf(".")>0)?moment(itineryItem.journeyDuration, "d.HH:mm:ss").format("d [day(s)] HH[h] mm[m]"):moment(itineryItem.journeyDuration, "HH:mm:ss").format("HH[h] mm[m]")} </strong>
//                             </div>
//                             <div className={"responsive-table-holder"}>
//                                 <div className="responsive-table">
//                                     <div className="itinery-row table-header">
//                                         <div className="detail-airline">Airline</div>
//                                         <div className="detail-f-number">Flight number</div>
//                                         <div className="detail-class">
//                                             Cabin class (code)
//                                         </div>
//                                         <div>Baggage(s)</div>
//                                         <div className="detail-location">Departure</div>
//                                         <div className="detail-time">Departure time</div>
//                                         <div className="detail-location">Arrival</div>
//                                         <div className="detail-time">Arrival time</div>
//                                         <div className="detail-duration">Duration</div>
//                                     </div>
//                                     {
//                                         itineryItem.flightSegments.map((item, index2 ,array) => {
//                                             return (
//                                                 <>
//                                                     <div className="itinery-row" key={item.flightNumber}>
//                                                         <div className="detail-airline">
//                                                             <div>
//                                                                 <img
//                                                                     src={item.marketingAirline.photoUrl}
//                                                                     alt={item.marketingAirline.name}
//                                                                     className="airline"/>
//                                                             </div>
//                                                             <div>
//                                                                 <span className="font-13">{item.marketingAirline.name}</span>
//                                                             </div>
//                                                         </div>
//                                                         <div className="detail-f-number">
//                                                             {item.flightNumber}
//                                                         </div>
//                                                         <div className="detail-class">
//                                                             <div className="font-12"><b>{item.cabinClass.name} ({item.cabinClass.code})</b> </div>
//                                                             <Tag title="resBookDesigCode" className="no-margin">{item.resBookDesigCode}</Tag>
//                                                         </div>
//                                                         <div>
//                                                             {item.baggageInformation.map((baggageItem=><div>{baggageItem.baggageAllowance} {baggageItem.unitType} for {baggageItem.passengerType}</div>))}
//                                                         </div>
//                                                         <div className="detail-location">
//                                                             <span className="font-13">{item.departureAirport.locationName} </span>
//                                                             <b>({item.departureAirport.locationCode})</b>
//                                                             {item.departureAirport.terminalID &&
//                                                             <div>Terminal {item.departureAirport.terminalID}</div>
//                                                             }
//                                                             <div className="font-13">
//                                                                 <span>{item.departureAirport.cityName}, </span>
//                                                                 <span>{item.departureAirport.countryName} </span>
//                                                             </div>
//
//                                                         </div>
//                                                         <div className="detail-time">
//                                                             <div className="no-wrap">{moment(item.departureDateTime).format("YYYY-MM-DD")}</div>
//                                                             <div className="font-13">{moment(item.departureDateTime).format("dddd")} </div>
//                                                             <div className="font-13"> {moment(item.departureDateTime).format("HH:mm")}</div>
//                                                         </div>
//                                                         <div className="detail-location">
//                                                             <span className="font-13">{item.arrivalAirport.locationName} </span>
//                                                             <b>({item.arrivalAirport.locationCode})</b>
//                                                             {item.arrivalAirport.terminalID &&
//                                                             <div className="small">Terminal  {item.arrivalAirport.terminalID}</div>
//                                                             }
//
//                                                             <div className="font-13">
//                                                                 <span>{item.arrivalAirport.cityName}, </span>
//                                                                 <span> {item.arrivalAirport.countryName} </span>
//                                                             </div>
//                                                         </div>
//                                                         <div className="detail-time">
//                                                             <div className="no-wrap">{moment(item.arrivalDateTime).format("YYYY-MM-DD")}</div>
//                                                             <div className="font-13">{moment(item.arrivalDateTime).format("dddd")} </div>
//                                                             <div className="font-13"> {moment(item.arrivalDateTime).format("HH:mm")}</div>
//                                                         </div>
//                                                         <div className="detail-duration">
//                                                             <b className="no-wrap">{moment(item.flightDuration, "HH:mm:ss").format("HH[h] mm[m]")}</b>
//                                                         </div>
//                                                     </div>
//
//                                                     {
//                                                         (item.stopTime !== "00:00:00") &&
//                                                         <div className={(!(item.arrivalAirport.locationCode === array[index2+1].departureAirport.locationCode))?
//                                                             "stop-info change-airport"
//                                                             :(item.arrivalAirport.terminalID === array[index2+1].departureAirport.terminalID)?
//                                                                 "stop-info "
//                                                                 :
//                                                                 "stop-info change-terminal"
//                                                         }>
//                                                             <div className="stop-message">
//                                                                 {(!(item.arrivalAirport.locationCode === array[index2+1].departureAirport.locationCode))?
//                                                                     <div className="text-left">
//                                                                         <Icon type="clock-circle" />
//                                                                         <span>
//                                                                                     <b> Stop for airport change: </b> You will be changing airports (<b>{item.arrivalAirport.locationCode}</b> to <b>{array[index2+1].departureAirport.locationCode}</b>). A transit visa may be required.
//                                                                                     <Tooltip title={<span>You will have to change airport from <u> {item.arrivalAirport.locationName} </u> to <u>  {array[index2+1].departureAirport.locationName} </u> to check into and board your next flight, and may need a transit visa as you’ll be existing thee airport.</span>}>
//                                                                                         <Icon type="info-circle" className="margin-left-light" />
//                                                                                     </Tooltip>
//                                                                                 </span>
//                                                                     </div>
//
//                                                                     :(!(item.arrivalAirport.terminalID === array[index2+1].departureAirport.terminalID))?
//                                                                         <div className="text-left">
//                                                                             <Icon type="clock-circle" />
//                                                                             <span> <b> Terminal change:</b> you will be changing terminals (<b>{item.arrivalAirport.terminalID} </b> to <b> {array[index2+1].departureAirport.terminalID}</b>) at </span>
//                                                                             <span> {item.arrivalAirport.locationName} </span>
//                                                                         </div>
//                                                                         :
//                                                                         <div className="text-left">
//                                                                             <Icon type="clock-circle" />
//                                                                             <span> Waiting time in </span>
//                                                                             <span> {item.arrivalAirport.locationName} </span>
//                                                                         </div>
//                                                                 }
//                                                             </div>
//                                                             <div className="detail-duration">
//                                                                 <b className="pull-end font-14 no-wrap">{moment(item.stopTime, "HH:mm:ss").format("HH[h] mm[m]")}</b>
//                                                             </div>
//                                                         </div>
//                                                     }
//                                                 </>
//                                             )
//                                         })
//                                     }
//                                 </div>
//                             </div>
//
//                         </div>
//
//                     )
//                 })
//             }
//         </div>
//     )
// }