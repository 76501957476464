import React from 'react';
import {connect} from 'react-redux';
import {Form, Input, Button,Icon,Col,Row,Card} from 'antd';
import {changePassword} from './../../../../Actions/AccountAction';
class ChangePassword extends React.Component{
    state={
        currentPassword:null,
        newPassword:null,
        confirmPassword:null
    };
    //handle form
    handleSubmit=e=>{
        e.preventDefault();
        this.props.form.validateFields((err,value)=>{
            if(!err){
                this.props.changePassword({current:this.state.currentPassword,new:this.state.confirmPassword})
            }
        })
    };
    //validate functions
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('You should enter new password again,two new password not equal!!');
        } else {
            callback();
        }
    };
    render() {
        const {getFieldDecorator} = this.props.form;
        return(
            <Form onSubmit={this.handleSubmit} className={'change_password'}>
                <Card className="min-height-400">
                    <Row gutter={15} style={{marginBottom:"10px"}}>
                        <Col xs={24}md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Username</label>
                                <Input prefix={<Icon type="user" className={'user'} />} value={this.props.username} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col xs={24}md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Current password</label>
                                <Form.Item>
                                    {
                                        getFieldDecorator('currentPassword',{
                                            rules:[{required:true,message:'Please enter your current password'}]
                                        })(
                                            <Input.Password prefix={<Icon type="safety" className={'icon_input'} />} placeholder={'current password'} onChange={(e)=>{this.setState({currentPassword:e.target.value})}} />
                                        )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col xs={24}md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">New password</label>
                                <Form.Item>
                                    {
                                        getFieldDecorator('newPassword',{
                                            rules:[{required:true,message:'Please enter your new password'},{min:6,message:'your password should have more than 6 characters'}]
                                        })(
                                            <Input.Password prefix={<Icon type="safety" className={'icon_input'} />} placeholder={'new password'} onChange={(e)=>{this.setState({newPassword:e.target.value})}} />
                                        )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col xs={24}md={8} className="gutter-row">
                            <div className="gutter-box">
                                <label className="form-label">Confirm new password</label>
                                <Form.Item>
                                    {
                                        getFieldDecorator('confirmPassword',{
                                            rules:[
                                                {required:true,message:'Please confirm your new password'},
                                                {min:6,message:'your password should have more than 6 characters'},
                                                {validator:this.compareToFirstPassword}
                                            ],

                                        })(
                                            <Input.Password prefix={<Icon type="safety" className={'icon_input'} />} placeholder={'confirm password'} onChange={(e)=>{this.setState({confirmPassword:e.target.value})}} />
                                        )}
                                </Form.Item>
                            </div>

                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col xs={24} className="gutter-row text-right">
                            <Button loading={this.props.showInlineLoad} type="primary" htmlType={"submit"}>Change Password</Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }
}
//map to props
const mapDispatchToProps=(dispatch)=>({
    changePassword:(params)=>dispatch(changePassword(params))
});
const MapStateToProp=(state)=>({
    showInlineLoad:state.Notification.loading.showInline,
    username:state.User.userName
});
const WrappedChangePasswordForm = Form.create({ name: 'change_password' })(ChangePassword);
export default connect(MapStateToProp,mapDispatchToProps)(WrappedChangePasswordForm);
