import React from 'react';
import {connect} from 'react-redux';
import Price from "../../../commond/Price";
import {Spin} from 'antd';
import {FilterType,FilterName} from "../../../../Enums/Types";
import {setFlightFilter,applyFlightFilter} from '../../../../Actions/FlightAction';
class AirlineFilter extends React.Component{
    handleSelect=(evt,type,filterType)=>{
        if(evt.target.type==="checkbox"){
            let value=evt.target.value;
            if(evt.target.checked){
                this.props.setFlightFilter({isAdd:true,type,value,filterType});
            }else{
                this.props.setFlightFilter({isAdd:false,type,value,filterType});

            }
        }else{
            let textInput=evt.target.value;
            this.props.setFlightFilter({isAdd:true,type,textInput,filterType});
        }
        this.props.applyFlightFilter();

    }
    //show shadow
    scrollEffect=()=>{
        let airlinesHolder =document.getElementById("airline_filter_items"),
            xPos = airlinesHolder.scrollLeft,
            filtersWidth = airlinesHolder.offsetWidth,
            filtersScrolledWidth = airlinesHolder.scrollWidth,
            leftShadow = document.getElementById('left_shadow'),
            rightShadow = document.getElementById('right_shadow');
            
        if ( xPos < (filtersScrolledWidth - filtersWidth )){
            rightShadow.style.opacity = 1;
            if( xPos === 0){
                leftShadow.style.opacity = 0;
            }else{
                leftShadow.style.opacity = 1;
            }
        }
        else if((filtersScrolledWidth - filtersWidth) > 0){
            rightShadow.style.opacity = 0;
            if( xPos > 0){
                leftShadow.style.opacity = 1;
            }else{
                leftShadow.style.opacity = 0;
            }

        }else{
            leftShadow.style.opacity = 0;
            rightShadow.style.opacity = 0;
        }
    }
    render() {
        const {airlinePrice}=this.props.filtershow;
        return(
            (airlinePrice && airlinePrice.length)?
            <div className={"airline-filter-wrapper"}>  
                <div className={"airline-filter-label"}>
                    <div id="left_shadow" className="left-shadow"/>
                    <div className="airline">Airline name</div>
                    <div className="label gray-bg">Direct</div>
                    <div className="label blue-bg">1+ Stop</div>
                </div>
                <div className="airline-items-holder clearfix" id="airline_filter_items" onScroll={this.scrollEffect} onLoad={this.scrollEffect}>
                    {
                        airlinePrice.map((item,index)=>{
                            return(
                                <div className="airline-item" key={index}>
                                    <img
                                        className="airline-logo"
                                        alt={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                        title={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                        src={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl} />
                                    {
                                        item.noneStop?
                                            <div className={"checkbox-holder ltr"}>
                                                <input className="custom-checkbox" id={"none" + index} type="checkbox" value={item.noneStop.airline+'|0'} onChange={(e)=>{this.handleSelect(e,FilterName.airlinePrice,FilterType.Union)}}/>
                                                <label className="checkbox-label gray-bg" htmlFor={"none" + index}><Price currency={this.props.currency}>{item.noneStop.priceInfo.totalFare}</Price></label>
                                            </div>
                                            :
                                            <div className="no-value gray-bg" > - </div>
                                    }
                                    {
                                        item.multiStop?
                                            <div className={"checkbox-holder ltr"}>
                                                <input className="custom-checkbox" id={"multi" + index} type="checkbox" value={item.multiStop.airline+'|1'} onChange={(e)=>{this.handleSelect(e,FilterName.airlinePrice,FilterType.Union)}}/>
                                                <label className="checkbox-label blue-bg" htmlFor={"multi" + index}><Price currency={this.props.currency}>{item.multiStop.priceInfo.totalFare}</Price></label>
                                            </div>
                                            :
                                            <div className="no-value blue-bg" > - </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div id="right_shadow"  className="right-shadow"/>
            </div>
            :
            <div className={"text-center vr-padding"}>
                <Spin tip="loading...">
                </Spin>
            </div>
        )
    }
}
const dispatchToProps=(dispatch)=>({
    setFlightFilter:(data)=>dispatch(setFlightFilter(data)),
    applyFlightFilter:()=>dispatch(applyFlightFilter())
})
const stateToProps=(state)=>({
    filtershow:state.Flight.filterShow,
    currency:state.User.currency
})
export default connect(stateToProps,dispatchToProps)(AirlineFilter)