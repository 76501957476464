import React from 'react';
import Rate from '../../../commond/Rate';
import Price from '../../../commond/Price';
import NullImage from '../../../../Images/design/default-hotel-image.svg';
import {Card, Icon, Tag} from 'antd';
import moment from "moment";
import {getCancellationStatusName} from "../../../../utils/enumConverter";

export default class SummaryHotel extends React.PureComponent {
    componentDidMount() {
        // function sidesEffect() {
        //     if ( document.getElementsByClassName("sides-holder").length > 0 ){
        //         var docTop = document.documentElement.scrollTop,
        //         elmntsTop = document.getElementsByClassName("sides-holder")[0].offsetTop,
        //         elmnt1 = document.getElementsByClassName("side-item")[0],
        //         elmnt2 = document.getElementsByClassName("side-item")[1],
        //         h1 = elmnt1.offsetHeight,
        //         h2 = elmnt2.offsetHeight,
        //         h,
        //         H,
        //         smallSide,
        //         bigSide,
        //         smallSideParent,
        //         smallSideWidth,
        //         smallSidePosLeft;
        //
        //
        //         if ( h1 < h2){
        //             smallSide =  document.getElementsByClassName("side-item")[0];
        //             bigSide =  document.getElementsByClassName("side-item")[1];
        //         } else{
        //             smallSide =  document.getElementsByClassName("side-item")[1];
        //             bigSide =  document.getElementsByClassName("side-item")[0];
        //         }
        //         smallSideParent = smallSide.parentElement;
        //         smallSideParent.style.position = "relative";
        //         smallSideParent.style.minHeight = "1px";
        //         h = smallSide.offsetHeight;
        //         H = bigSide.offsetHeight;
        //         smallSideWidth = smallSideParent.offsetWidth ;
        //         smallSidePosLeft = smallSideParent.getBoundingClientRect().left ;
        //         if ( window.innerWidth < 850 ){
        //             smallSide.style.position = "static";
        //             smallSide.style.top = "";
        //             smallSide.style.bottom = "";
        //             smallSide.style.width = "";
        //             smallSide.style.left = "";
        //         } else{
        //             if ( docTop < elmntsTop ) {
        //
        //                 smallSide.style.position = "static";
        //                 smallSide.style.top = "";
        //                 smallSide.style.bottom = "";
        //                 smallSide.style.width = "";
        //                 smallSide.style.left = "";
        //
        //             } else {
        //                 if( docTop < ( elmntsTop + H - h )) {
        //                     smallSide.style.position = "fixed";
        //                     smallSide.style.top = 0;
        //                     smallSide.style.bottom = "";
        //                     smallSide.style.width = smallSideWidth + "px";
        //                     smallSide.style.left = smallSidePosLeft + "px";
        //
        //                 } else {
        //                     smallSide.style.position = "absolute";
        //                     smallSide.style.top = "";
        //                     smallSide.style.bottom = 0;
        //                     smallSide.style.width = "";
        //                     smallSide.style.left = 0;
        //                 }
        //             }
        //         }
        //     }
        //
        // }
        // document.onscroll = sidesEffect;
        // window.onresize = sidesEffect;
    }

    render() {
        return (
            <Card title="Booking Summary">
                <div className="hotel-img-titles">
                    {(!this.props.model.accommodation.mainPhoto) ?
                        <div className="hotel-image default-hotel-image" style={{backgroundImage: `url(${NullImage})`}}></div>
                        :
                        <div className="hotel-image  main-hotel-image" style={{backgroundImage: `url(${this.props.model.accommodation.mainPhoto})`}}></div>
                    }
                    <div className="hotel-name-star full-width">
                        <h5 className="normal-title">
                            {this.props.model.accommodation.name}
                        </h5>
                        <div className="hotelRange">
                            {(this.props.model.accommodation.rating) && <Rate number={this.props.model.accommodation.rating}/>}
                        </div>
                        <div className="addressFeild"><Icon type="environment"/> {this.props.model.accommodation.address}</div>
                    </div>
                </div>
                <div className="pair-row">
                    <span>Check-in</span>
                    <b>{this.props.model.date.checkIn.split("T")[0]}</b>

                </div>
                <div className="pair-row">
                    <span>Check-out</span>
                    <b>{this.props.model.date.checkOut.split("T")[0]}</b>

                </div>
                <div className="pair-row">
                    <span>Length of stay</span>
                    <b>{this.props.night} night(s)</b>

                </div>

                {
                    this.props.model.accommodation.rooms.map((item, index) => {
                        console.log(this.props);
                        return (
                            <div className="clearfix border-top" key={index}>
                                <div className="pull-left">
                                    <span className="blue"> {item.name} </span>
                                </div>
                                {/*<div className="pull-right" dir="ltr">*/}
                                {/*    <strong><Price currency={this.props.currencyCode}>{item.salePrice}</Price></strong>*/}
                                {/*</div>*/}
                                <div className="clearfix">
                                    <ul className="bullet-list">
                                        <li>{item.board.name}</li>
                                    </ul>
                                </div>
                                <div className="clearfix">
                                    (<span className="inline-block">{item.adults} Adults</span>
                                    {(item.children > 0) ?
                                        <span className="inline-block">, {item.children} Children </span>
                                        :
                                        null
                                    })
                                </div>
                                <div className="cancelationContainer body-direction">
                                    <div className='margin-bottom-light'>
                                        <strong>Cancellation Rules</strong>
                                    </div>
                                    <Tag
                                        color={this.props.model.cancellationPolicy.status === 'Refundable' ? 'green' : 'red'}>
                                        <span className='font-14'>{getCancellationStatusName(this.props.model.cancellationPolicy.status)}</span>
                                    </Tag>
                                    <ul className={"cancelation-rules-list"}>
                                        {this.props.model.cancellationPolicy.fees.map(fee => {
                                            if (fee.amount === 0) {
                                                return (<li key={this.props.model.cancellationPolicy.fees.indexOf(fee)}>Cancellation is <span
                                                    className='green'>FREE</span> before {moment(fee.toDate).format("DD MMM YYYY")}</li>)
                                            } else {
                                                return (<li key={this.props.model.cancellationPolicy.fees.indexOf(fee)}><Price
                                                    currency={this.props.currencyCode}>{fee.amount}</Price> on and after
                                                    date {moment(fee.fromDate).format("DD MMM YYYY")}</li>)
                                            }
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="border-top">
                    <div className="pair-row red font-18">
                        <b>Total price</b>
                        <b>
                            <span> <Price currency={this.props.currencyCode}>{this.props.model.salePrice}</Price></span>
                        </b>
                    </div>

                    {this.props.showButton &&
                    <div className="confirmButton">
                        <button className="button red-btn full-width" type="submit">Continue to payment</button>
                    </div>
                    }

                </div>
            </Card>
        )
    }
}

