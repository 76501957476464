//import library
import React from 'react';
import {connect} from 'react-redux';
import {withRouter,Link} from 'react-router-dom';
import queryString from "query-string";
import {Row,Col,Card,Alert,Spin,Button,Icon,Steps,Result} from 'antd';

//import URL and Type
import {bookStatus} from '../../../../Enums/Types';
//import component
import SummaryHotel from '../Hotel/SummaryHotel';
import FlightSummary from "../Flight/FlightSummary";
import {Urls} from '../../../../Enums/APIAddress';
import PaymentList from './PaymentList';
//import action
import {getReserveByID,getPaymentList,sendPaymentMethod,getDepositBalance,confirmDeposit,setBookStatus,confirmPayment} from '../../../../Actions/PaymentAction';
import {book,clearHotelSelected} from "../../../../Actions/HotelAction";
import {IssuePNR,setSelectedFlight} from "../../../../Actions/FlightAction.js";
import Price from "../../../commond/Price";
const ShowText=(props)=>{
    return(
        <div>{props.text} {props.showButton && <Link to={"/tracking/reserves"}>Reserves list</Link>}</div>
    )
}
class ChoicePayment extends React.Component{
    state={
        showSuccess:false,
        selectedBank:false,
        reserveId: null,
    };
    getInit=()=>{
        this.props.setSelectedFlight(null);
        let parameters=queryString.parse(this.props.location.search);
        this.props.confirmPayment(parameters);
        this.setState({
            reserveId:parameters.reserveId
        })
    };
    componentWillMount() {
        this.props.clearHotelSelected();

    }
    componentDidMount() {
        //reset payment status
        this.props.setBookStatus(bookStatus.idle);
        this.getInit();
    }
    bankSelect=(bankID,param)=>{
        let URLparams=queryString.parse(this.props.location.search);
        URLparams=URLparams.reserveId;
        let params={reserveId:URLparams,gatewayId:bankID,callbackUrl:`http://${Urls.Local.Url}/payment/choice`,ipAddress:Urls.Local.Url,formParams:{...param}};
        this.props.sendPaymentMethod(params);
    };
    confirmDeposit=()=>{
        let parameters=queryString.parse(this.props.location.search);
        this.props.confirmDeposit({reserveId:parameters.reserveId.trim(),username:parameters.username.trim()});
    }
    render() {

        const {PaymentInfo,hotelInformation,flightInformation}=this.props;
        const {Step} =Steps;
        const depositHeader =
            <div>
                <div className="clearfix"><div className="pull-left">Deposit Payment</div> <span className={"deposit-icon itours-icon"} /></div>
            </div>;
        return(
            <>
                {(PaymentInfo)?
                    <div className="container vr-padding">
                        {
                            (hotelInformation && hotelInformation.accommodation) &&
                            <div className={"vr-padding margin-bottom"}>
                                {/* % current based on payment status */}
                                <Steps current={2}>
                                    <Step title="Choose your room"/>
                                    <Step title="Enter your details"/>
                                    <Step title="Confirm and payment"/>
                                    <Step title="Complete the purchase"/>
                                </Steps>
                            </div>
                        }
                        {/* {
                            (flightInformation) &&
                            <div className={"vr-padding margin-bottom"}>
                                //current based on payment status
                                <Steps current={2}>
                                    <Step title="Choose your flight"/>
                                    <Step title="Enter your details"/>
                                    <Step title="Confirm and payment"/>
                                    <Step title="Complete the purchase"/>
                                </Steps>
                            </div>
                        } */}
                        {/*show Error in payment*/}
                        {this.state.showError &&
                            <Alert
                                className="margin-bottom"
                                message="Payment operation failed"
                                description="try again ,  You can call (+47) 22112744 for more tracing."

                                type="error"
                                showIcon
                            />
                        }
                        {
                            (this.props.bookStatus === bookStatus.process) &&
                            <Result
                                status="success"
                                icon={<div class="result-process-loading"><Icon type="loading"/></div>}
                                title="Your payment has been successful!"
                                subTitle="Your ticket will be issued in a few moments, Please do not close this page until the ticket is issued."
                                extra={[
                                    <div className="margin-bottom-light">
                                        <strong className="font-18">Issuing tickets</strong>
                                    </div>,
                                    <div className="red margin-bottom-light">
                                        Please do not close this page until the ticket is issued.
                                    </div>,
                                    <div>For questions regarding this reservation, please contact Itours directly :
                                        +47 22112744</div>
                                ]}
                            />
                        }
                        {
                            (this.props.bookStatus === bookStatus.done) &&

                            <Result
                                status="success"
                                title="Your ticket was successfully issued!"
                                subTitle="We wish you a happy and memorable trip"
                                extra={[
                                    <strong className="font-22 margin-bottom">Your reserve ID: {this.state.reserveId} </strong>,
                                    <div className="margin-bottom">
                                        {/* % download voucher or Ticket */}
                                        {/*{this.props.PaymentInfo.type==="FlightGlobal"?*/}
                                        {/*    <a target={"_blank"} className="button blue-btn margin-right" href={`https://voucher.imt.as/en/flight/Ticket?reserveId=${this.state.reserveId}`}>Download Voucher</a>*/}
                                        {/*    :*/}
                                        {/*    <a target={"_blank"} className="button blue-btn margin-right" href={`https://voucher.imt.as/en/Hotel/Voucher?reserveId=${this.state.reserveId}`}>Download Voucher</a>*/}

                                        {/*}*/}
                                        {/* % show details */}
                                        {this.props.PaymentInfo.type==="FlightGlobal"?
                                            <Link to={`/tracking/reserves/flight?id=${this.state.reserveId}`}><button type="button" className="button white-btn">Details</button></Link>
                                            :
                                            <Link to={`/tracking/reserves/hotel?id=${this.state.reserveId}`}><button type="button" className="button white-btn">Details</button></Link>

                                        }

                                    </div>,
                                    <div className="margin-bottom-light">
                                        <ShowText text={<span>You can check your reserves list in </span>}
                                                  showButton={true}/>
                                    </div>,
                                    <div>For questions regarding this reservation, please contact Itours directly :
                                        +47 22112744</div>
                                ]}
                            />
                        }
                        {
                            (this.props.bookStatus===bookStatus.error)&&
                            <Result
                                status="error"
                                title="Unsuccessfull payment!"
                                subTitle="There was a problem with the reservation process"
                                extra={[
                                    <div className="margin-bottom">
                                        {/* % search again */}
                                        {this.props.PaymentInfo.type==="FlightGlobal"?
                                            <Link to={`/flight`}><button type="button" className="button white-btn">Search Again</button></Link>
                                            :
                                            <Link to={`/hotel`}><button type="button" className="button white-btn">Search Again</button></Link>

                                        }
                                    </div>,
                                    <div>For questions regarding this reservation, please contact Itours directly :  +47 22112744</div>


                                ]}
                            />
                        }
                        {
                            (this.props.bookStatus===bookStatus.now)&&
                            <Result
                                status="info"
                                title="Your ticket was successfully Booked!"
                                subTitle="Your PNR will be issued Automatically and you can see your ticket on reserve page."
                                extra={[
                                    <strong className="font-22 margin-bottom">Your reserve ID: {this.state.reserveId} </strong>,
                                    <div className="margin-bottom">
                                        {/* % download voucher or Ticket */}
                                        {/*{this.props.PaymentInfo.type==="FlightGlobal"?*/}
                                        {/*    <a target={"_blank"} className="button blue-btn margin-right" href={`https://voucher.imt.as/en/flight/Ticket?reserveId=${this.state.reserveId}`}>Download Voucher</a>*/}
                                        {/*    :*/}
                                        {/*    <a target={"_blank"} className="button blue-btn margin-right" href={`https://voucher.imt.as/en/Hotel/Voucher?reserveId=${this.state.reserveId}`}>Download Voucher</a>*/}

                                        {/*}*/}
                                        {/* % show details */}
                                        {this.props.PaymentInfo.type==="FlightGlobal"?
                                            <Link to={`/tracking/reserves/flight?id=${this.state.reserveId}`}><button type="button" className="button white-btn">Details</button></Link>
                                            :
                                            <Link to={`/tracking/reserves/hotel?id=${this.state.reserveId}`}><button type="button" className="button white-btn">Details</button></Link>

                                        }

                                    </div>,
                                    <div className="margin-bottom-light">
                                        <ShowText text={<span>You can check your reserves list in </span>}
                                                  showButton={true}/>
                                    </div>,
                                    <div>For questions regarding this reservation, please contact Itours directly :
                                        +47 22112744</div>
                                ]}
                            />
                        }
                        <Row gutter={[15,15]}>
                            <Col className="gutter-row pull-right-md" xs={24} md={8} xl={6}>
                                <div className="gutter-box">
                                    {
                                    hotelInformation && hotelInformation.accommodation && hotelInformation.reserver &&
                                    <SummaryHotel model={hotelInformation} currencyCode={hotelInformation.currency.code} showButton={false} totalPrice={null} night={hotelInformation.date.duration} />
                                    }
                                    {
                                    flightInformation &&
                                    <FlightSummary
                                        showButton={true}
                                        model={flightInformation.airItinerary.originDestinationOptions}
                                        currencyCode={flightInformation.currency}
                                        fareInfo={null}
                                        totalPrice={flightInformation.flightFare.totalFare}
                                        feild={null}
                                        layout={"sidebar"}
                                    />
                                    }

                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} md={16} xl={18}>
                                <div className="gutter-box">
                                    {/*show hotel reserver's Information*/}
                                    {
                                        (PaymentInfo) &&
                                        <>
                                            <h5 className={"normal-title"}><Icon type="user"  className="margin-right-light"/>Reserver Informartion</h5>
                                            <Card title="" className="margin-bottom">
                                            <div className="pair-row">
                                                <span>Gender: </span>
                                                <span>{PaymentInfo.gender?"male":"female"}</span>
                                            </div>
                                            <div className="pair-row">
                                                <span>Firs tname: </span>
                                                <span>{PaymentInfo.firstName}</span>
                                            </div>
                                            <div className="pair-row">
                                                <span>Last name: </span>
                                                <span>{PaymentInfo.lastName}</span>
                                            </div>
                                            <div className="pair-row">
                                                <span>Email: </span>
                                                <span>{PaymentInfo.email}</span>
                                            </div>
                                            <div className="pair-row">
                                                <span>Phone number: </span>
                                                <span>{PaymentInfo.phoneNumber}</span>
                                            </div>
                                        </Card>
                                        </>
                                    }
                                    {
                                        (this.props.bookStatus===bookStatus.error || this.props.bookStatus===bookStatus.idle)&&
                                        <>
                                        <h5 className="normal-title"><Icon type="dollar" className="margin-right-light" /> Please Choice your Payment</h5>
                                        <div>
                                            {(this.props.banks)?
                                                <PaymentList banks={this.props.banks} selectedBankthis={this.bankSelect}/>
                                                :
                                                <div className="loading-section text-center">
                                                    <div className="margin-bottom">
                                                        <Spin />
                                                    </div>
                                                    <div>
                                                        <strong>Choice Payment Method is loading...</strong>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            (this.props.DepositInfo)?
                                            <Card title={depositHeader} className="margin-top margin-bottom deposit-card">
                                                <div className="clearfix">
                                                    <Button className="pull-right" type="danger" htmlType="button" onClick={()=>this.confirmDeposit()}>Pay by Deposit</Button>
                                                    <div>
                                                        <b className="margin-right">Remaining:</b>
                                                        <Price currency={this.props.DepositInfo.currencyId}>{this.props.DepositInfo.amount}</Price>
                                                    </div>
                                                </div>
                                            </Card>
                                            :
                                            <div className="loading-section text-center">
                                                <div className="margin-bottom">
                                                    <Spin />
                                                </div>
                                                <div>
                                                    <strong>Deposit information is loading ...</strong>
                                                </div>
                                            </div>
                                        }

                                        </>
                                    }

                                    {
                                        this.props.bookStatus!==bookStatus.idle && this.props.bookStatus===bookStatus.error &&
                                        <div className="status-box error margin-bottom">
                                            <div className="icon-box ">
                                                <Icon type="close-circle" className="red" />
                                            </div>
                                            <div className="details-box">
                                                <strong className="title">Unsuccessfull payment</strong>
                                                <p className="description">
                                                    Error in processing Payment.
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        (this.state.showSuccess && this.props.PaymentInfo.type==="FlightGlobal")&&
                                        <div className={"SpinPayment"}><Spin tip={"Ticketing is processing...please wait a moment,you will redirect to ticket details after a few seconds"} /></div>
                                    }
                                </div>
                            </Col>

                        </Row>
                                    


                    </div>
                    :
                    <div className="loading-section text-center">
                        <div className="margin-bottom">
                            <Spin />
                        </div>
                        <div>
                            <strong>Loading data ...</strong>
                        </div>
                    </div>
                }
            </>
        )
    }
}
const MapDispatchToProps=(dispatch)=>({
    getReserveByID:(param)=>{dispatch(getReserveByID(param))},
    getPaymentList:(param)=>{dispatch(getPaymentList(param))},
    book:(param)=>{dispatch(book(param))},
    sendPaymentMethod:(param)=>{dispatch(sendPaymentMethod(param))},
    clearHotelSelected:()=>{dispatch(clearHotelSelected())},
    getDepositBalance:(param)=>{dispatch(getDepositBalance(param))},
    confirmDeposit:(param)=>{dispatch(confirmDeposit(param))},
    IssuePNR:(param)=>{dispatch(IssuePNR(param))},
    setBookStatus:(param)=>{dispatch(setBookStatus(param))},
    confirmPayment:(param)=>{dispatch(confirmPayment(param))},
    setSelectedFlight:(param)=>dispatch(setSelectedFlight(param))

});
const MapStateToProps=(state)=>({
    banks:state.Payment.paymentList,
    PaymentInfo:state.Payment.reserverInformation,
    DepositInfo:state.Payment.depositBalance,
    hotelInformation:state.Hotel.selectedHotel,
    flightInformation:state.Flight.flightSelect,
    currency:state.User.currency,
    bookStatus:state.Payment.bookStatus
});
const RouterBind=withRouter(ChoicePayment);
export default connect(MapStateToProps,MapDispatchToProps)(RouterBind);
