//import actionType and types
import {UserActions} from './../Enums/ActionTypes';
import {Account} from './../Enums/Types';
//import utiltiy cookie
import {getStorage} from "../utils/localstorage";
//user reducer
export const User = (state = new Account(), action) => {
    switch (action.type) {
        case UserActions.SetUser:
            return {
                ...action.input,
                companyName: getStorage('ag_it_company')// CookieManage.getCookie("ag_it_company")
            };
        case UserActions.Logout:
            return {
                ...new Account()
            };
        case UserActions.ChangeCurrency:
            return {
                ...state,
                currency: action.input
            };
        case UserActions.SetTenant:
            return {
                ...state,
                tenant: action.input
            }
        default:
            return {
                ...state
            };
    }
};
