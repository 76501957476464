import React from 'react';
import PropTypes from 'prop-types';
import "../../CSS/Rate.scss";

Rate.propTypes = {
    number: PropTypes.number.isRequired,
    showNoRating: PropTypes.bool
};

function Rate(props) {
    let noRatingElement = null;
    if (props.showNoRating) {
        noRatingElement = <div>No rating</div>;
    }

    return (
        <div className={"rateContainer"}>
            {props.number > 0 ? <div className={"rate"} style={{width: props.number * 18 + 'px'}}/> : noRatingElement}
        </div>
    );
}

export default Rate;