//import library
import React from 'react';
import {Col, Row, Tabs,Table} from 'antd';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
//import component
import FlightSearchFrom from "./Flight/SearchFrom";
import HotelSearchFrom from "./Hotel/HotelFormSearch";
import Price from '../../commond/Price';
import {getReserves} from "../../../Actions/ReservesAction";
//import actions
import {getDeposits} from '../../../Actions/PaymentAction';
class Home extends React.Component{
    state={
        reserveList:[],
        mode:"waiting"
    }
    getReserveList=()=>{
        debugger;
        if(this.state.mode==="waiting"){
            let param={
                createFrom: null,
                createTo: null,
                departureFrom: null,
                departureTo: null,
                email: null,
                lastname: null,
                pageNumber: "1",
                pageSize: "20",
                phoneNumber: null,
                reference: null,
                reserveId: null,
                reserveStatus: "PENDING",
                reserveTypeIds: null
            }
            this.props.getReserves(param);
        }else if(this.state.mode==="finalized"){
            let param={
                createFrom: null,
                createTo: null,
                departureFrom: null,
                departureTo: null,
                email: null,
                lastname: null,
                pageNumber: "1",
                pageSize: "20",
                phoneNumber: null,
                reference: null,
                reserveId: null,
                reserveStatus: "ISSUED",
                reserveTypeIds: null
            }
            this.props.getReserves(param);
        }
    }
    componentDidMount() {
        this.getReserveList();
    }
    changeMode=(mode)=>{
        debugger;
        this.setState({
            mode,
            reserveList:[]
        },()=>{
            this.getReserveList();
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.Reserve.reserveList){
            if(JSON.stringify(prevProps.Reserve.reserveList)!==JSON.stringify(this.props.Reserve.reserveList)){
                this.setState({
                    reserveList:this.props.Reserve.reserveList.reserves
                })
            }
        }


    }

    render() {
        const { TabPane } = Tabs;
        const waitingPaymentsLabels = [
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (text,record) => (
                  (record.type === "FLIGHT_GLOBAL")?
                    <span className={"infocard-item-icon flight-icon itours-icon"} />
                  : (record.type === "HOTEL_GLOBAL")?
                    <span className={"infocard-item-icon hotel-orange-icon itours-icon"} />
                  :null
              )
            },
            {
              title: 'Reserver',
              dataIndex: 'reserver',
              key: 'reserver',
              render: (text,record) => (
                <strong>
                    {(record.type === "FLIGHT_GLOBAL")?
                    <Link to={`tracking/reserves/flight?id=${record.reserveNumber}`}>
                        {record.firstName} {record.lastName}
                    </Link>
                    :(record.type === "HOTEL_GLOBAL")?
                    <Link to={`tracking/reserves/hotel?id=${record.reserveNumber}`}>
                    {record.firstName} {record.lastName}
                    </Link>
                    :<>{record.firstName} {record.lastName}</>
                    }
                </strong>
                )
            },
            {
              title:"Reserve Number",
              dataIndex: 'reserveNumber',
              key: 'reserveNumber',
              render:(text,record) => (
                  <>
                      <div>
                        {record.reserveNumber}
                      </div>
                      {(record.reference)?
                        <div>
                            ({record.reference})
                        </div>:null
                      }

                  </>
              )
            },
            {
              title: 'Price',
              key: 'price',
              dataIndex: 'price',
              render: (text,record) => (
                <Price currency={record.saleCurrency.code}>{record.salePrice}</Price>
              )
            },
            {
              title: 'Contact',
              key: 'contact',
              dataIndex: 'contact',
              render : (text,record) => (
                <><div>{record.phoneNumber}</div><div>{record.email}</div></>
              )
            },
            {
              title: 'Payment',
              key: 'payment',
              dataIndex: 'payment',
              render : (text,record) => (
                record.paymentStatus==="PENDING" && record.type === "HOTEL_GLOBAL" &&
                <Link className="button green-btn small-btn" to={`/payment/choice?reserveId=${record.reserveNumber}&username=${record.username}`}>Payment</Link>
                // % what if type was flight?

              )
            }
          ];
        const finalizedBookingsLabels = [
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (text,record) => (
                  (record.type === "FLIGHT_GLOBAL")?
                    <span className={"infocard-item-icon flight-icon itours-icon"} />
                  :(record.type === "HOTEL_GLOBAL")?
                    <span className={"infocard-item-icon hotel-orange-icon itours-icon"} />
                  :null
              )
            },
            {
              title: 'Reserver',
              dataIndex: 'reserver',
              key: 'reserver',
              render: (text,record) => (
                <strong>
                    <a href={record.detailUrl}>{record.firstName} {record.lastName}</a>
                </strong>
                )
            },
            {
              title: <div><div>Reserve Number</div><div>(PNR code)</div></div>,
              dataIndex: 'reserveNumber',
              key: 'reserveNumber',
              render:(text,record) => (
                  <div>
                      <div>
                        {record.reserveNumber}
                      </div>
                      {(record.reference)?
                        <div>
                            ({record.reference})
                        </div>:null
                      }
                  </div>
              )
            },
            {
              title: 'Booking Date',
              dataIndex: 'createDate',
              key: 'createDate',
            },
            {
              title: 'Price',
              key: 'price',
              dataIndex: 'price',
              render: (text,record) => (
                <Price currency={record.saleCurrency.code}>{record.salePrice}</Price>
              )
            },
            {
              title: 'Contact',
              key: 'contact',
              dataIndex: 'contact',
              render : (text,record) => (
                <><div>{record.phoneNumber}</div><div>{record.email}</div></>
              )
            }
          ];


        const reservesListLink = <Link to={"/tracking/reserves"}>See All Bookings</Link>;
        return (
            <div className="container vr-padding">
                <Row gutter={15}>
                    <Col xs={24} lg={10} >
                        <div className="card-container margin-bottom home-search-wrapper">
                            <Tabs type="card">
                                <TabPane tab="Flight" key="1">
                                    <FlightSearchFrom home="true"/>
                                </TabPane>
                                <TabPane tab="Hotel" key="2" >
                                    <HotelSearchFrom home="true"/>
                                </TabPane>
                            </Tabs>
                        </div>
                        <div className="recent-searches-holder">
                            {/*<h5 className="normal-title">Your recent searches</h5>*/}
                            {/*<Row  gutter={15}>*/}
                            {/*    <Col xs={24} sm={12}>*/}
                            {/*        <button className="margin-bottom-half recent-search type-flight">*/}
                            {/*            <div className="grey font-12">*/}
                            {/*                Round-trip*/}
                            {/*            </div>*/}
                            {/*            <div className="font-16">*/}
                            {/*                Oslo to Helsinki*/}
                            {/*            </div>*/}
                            {/*            <div className="blue">*/}
                            {/*                Sat, 16 May - Sun, 31 May*/}
                            {/*            </div>*/}
                            {/*            <div className="grey font-12">*/}
                            {/*                Economy - 2 adults, 1 infant*/}
                            {/*            </div>*/}
                            {/*    </button>*/}
                            {/*    </Col>*/}
                            {/*    <Col xs={24} sm={12}>*/}
                            {/*        <button className="margin-bottom-half recent-search type-hotel">*/}
                            {/*            <div className="font-16">*/}
                            {/*                Oslo*/}
                            {/*            </div>*/}
                            {/*            <div className="blue">*/}
                            {/*                Sat, 16 May - Sun, 31 May*/}
                            {/*            </div>*/}
                            {/*            <div className="grey font-12">*/}
                            {/*                1 room - 1 adult, 2 children*/}
                            {/*            </div>*/}
                            {/*        </button>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </div>
                    </Col>
                    <Col xs={24} lg={14} className="home-right-section">
                        <div className="vr-padding margin-bottom-light">
                            <strong className="font-18">
                                <span> Welcome, </span>{this.props.user.firstName} {this.props.user.lastName}!
                            </strong>
                        </div>
                        <Tabs type="card" className="margin-bottom" tabBarExtraContent={reservesListLink} onChange={this.changeMode}>
                            <TabPane tab="Waiting Payments" key="waiting">
                                <Table columns={waitingPaymentsLabels} dataSource={this.state.reserveList} />
                                {/* <Empty description="There are no waiting payments"/> */}
                            </TabPane>
                            <TabPane tab="Finalized Bookings" key="finalized">
                                <Table columns={finalizedBookingsLabels} dataSource={this.state.reserveList} />
                                {/* <Empty description="There are no finalized bookings"/> */}
                            </TabPane>
                        </Tabs>
                    </Col>

                </Row>
            </div>

        )
    }
};
const mapDispatchToProps=(dispatch)=>({
    getDeposits:()=>dispatch(getDeposits()),
    getReserves:(param)=>{dispatch(getReserves(param))},

})
const mapStateToProps=(state)=>({
    depositList:state.Payment.depositList,
    user:state.User,
    Reserve:state.Reserve
})
export default connect(mapStateToProps,mapDispatchToProps)(Home);