//import  actionType and type
import {Notification as NotificationType} from './../Enums/Types';
import {NotificationActions} from './../Enums/ActionTypes';
//Notification reducer
export const Notification=(state=new NotificationType(),action)=>{
    switch (action.type) {
        case NotificationActions.SetNotification:
            return{
                ...state,
                notificationMessage:{...action.input}
            };
        case NotificationActions.SetLoading:
            return{
                ...state,
                loading:{...action.input}
            };
        default:
            return {...state}
    }
}