import {createBrowserHistory} from 'history';
//redux
import {createStore, applyMiddleware} from 'redux';
//import devtools extension for view redux value in monitor
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
//combine reducers for mix reducers
import combineReducers from '../Reducers/reducer';
//import redux-saga for create stack of redux action
import createSageMiddleware from 'redux-saga';
import {bindSaga} from '../Saga/bindSaga';

import { routerMiddleware } from 'connected-react-router'

//init redux-saga
const sagaMiddleware = createSageMiddleware();
//init store for redux
const history = createBrowserHistory();
const store = createStore(combineReducers(history), composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
//after init store we bind redux-saga to it
bindSaga(sagaMiddleware);

export {store, history};
