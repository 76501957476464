import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Price from "../../../commond/Price";
import Itineraries from '../../../commond/ItineraryDetail';
import {Row,Col,Icon,Button,Card,Collapse} from 'antd';
import _ from "lodash";
export default class FlightSummary extends React.PureComponent{
    static propTypes={
        model:PropTypes.any.isRequired,
        fareInfo:PropTypes.any.isRequired,
        totalPrice:PropTypes.any.isRequired,
        currencyCode:PropTypes.any.isRequired,
    };
    showPassenger(cnt,type,price){
        return(
            <div className={"pair-row"}>
                <div className="passenger-type-title">{cnt} <span> x </span>
                    {(type==="ADT")&& "Adult"}
                    {(type==="CHD")&& "Child"}
                    {(type==="INF")&& "Infant"}
                </div>
                <div dir="ltr">
                    <Price currency={this.props.currencyCode}>{price}</Price>
                </div>
            </div>


        )
    }
    state = {
        showItinerariModal : false
    }
    openItinerariModal = () =>{
        this.setState({
            showItinerariModal :true
        })
    }
    closeItinerariModal = () => {
        this.setState({
            showItinerariModal :false
        })
    }
    render() {
        const { Panel } = Collapse;
        return(
            (this.props.layout==="sidebar")?
            <>
            <Card title="Booking Summary" className="sidebar-flight-summary">
                {this.props.model.map((item,index)=>{
                    return(
                        <div key={index} className="itinerary-item">
                            <img src={item.flightSegments[0].marketingAirline.photoUrl}
                                title={item.flightSegments[0].marketingAirline.name}
                                alt={item.flightSegments[0].marketingAirline.name}
                                className="airline-logo"/>
                            <div className="origin">
                                <div>
                                    <b title={item.flightSegments[0].departureAirport.locationName}>{item.flightSegments[0].departureAirport.locationCode}</b>
                                </div>
                                <div className="font-12">
                                    {moment(item.flightSegments[0].departureDateTime).format("YYYY-MM-DD")}
                                </div>
                                <div>
                                    {moment(item.flightSegments[0].departureDateTime).format("HH:mm")}
                                </div>
                            </div>
                            <div className="stops text-center">
                                <div>
                                    {(item.flightSegments.length - 1 === 0) ?
                                    <span className="green">Direct</span> :
                                    <span>{item.flightSegments.length - 1} Stop(s)</span>} 
                                </div>
                                <div className="hr-divider"></div>
                                {/* <div>
                                    {item.flightSegments[0].cabinClass.name}
                                </div> */}
                                <div className="grey">
                                    {((item.journeyDuration).indexOf(".")>0)?moment(item.journeyDuration, "d.HH:mm:ss").format("d[d] HH[h] mm[m]"):moment(item.journeyDuration, "HH:mm:ss").format("HH[h] mm[m]")}
                                </div>
                            </div>
                            <div className="destination">
                                <div>
                                    <b title={item.flightSegments[item.flightSegments.length - 1].arrivalAirport.locationName}>{item.flightSegments[item.flightSegments.length - 1].arrivalAirport.locationCode}</b>
                                </div>
                                <div className="font-12">
                                    {moment(item.flightSegments[item.flightSegments.length - 1].departureDateTime).format("YYYY-MM-DD")}
                                </div>
                                <div>
                                    {moment(item.flightSegments[item.flightSegments.length - 1].arrivalDateTime).format("HH:mm")}
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="hr-divider"></div>

                
                {
                    this.props.fareInfo!==null
                    &&
                    <>
                        <h5 className="normal-title margin-top">
                            Price Details
                        </h5>
                        <Collapse bordered={false} className="prices-info">
                        {this.props.fareInfo.map((item,index)=>{
                        return(                            
                            <Panel header={
                                <div className="pair-row">
                                     <div className="passenger-type-title no-wrap">  
                                        {(item.passengerTypeQuantity.code ==="ADT")&& "Adult"}
                                        {(item.passengerTypeQuantity.code ==="CHD")&& "Child"}
                                        {(item.passengerTypeQuantity.code ==="INF")&& "Infant"}
                                        <span> (<Icon className="font-10" type="close" /> </span><span> {item.passengerTypeQuantity.quantity})</span>
                                    </div> 
                                    <div>
                                        <Price currency={this.props.currencyCode}>{item[this.props.feild].totalFare}</Price>
                                        <span> (<Icon className="font-10" type="close" /> </span><span> {item.passengerTypeQuantity.quantity})</span>
                                    </div>
                                </div>
                               
                            } key={index}> <>
                                <div className="pair-row">
                                    <span>Base fare </span>
                                    <Price currency={this.props.currencyCode}>{item[this.props.feild].baseFare}</Price> 
                                </div>
                                <div className="pair-row">
                                    <span>Commission </span>
                                    <Price currency={this.props.currencyCode}>{item[this.props.feild].commission}</Price>
                                </div>
                                <div className="pair-row">
                                    <span>Tax </span>
                                    <Price currency={this.props.currencyCode}>{(item[this.props.feild].totalTaxes)?item[this.props.feild].totalTaxes:_.sumBy(item[this.props.feild].taxes,(item)=>item.amount)}</Price>
                                </div>
                            </>
                            </Panel>                            
                            )
                        })} 
                        </Collapse>
                    </>                                           
                }
                

                <div className="pair-row no-margin-bottom card-footer">
                    <Button type="link" htmlType="button" className="no-padding" onClick={this.openItinerariModal}> Flight details</Button>
                    <b className="font-16 no-wrap"><span className="margin-right-light">Total fare: </span>
                        <Price currency={this.props.currencyCode}>{this.props.totalPrice}</Price>
                    </b>
                    
                </div>
            </Card>
            <div className={(this.state.showItinerariModal)? "itours-modal visible": "itours-modal"} >
                <div className="modal-overlay" onClick={this.closeItinerariModal}/>
                <div className="modal-inner">
                    <div className="modal-header">
                        Flight Details
                    </div>
                    <div className="modal-main">
                        <Itineraries model = {this.props.model} />
                    </div>
                    <div className="modal-footer">
                        <Button htmlType="button" type={"primary"} onClick={this.closeItinerariModal}>Ok</Button>
                    </div>
                </div>
            </div>

            </>
            :
            
            <div className="hr_padding">
                <h5 className="page-heading">
                    Booking Flight
                </h5>
                <div className="selected-flights-info margin-bottom">
                    <div className="result-list-label-holder hidden-xs hidden-sm hidden-md">
                        <Row gutter={15}>
                            <Col  xs={24} md={18} xl={20}>
                                <div className="flight-results-label">
                                    <div className="airline-col">
                                        AirLine
                                    </div>
                                    <div className="stops-col">
                                        Stop
                                    </div>
                                    <div className="depart-col">
                                        Depart
                                    </div>
                                    <div className="arrive-col">
                                        Arrive                                                                                                                  
                                    </div>
                                    <div className="duration-col text-right">
                                        Duration
                                    </div>
                                </div>                    
                            </Col>
                            <Col  xs={24} md={6} xl={4}>
                                <div className="item-footer" />
                            </Col>
                        </Row>
                    </div>
                    <div className="flight-item-content">
                        <Row gutter={15} align="middle" type="flex">
                            <Col  xs={24} md={18} xl={20}>
                                {this.props.model.map((item,index)=>{
                                    return(
                                        <div key={index} className="flight-route-item">
                                            <div className="airline-col">
                                                <img src={item.flightSegments[0].marketingAirline.photoUrl}
                                                    alt={item.flightSegments[0].marketingAirline.name}
                                                    className="airline-logo"/>
                                                <div>
                                                    
                                                    <div className="airline-name font-12">
                                                    <b className="hidden-lg hidden-xl font-14">Airline: </b>
                                                    {item.flightSegments[0].marketingAirline.name}</div>
                                                    {/* <div className="flightnumber font-14">{item.flightSegments[0].flightNumber}</div> */}
                                                </div>
                                            </div>
                                            <div className="stops-col">
                                                <div className="stop"><b className="hidden-lg hidden-xl">Stops: </b> {(item.flightSegments.length - 1 === 0) ?
                                                    <span className="green">Direct</span> :
                                                    <span>{item.flightSegments.length - 1} Stop(s)</span>}
                                                </div>
                                                <div>
                                                <b className="hidden-lg hidden-xl">Cabin class: </b>
                                                {item.flightSegments[0].cabinClass.name}
                                                </div>
                                            </div>
                                            <div className="depart-col">
                                                <div>
                                                    <b className="hidden-lg hidden-xl">Origin Airport: </b>
                                                    <span className="margin-right-light">{item.flightSegments[0].departureAirport.locationName}</span>
                                                    <span>({item.flightSegments[0].departureAirport.locationCode})</span>
                                                </div>
                                                <div>
                                                    <b className="hidden-lg hidden-xl">Departure date: </b>
                                                    <span className="margin-right-light">{moment(item.flightSegments[0].departureDateTime).format("YYYY-MM-DD")}</span>
                                                    <span>{moment(item.flightSegments[0].departureDateTime).format("HH:mm")}</span>
                                                </div>
                                            </div>
                                            <div className="arrive-col">
                                                <div>
                                                    <b className="hidden-lg hidden-xl">Destination Airport: </b>
                                                    <span className="margin-right-light">{item.flightSegments[item.flightSegments.length - 1].arrivalAirport.locationName}</span>
                                                    <span>({item.flightSegments[item.flightSegments.length - 1].arrivalAirport.locationCode})</span>
                                                </div>
                                                <div>
                                                    <b className="hidden-lg hidden-xl">Arrival date: </b>
                                                    <span className="margin-right-light">{moment(item.flightSegments[item.flightSegments.length - 1].departureDateTime).format("YYYY-MM-DD")}</span>
                                                    <span>{moment(item.flightSegments[item.flightSegments.length - 1].arrivalDateTime).format("HH:mm")}</span>
                                                </div>                                           
                                                                                                
                                            </div>
                                            <div className="duration-col text-right-lg">
                                                <b className="hidden-lg hidden-xl margin-bottom">Duration: </b>
                                                {((item.journeyDuration).indexOf(".")>0)?moment(item.journeyDuration, "d.HH:mm:ss").format("d[d] HH[h] mm[m]"):moment(item.journeyDuration, "HH:mm:ss").format("HH[h] mm[m]")}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Col>
                            <Col  xs={24} md={6} xl={4}>
                                <div className="item-footer text-center">
                                    <b className="font-18"><span className="margin-right-light">Total fare: </span>
                                    <Price currency={this.props.currencyCode}>{this.props.totalPrice}</Price>
                                    </b>
                                </div>

                            </Col>                                        
                        </Row>
                    </div>
                    <div className={"flight-footer"}>
                            <Button type="link" htmlType="button" className="no-padding" onClick={this.openItinerariModal}> Flight details</Button>
                    </div>
                </div>
                <div className={(this.state.showItinerariModal)? "itours-modal visible": "itours-modal"} >
                    <div className="modal-overlay" onClick={this.closeItinerariModal}/>
                    <div className="modal-inner">
                        <div className="modal-header">
                            Flight Details
                        </div>
                        <div className="modal-main">
                            <Itineraries model = {this.props.model} />
                        </div>
                        <div className="modal-footer">
                            <Button htmlType="button" type={"primary"} onClick={this.closeItinerariModal}>Ok</Button>
                        </div>
                    </div>
                </div>
                <div className="flight-prices-info responsive-table-holder margin-bottom">
                    <table className="itours-table">
                        <thead>
                            <tr>
                                <th>Passenger type</th>
                                <th>Base fare</th>
                                <th>Commission</th>
                                <th>Tax</th>
                                <th>Total</th>                                        
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.fareInfo!==null
                                &&
                                    this.props.fareInfo.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>
                                                <div className="passenger-type-title no-wrap">  
                                                    {(item.passengerTypeQuantity.code ==="ADT")&& "Adult"}
                                                    {(item.passengerTypeQuantity.code ==="CHD")&& "Child"}
                                                    {(item.passengerTypeQuantity.code ==="INF")&& "Infant"}
                                                    <span> (<Icon className="font-10" type="close" /> </span><span> {item.passengerTypeQuantity.quantity})</span>
                                                </div>                                                            
                                            </td>
                                            <td className="no-wrap">
                                                <Price currency={this.props.currencyCode}>{item[this.props.feild].baseFare}</Price> 
                                            </td>
                                            <td className="no-wrap">
                                                <Price currency={this.props.currencyCode}>{item[this.props.feild].commission}</Price>
                                            </td>
                                            <td className="no-wrap">
                                                <Price currency={this.props.currencyCode}>{(item[this.props.feild].totalTaxes)?item[this.props.feild].totalTaxes:_.sumBy(item[this.props.feild].taxes,(item)=>item.amount)}</Price>
                                            </td>
                                            <td className="no-wrap">
                                                <Price currency={this.props.currencyCode}>{item[this.props.feild].totalFare}</Price>
                                                <span> (<Icon className="font-10" type="close" /> </span><span> {item.passengerTypeQuantity.quantity})</span>
                                            </td>
                                        </tr>
                                    )
                                })                                            
                            }      
                                <tr>
                                    <td colSpan="4"><b>Total fare</b></td>
                                    <td><b><Price currency={this.props.currencyCode}>{this.props.totalPrice}</Price></b></td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }
}
