//import library
import React from 'react';
import {connect} from 'react-redux';
import {Input, Checkbox, Card, Button} from 'antd';
import Rate from "../commond/Rate";
//import action and type
import {FilterName, FilterType} from "../../Enums/Types";
import {setHotelFilterSelect} from "../../Actions/HotelAction";
import {setFlightFilter} from "../../Actions/FlightAction";

class FilterForm extends React.Component {
    state = {
        filterVisiblity: false
    }
    showFilters = () => {
        this.setState({
            filterVisiblity: true
        });
    }
    hideFilters = () => {
        this.setState({
            filterVisiblity: false
        });
    }
    handleSelect = (evt, type, filterType, valueEl) => {
        if (evt.target.type === "checkbox") {
            let value = (isNaN(valueEl) ? valueEl : parseFloat(valueEl));
            if (evt.target.checked) {
                this.props.setHotelFilterSelect({isAdd: true, type, value, filterType});
            } else {
                this.props.setHotelFilterSelect({isAdd: false, type, value, filterType});
            }
        } else {
            let textInput = evt.target.value;
            this.props.setHotelFilterSelect({isAdd: true, type, textInput, filterType});
        }
    };
    handleSelectFlight = (evt, type, filterType, valueEl) => {
        if (evt.target.type === "checkbox") {
            let value = (isNaN(valueEl) ? valueEl : parseFloat(valueEl));
            if (evt.target.checked) {
                this.props.setFlightFilter({isAdd: true, type, value, filterType});
            } else {
                this.props.setFlightFilter({isAdd: false, type, value, filterType});

            }
        } else {
            let textInput = evt.target.value;
            this.props.setFlightFilter({isAdd: true, type, textInput, filterType});
        }

    };

    render() {
        return (
            <div>
                <Button htmlType="button" type="primary" className="filter-btn hidden-md hidden-lg hidden-xl" onClick={this.showFilters}> Filter</Button>
                <div className={this.state.filterVisiblity ? "filterholder visible" : "filterholder"}>
                    <div className="filter full-width">
                        {
                            (this.props.type === "flight") ?
                                this.props.flights.length !== 0 &&
                                <Card title="Filter">
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Flight Stop(s)
                                        </h5>
                                        <div>
                                            {this.props.filterShowFlight && this.props.filterShowFlight.stop.value[0] > 0 &&
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" id={"nonStop"} type={"checkbox"} onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.stop, FilterType.Union, 0)
                                            }}>Non Stop ({this.props.filterShowFlight.stop.value[0]})</Checkbox></div>
                                            }
                                            {this.props.filterShowFlight && this.props.filterShowFlight.stop.value[1] > 0 &&
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" id={"nonStop"} type={"checkbox"} onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.stop, FilterType.Union, 1)
                                            }}>One Stop ({this.props.filterShowFlight.stop.value[1]})</Checkbox></div>
                                            }
                                            {this.props.filterShowFlight && this.props.filterShowFlight.stop.value[2] > 0 &&
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" id={"nonStop"} type={"checkbox"} onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.stop, FilterType.Union, 2)
                                            }}>Multi Stop ({this.props.filterShowFlight.stop.value[1]})</Checkbox></div>
                                            }
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Cabin Class
                                        </h5>
                                        <div>
                                            {this.props.filterShowFlight.class.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder"><Checkbox className="custom-checkbox" id={"classCabin" + index} type="checkbox"
                                                                                                              onChange={(e) => {
                                                                                                                  this.handleSelectFlight(e, FilterName.class, FilterType.Union, item)
                                                                                                              }}>{item} </Checkbox></div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Flight Airline
                                        </h5>
                                        <div className="accordion-content">
                                            {this.props.filterShowFlight.airline.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder"><Checkbox className="custom-checkbox" id={"classCabin" + index} type="checkbox"
                                                                                                              onChange={(e) => {
                                                                                                                  this.handleSelectFlight(e, FilterName.airline, FilterType.Union, item)
                                                                                                              }}>{item} </Checkbox></div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Departure Time
                                        </h5>
                                        <div className="accordion-content">
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.departureDateTime, FilterType.Union, '00:00-05:59')
                                            }}>00:00 - 06:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.departureDateTime, FilterType.Union, '06:00-11:59')
                                            }}>06:00 - 12:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.departureDateTime, FilterType.Union, '12:00-17:59')
                                            }}>12:00 - 18:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.departureDateTime, FilterType.Union, '18:00-23:59')
                                            }}>18:00 - 00:00</Checkbox></div>
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Arrival Time
                                        </h5>
                                        <div className="accordion-content">
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.arrivalDateTime, FilterType.Union, '00:00-05:59')
                                            }}>00:00 - 06:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.arrivalDateTime, FilterType.Union, '06:00-11:59')
                                            }}>06:00 - 12:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.arrivalDateTime, FilterType.Union, '12:00-17:59')
                                            }}>12:00 - 18:00</Checkbox></div>
                                            <div className="checkbox-holder"><Checkbox className="custom-checkbox" type="checkbox" onChange={(e) => {
                                                this.handleSelectFlight(e, FilterName.arrivalDateTime, FilterType.Union, '18:00-23:59')
                                            }}>18:00 - 00:00</Checkbox></div>
                                        </div>
                                    </div>
                                </Card>
                                :
                                this.props.hotels.length !== 0 &&
                                <Card title="Filter">
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Hotel's name
                                        </h5>
                                        <div>
                                            <div className="form-group">
                                                <Input.Search className="form-control" type="text" placeholder="Enter your accomodation's name" onChange={(e) => {
                                                    this.handleSelect(e, FilterName.name, FilterType.Like)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Hotel Rate
                                        </h5>
                                        <div>
                                            {this.props.filterShow.rating.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder rating-filter"><Checkbox className="custom-checkbox" id={"rate" + index} type="checkbox"
                                                                                                                            onChange={(e) => {
                                                                                                                                this.handleSelect(e, FilterName.rating, FilterType.Union, item)
                                                                                                                            }}><Rate number={parseFloat(item)}
                                                                                                                                     showNoRating={true}/></Checkbox></div>
                                            })
                                            }
                                        </div>

                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Hotel Facilities
                                        </h5>
                                        <div>
                                            {this.props.filterShow.features.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder"><Checkbox className="custom-checkbox" id={"feature" + index} type="checkbox"
                                                                                                              onChange={(e) => {
                                                                                                                  this.handleSelect(e, FilterName.feature, FilterType.Intersection, JSON.stringify(item))
                                                                                                              }}>{item.name} </Checkbox></div>
                                            })
                                            }
                                        </div>

                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Hotel Board
                                        </h5>
                                        <div>
                                            {this.props.filterShow.boards.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder"><Checkbox className="custom-checkbox" id={"board" + index} type="checkbox"
                                                                                                              onChange={(e) => {
                                                                                                                  this.handleSelect(e, FilterName.boards, FilterType.Union, JSON.stringify(item))
                                                                                                              }}> {item.name} </Checkbox></div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="filter-item margin-bottom">
                                        <h5 className="normal-title">
                                            Hotel Location
                                        </h5>
                                        <div>
                                            {this.props.filterShow.regions.value.map((item, index) => {
                                                return <div key={index} className="checkbox-holder"><Checkbox className="custom-checkbox" id={"board" + index} type="checkbox"
                                                                                                              onChange={(e) => {
                                                                                                                  this.handleSelect(e, FilterName.regions, FilterType.Union, JSON.stringify(item))
                                                                                                              }}>
                                                    {item.name} </Checkbox></div>
                                            })
                                            }
                                        </div>
                                    </div>
                                </Card>
                        }
                    </div>
                    <Button htmlType="button" type="primary" className="filter-close-btn hidden-md hidden-lg hidden-xl" onClick={this.hideFilters}> Close filter</Button>
                </div>
            </div>
        )
    }
}

//Mapping
const mapDispatchToProp = (action) => ({
    setHotelFilterSelect: (param) => {
        action(setHotelFilterSelect(param))
    },
    setFlightFilter: (param) => {
        action(setFlightFilter(param))
    }
});
const mapStateToProps = (state) => ({
    filterShow: state.Hotel.filterShow,
    hotels: state.Hotel.hotelsBackup,
    filterShowFlight: state.Flight.filterShow,
    flights: state.Flight.flightBackup
});
export default connect(mapStateToProps, mapDispatchToProp)(FilterForm);
