//import actionType and type
import {ReserveActions} from '../Enums/ActionTypes';
import {Reserves as ReserveModel} from '../Enums/Types';
//Payment reducer
export const Reserve=(state=new ReserveModel(),action)=> {
    switch (action.type) {
        case ReserveActions.GetReserves:
            return{
                ...state,
                filterParam:action.input
            }
        case ReserveActions.SetReserves:
            return {
                ...state,
                reserveList:action.input
            }
        case ReserveActions.setTransaction:
            return {
                ...state,
                transactionList:action.input
            }
        case ReserveActions.setGatewaysLog:
            return {
                ...state,
                gatewaysLogs:action.input
            }
        case ReserveActions.setHotelInfo:
            return {
                ...state,
                hotelDetails:action.input
            }
        case ReserveActions.setFlightInfo:
            return {
                ...state,
                flightDetails:action.input
            }
        case ReserveActions.setReserveStatus:
            return {
                ...state,
                reserveStatus:action.input
            }
        default:
            return{
                ...state
            }
    }
}