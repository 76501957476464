import {setIpLocation} from "../Actions/SharedActions";
import {call, put, takeLatest} from 'redux-saga/effects';
import {SharedActions} from "../Enums/ActionTypes";
import {getIpLocationHttp} from '../APIs/Misc'

function* getIpLocation() {
    const response = yield call(getIpLocationHttp);
    if (response && !response.isAxiosError) {
        yield put(setIpLocation(response));
    }
}


export function* watchIpLocation() {
    yield takeLatest(SharedActions.GetIpLocation, getIpLocation);
}
