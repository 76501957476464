import React from 'react';
import PropTypes from 'prop-types';
/* import Lightbox from 'react-images'; */
import '../../CSS/gallery.scss';
export default class Gallery extends React.Component{
    static propTypes={
           images:PropTypes.any.isRequired
    };
    state={
        currentImage:0,
        lightboxIsOpen:false
    };
    openLightbox=(param)=> {
        this.setState({
            currentImage: param,
            lightboxIsOpen: true,
        });
    };
    closeLightbox=()=> {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    };
    gotoPrevious=()=> {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };
    gotoNext=()=> {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };
    render() {
        return(
            <div className="galleryContainer">
            <>
            <div className="hotel-gallery">
                <div className="gallery-row">
                    {((this.props.images[0]==null))?
                        null
                        :
                        <div className="large-item">
                            <div className="image" style={(this.props.images[0]==null)?null:{backgroundImage:`url(${this.props.images[0].src})`}} >
                                <div className="hover" onClick={this.openLightbox.bind(this,0)} ><span className="more-images-text">More images</span></div>
                            </div>
                        </div>
                    }
                    {((this.props.images[1]==null))?
                        null
                        :  
                        <div className="gallery-column">
                            <div>
                                <div className="image" style={(this.props.images[1]==null)?null:{backgroundImage:`url(${this.props.images[1].smallSrc})`}}>                                                            
                                    <div className="hover" onClick={this.openLightbox.bind(this,1)} ><span className="more-images-text">More images</span></div>
                                </div>
                            </div>
                            {((this.props.images[2]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[2]==null)?null:{backgroundImage:`url(${this.props.images[2].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,2)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>             
                            }   
                            {((this.props.images[3]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[3]==null)?null:{backgroundImage:`url(${this.props.images[3].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,3)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>             
                            }  
                            {((this.props.images[4]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[4]==null)?null:{backgroundImage:`url(${this.props.images[4].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,4)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>             
                            }                                                                                   

                        </div>               
                    }
                    {((this.props.images[5]==null))?
                        null
                        :
                        <div className="gallery-column">
                            <div className="">
                                <div className="image" style={(this.props.images[5]==null)?null:{backgroundImage:`url(${this.props.images[5].smallSrc})`}}>
                                    <div className="hover" onClick={this.openLightbox.bind(this,5)} ><span className="more-images-text">More images</span></div>
                                </div>
                            </div> 
                            {((this.props.images[6]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[6]==null)?null:{backgroundImage:`url(${this.props.images[6].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,6)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>    
                            } 
                            {((this.props.images[7]==null))?
                                null
                                :
                                <div>
                                    <div className="image" style={(this.props.images[7]==null)?null:{backgroundImage:`url(${this.props.images[7].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,7)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>             
                            }  
                            {((this.props.images[8]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[8]==null)?null:{backgroundImage:`url(${this.props.images[8].smallSrc})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,8)} ><span className="more-images-text">More images</span></div>
                                    </div>
                                </div>             
                            } 
                        </div>
                    }

                </div>
            </div>
            {/* <Lightbox images={this.props.images} onClose={this.closeLightbox} onClickPrev={this.gotoPrevious} onClickNext={this.gotoNext} currentImage={this.state.currentImage} isOpen={this.state.lightboxIsOpen} />             */}
            </>
            </div>
        )
    }
}
