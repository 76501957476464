import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popover} from "antd";
import Price from "../../../commond/Price";
import {useSelector} from "react-redux";

HotelAgentGainPopover.propTypes = {
    introPrice: PropTypes.number.isRequired,
    regularPrice: PropTypes.number.isRequired,
    salePrice: PropTypes.number.isRequired
};

function HotelAgentGainPopover(props) {
    let currency = useSelector(state => state.User.currency);

    const pricePopupContent = (introPrice, regularPrice, salePrice) => {
        const profit = salePrice - introPrice;
        return (
            <div className='padding-10'>
                <div className='border-bottom'>Customer : <Price currency={currency}>{salePrice}</Price></div>
                <div className='border-bottom'>Net : <Price currency={currency}>{introPrice}</Price></div>
                <div className='border-bottom green'><b>Profit : <Price currency={currency}>{profit}</Price></b></div>
                <div>*Your profit will be deposit into your bank account after Check-in.</div>
            </div>
        )
    };

    return (
        <Popover className='font-12' overlayStyle={{width: 300}} content={pricePopupContent(props.introPrice, props.regularPrice, props.salePrice)}
                 title='Commission details'
                 placement={"left"}>
            <Icon type='info-circle'/>
        </Popover>
    );
}

export default HotelAgentGainPopover;
