//import library
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col, Input, Card} from 'antd';
//import model and type
import {CountryPostalCode} from '../../../../Enums/Types';
export default class PaymentList extends React.Component{
    state={
        showSegment:null,
        formStatus:false,
        formFeilds:[],
        postalCode:"0047",
        resursBankVisibility: false
    };
    static propTypes={
        selectedBankthis:PropTypes.any.isRequired,
        banks:PropTypes.any.isRequired
    }
    //event declaration
    handlePaymentChoice(gatewaysItem){

        let form=[];
        if(gatewaysItem.form.elements.length===0){
            this.props.selectedBankthis(gatewaysItem.id);
        }else{
            gatewaysItem.form.elements.forEach(item=>{
                form[item.key]=this.state.formFeilds[item.key];
            });
            this.setState({
                formFeilds:form,
                formStatus:true
            },()=>{
                let formStatus=true;
                for(let itemFeild in this.state.formFeilds){
                    if(!this.state.formFeilds[itemFeild]){
                        formStatus=false;
                    }
                }
                if(formStatus){
                    let formData=this.state.formFeilds;
                    formData["PhoneNumber"]=this.state.postalCode+formData["PhoneNumber"];
                    this.props.selectedBankthis(gatewaysItem.id,this.state.formFeilds);
                }
            })
        }

    }
    bindData(filedName,value){

        let formData=this.state.formFeilds;
        formData[filedName]=value;
        this.setState({
            formFeilds:formData
        })
    }
    componentDidUpdate(prevProp,prevState){
        if(JSON.stringify(this.props.banks)!==JSON.stringify(prevProp.banks)){
            this.setFirstItem();
        }
    }
    resursToggleHandler = () =>{
        this.setState({
            resursBankVisibility:!this.state.resursBankVisibility
        })
    }
    setFirstItem=()=>{
        if(this.props.banks.length>0){
            this.setState({
                showSegment:this.props.banks[0].keyword
            })
        }
    }
    conditionalRender=(formItem)=>{
        let fieldLabel;
        switch (formItem.key) {
            case "Firstname":
                fieldLabel = "first name";
                break;
            case "Lastname":
                fieldLabel = "last name";
                break;
            case "Fullname":
                fieldLabel = "full name";
                break;
            case "Email":
                fieldLabel = "email";
                break;
            case "PhoneNumber":
                fieldLabel = "phone number";
                break;
            case "CountryCode":
                fieldLabel = "country code";
                break;
            case "PostalArea":
                fieldLabel = "postal area";
                break;
            case "PostalCode":
                fieldLabel = "postal code";
                break;
            case "Address":
                fieldLabel = "address";
                break;
            default:
                fieldLabel = formItem.key;
        }
        if (formItem.type !== "select") {
            return (
                <Col key={formItem.key} lg={12} xs={24} >
                    <div  className="form-group">
                        <label htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                        {
                            formItem.key==="PhoneNumber" &&
                            <select className="itours-select form_control country-code"
                                    onChange={(e)=>this.setState({postalCode:e.target.value})} value={this.state.postalCode}>
                                {CountryPostalCode.map((item,index)=><option key={index}  value={item.value}>{item.code}</option>)}
                            </select>
                        }
                        <Input id={formItem.key} className="form_control"
                               type={formItem.type}
                               onChange={(e)=>this.bindData(formItem.key,e.target.value)}
                               name={formItem.key} defaultValue={formItem.defaultValue}/>
                        {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>Please fill this field</div>
                        }
                    </div>
                </Col>
            )
        }
        else {
            return (
                <Col key={formItem.key} lg={12} xs={24} >
                    <div className="form-group">
                        <label htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                        <select className="itours-select form_control"
                                onChange={(e)=>this.bindData(formItem.key,e.target.value)} id={formItem.key} name={formItem.key}
                                defaultValue={formItem.defaultValue}>
                            <option value={null}></option>
                            {formItem.values.map((optItem,optIndex) => <option key={optIndex} value={optItem.value}>{optItem.label}</option>)}
                        </select>
                        {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>Please fill this field</div>}
                    </div>
                </Col>
            )
        }
    }
    //rendering
    render() {
        const DirectHeader =
            <div>
                <div className="clearfix"><div className="pull-left">Direct Payment</div> <span className={"deposit-icon itours-icon"} /></div>
            </div>;
        return(
            <Card title={DirectHeader} className="margin-top margin-bottom deposit-card">
                {
                    this.props.banks.map((item,key) => {
                        return (
                            <div className="payment-type-item" key={key}>
                                <div className={"payment-method-item"}>
                                    <div className={"paymentItem"}>
                                        <div className={"paymentDetails"}>
                                            <div>
                                                {item.gateways.map((gatewaysItem) => {
                                                    return (
                                                        (item.keyword==="ResursBankPartPayment")?
                                                            <div className={"gateway-item resurs-bank-item no-border-top relative"} key={gatewaysItem.name}>
                                                                <div className={"resurs-form"} key={gatewaysItem.name}>
                                                                    <img className="bank-icon margin-right-light"  src={item.image.path} alt={item.keyword} />
                                                                    <b>{item.name}</b>
                                                                    <p className="payment-method-description">{item.description}</p>
                                                                    {(this.state.resursBankVisibility)?
                                                                        <div className={"resursform-holder"}>
                                                                            <form>
                                                                                <Row gutter={15}>
                                                                                    {gatewaysItem.form.elements.map((formItem) =>this.conditionalRender(formItem))}
                                                                                    <Col lg={12} xs={24}>
                                                                                        <div className="clearfix">
                                                                                            <Button className="pull-right" type="primary" htmlType="button" onClick={this.handlePaymentChoice.bind(this,gatewaysItem)}>Send</Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </form>
                                                                        </div>
                                                                        :null}


                                                                </div>
                                                                <Button className={"resurs-toggle-btn"} type={"primary"} htmlType={"button"} onClick={this.resursToggleHandler}> {(this.state.resursBankVisibility)?"Close Form":"Open Form"}</Button>
                                                            </div>
                                                            :
                                                            <div className={"gateway-item"} key={gatewaysItem.name}>
                                                                <div>
                                                                    <img src={gatewaysItem.image.path} alt={gatewaysItem.name} className="bank-icon margin-right-light"/>
                                                                    <b>{gatewaysItem.name}</b>
                                                                    <p className="payment-method-description">{item.description}</p>
                                                                </div>
                                                                <form>
                                                                    <div className="clearfix">
                                                                        <Button className="pull-right" type="primary" htmlType="button" onClick={this.handlePaymentChoice.bind(this,gatewaysItem)}>{(item.keyword==="ResursBank")?"Send":"Select"}</Button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                    )
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Card>

        )
    }
}
