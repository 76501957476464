import React from 'react';
import {BackTop, notification, Button} from 'antd';
import {withRouter} from 'react-router-dom';
//import Component
import Login from './Sections/Account/Login';
import Header from './Sections/Layout/Header';
import FlightSearch from './Sections/Flight/FlightSearch.js';
import FlightGetPassenger from "./Sections/Flight/FlightGetPassenger";
import AddDeposit from '../../Components/view/Sections/Payment/AddDeposit';
import FlightTicketing from "./Sections/Flight/FlightTicketing";
import {default as FLITGH} from './Sections/Tracking/FlightDetails';
import {default as HOTEL} from './Sections/Tracking/HotelDetails';
import {connect} from 'react-redux';
import {authentication} from '../../Actions/AccountAction';
import {getIpLocation} from "../../Actions/SharedActions";
import {setLoading, setNotification} from '../../Actions/NotificationAction';
import {Switch, Route} from 'react-router-dom';


//import section
import Home from './Sections/Home';
import AccountIndex from './Sections/Account/AccountIndex';
import HotelSearch from './Sections/Hotel/HotelSearch';
import HotelDetails from './Sections/Hotel/HotelDetails';
import HotelSetPassenger from './Sections/Hotel/HotelSetPassenger';
import ResetPassword from './Sections/Account/ResetPassword';
import CommissionIndex from './Sections/Comission/Index';
import CommissionCreate from './Sections/Comission/CommissionCreate';
import CommissionEdit from './Sections/Comission/CommissionEdit';
import ChoicePayment from './Sections/Payment/ChoicePayment';
import Reserves from './Sections/Tracking/Reserves';
import {default as Profile} from './Sections/Profile/index';
import NotFound from '../../Components/Errors/NotFound';
import ErrorBoundary from "../Errors/ErrorBoundary";
import {default as CompanyIndex} from "../view/Sections/Company/Index"
//
import Logo from '../../Images/design/Logo.svg';
import '../../CSS/Layout.scss';
import {Layout} from 'antd';
import UserEdit from "./Sections/Account/UserEdit";
import UserCreate from "./Sections/Account/UserCreate";
import {getStorage} from "../../utils/localstorage";
import {getDeposits} from "../../Actions/PaymentAction";

const {Content, Footer} = Layout;

class Stage extends React.Component {
    componentDidMount() {
        const officeID = getStorage('token');// CookieManage.getCookie("token");
        const token = getStorage('ag_it_tenant');// CookieManage.getCookie("ag_it_tenant");
        if (token) {
            this.props.authentication({token, officeID})
            this.props.getDeposits();

        } else {
            this.props.setLoading({showAllPage: false, showInline: false});
        }
        this.props.getIpLocation();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.MessageInformation.show) {
            const key = `open${Date.now()}`;
            const btn = (
                <Button type="primary" onClick={() => notification.close(key)}>Ok</Button>
            )
            notification[this.props.MessageInformation.type]({
                message: 'Attentions',
                description: this.props.MessageInformation.content,
                placement: 'topRight',
                btn,
                key
            })
        }
        if (this.props.location !== prevProps.location) {
            this.props.getDeposits();
        }
    }

    render() {
        const {showAllPage, userID} = this.props;
        return (
            <ErrorBoundary>
                {(showAllPage) &&
                <div className="loading-full-page">
                    <div className="loading-section text-center">
                        <div className="logo-loading">
                            <img src={Logo} alt={'iTours logo'} title={'iTours logo'}/>
                        </div>
                        <div>
                            <strong>Loading data,please wait ...</strong>
                        </div>
                    </div>
                </div>
                }

                {/*{(MessageInformation.show) &&*/}
                {/*<Alert closable message='Message' description={MessageInformation.content}*/}
                {/*       type={MessageInformation.type} showIcon/>*/}
                {/*}*/}
                {

                    (!userID) ?

                        <div className={"loginScreen"}>
                            <Switch>
                                <Route path="/" exact component={Login}/>
                                <Route path="/resetpassword" component={ResetPassword}/>
                                <Route component={Login}/>
                            </Switch>
                        </div>
                        :
                        <Layout>
                            <Header/>
                            <Content>
                                <div>
                                    <Switch>
                                        <Route path="/" exact component={Home}/>
                                        <Route exact path="/user" component={AccountIndex}/>
                                        <Route path="/user/edit/:id" exact component={UserEdit}/>
                                        <Route path="/user/create" exact component={UserCreate}/>
                                        <Route path="/flight" exact component={FlightSearch}/>
                                        <Route path="/flight/result" exact component={FlightSearch}/>
                                        <Route path="/flight/getpassenger" component={FlightGetPassenger}/>
                                        <Route path="/flight/ticketing" component={FlightTicketing}/>
                                        <Route path="/hotel" exact component={HotelSearch}/>
                                        <Route path="/hotel/result" component={HotelSearch}/>
                                        <Route path="/hotel/details" component={HotelDetails}/>
                                        <Route path="/hotel/passenger" component={HotelSetPassenger}/>
                                        <Route path="/commission" exact component={CommissionIndex}/>
                                        <Route path="/commission/create" exact component={CommissionCreate}/>
                                        <Route path="/commission/edit/:id" exact component={CommissionEdit}/>
                                        <Route path="/payment/choice" component={ChoicePayment}/>
                                        <Route path="/payment/deposit" component={AddDeposit}/>
                                        <Route path="/tracking/reserves" exact={true} component={Reserves}/>
                                        <Route path="/tracking/reserves/hotel" component={HOTEL}/>
                                        <Route path="/tracking/reserves/flight" component={FLITGH}/>
                                        <Route path="/Profile" component={Profile}/>
                                        <Route path="/company/" component={CompanyIndex}/>
                                        <Route component={NotFound}/>
                                    </Switch>
                                </div>
                            </Content>
                            <Footer className="footer">
                                <div className="container">
                                    Copyright © 2020 Itours. All rights reserved.
                                </div>
                            </Footer>
                        </Layout>
                }
                <BackTop/>
            </ErrorBoundary>
        )
    }
}

//Mapping functions
const WrrapedRouter = withRouter(Stage);
const mapStateToProps = (state) => ({
    showAllPage: state.Notification.loading.showAllPage,
    userID: state.User.id,
    MessageInformation: state.Notification.notificationMessage
});
const mapDispatchToProps = (dispatch) => ({
    authentication: (token) => dispatch(authentication(token)),
    setLoading: (param) => dispatch(setLoading(param)),
    setNotification: (param) => dispatch(setNotification(param)),
    getDeposits: () => dispatch(getDeposits()),
    getIpLocation: () => dispatch(getIpLocation())
});
export default connect(mapStateToProps, mapDispatchToProps)(WrrapedRouter)
