//import libraries
import React from 'react';
import {Layout, Card, Spin, Alert, Button, Icon, Row, Col, Empty} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'querystring';
import Gallery from '../../../commond/Gallery';
import Rate from "../../../commond/Rate";
import FormSearch from "./HotelFormSearch"
//import Component
import Price from '../../../commond/Price';
//import actions
import {searchRoom, setRooms, setRoomsKey} from '../../../../Actions/HotelAction';
//import assets
import HotelSingleRoom from "./RoomLayout/HotelSingleRoom";
import HotelMultipleRoom from "./RoomLayout/HotelMultipleRoom";

class HotelDetails extends React.Component {
    state = {
        photos: [],
        Facilities: [],
        RoomsFacilities: [],
        RoomsInfo: [],
        RoomShow: [],
        roomSelect: [],
        isSelectAllRooms: false,
        doneSelectRoom: false,
        roomsAvailable: [],
        roomsSelected: [],
        roomsSelectedIndex: 0
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hotelDetails !== this.props.hotelDetails) {
            let facilities = null;
            let roomFacilities = null;
            let roomSelectShow = [];
            if (this.props.hotelDetails !== null) {
                //fill roomsAvailable and roomsSelected
                let roomsSelectedCash = [];
                this.props.hotelDetails.availabilities && this.props.hotelDetails.availabilities.forEach((roomItem, roomIndex) => {
                    roomsSelectedCash.push({index: roomIndex, selected: false, bookingToken: null, visibility: (!roomIndex), name: null, price: null})
                });
                this.setState({
                    roomsAvailable: this.props.hotelDetails.availabilities,
                    roomsSelected: roomsSelectedCash
                });
                //
                //get Hotel facilities
                facilities = this.props.hotelDetails.features.filter((item) => {
                    return item.keyword === "facilities"
                });
                if (facilities !== null) {
                    if (facilities.length > 0) {
                        facilities = facilities[0].items;
                    }
                }
                //get room facilities
                roomFacilities = this.props.hotelDetails.features.filter((item) => {
                    return item.keyword === "room_facilities"
                });
                if ((roomFacilities !== null) && (roomFacilities.length > 0)) {

                    roomFacilities = roomFacilities[0].items;
                }
                //create array for show room select
                if (this.state.RoomShow.length === 0) {
                    for (let cnt = 0; cnt < this.props.hotelDetails.availabilities.length; cnt++) {
                        if (cnt === 0) {
                            roomSelectShow.push(true);

                        } else {
                            roomSelectShow.push(false);

                        }
                    }
                } else {
                    roomSelectShow = this.state.RoomShow;
                }

                //call setRooms action
                let roomSelect = [];
                for (let cnt = 0; cnt < this.props.hotelDetails.availabilities.length; cnt++) {
                    roomSelect.push({name: '', price: '', bookingToken: 0});
                }
                //set gallery
                let imagesArray = this.props.hotelDetails.images;
                let imageGallery = imagesArray.map((item) => {
                    let imageAddress = item.sizes[item.sizes.length - 1].path;
                    let smallImageAddress = item.sizes[0].path;
                    return {src: imageAddress, width: 1, height: 1, smallSrc: smallImageAddress}
                });
                this.setState({
                    photos: imageGallery,
                    roomSelect: roomSelect,
                    Facilities: facilities,
                    RoomsFacilities: roomFacilities,
                    RoomsInfo: this.props.hotelDetails.availabilities,
                    RoomShow: roomSelectShow
                });

            }
        }
        if ((prevProps.reserveId !== this.props.reserveId) && (this.props.reserveId !== null)) {
            this.props.history.push(`/hotel/passenger?reserveId=${this.props.reserveId}`);
        }
    }

    componentDidMount() {
        this.props.setRoomsKey(null);
        this.getRooms();
    }

    handleSelectRoom = async (bookingToken) => {
        //create Parameters'
        this.setState({
            doneSelectRoom: true
        });
        let parameters = queryString.parse(this.props.location.search);
        let params = {};
        let adult = [];
        parameters.Adults.split(",").forEach((item) => adult.push(parseInt(item)));
        params["adults"] = adult;
        let children = [];
        parameters.Children.split(",").forEach((item) => children.push(parseInt(item)));
        params["children"] = children;
        if (parameters.Ages.length !== 0) {
            let ages = [];
            parameters.Ages.split(",").forEach((item) => ages.push(parseInt(item)));
            params["ages"] = ages;
        }
        params["checkin"] = parameters.Checkin;
        params["checkout"] = parameters.Checkout;
        params["bookingToken"] = bookingToken;

        this.props.setRooms(params);
    };

    getRooms = () => {
        //first we take URL and convert it to URLSearchParam
        let parameters = queryString.parse(this.props.location.search);
        let params = new URLSearchParams();
        parameters.Adults.split(",").forEach((item) => params.append("Adults", item));
        parameters.Children.split(",").forEach((item) => params.append("Children", item));
        if (parameters.Ages.length !== 0) {
            parameters.Ages.split(",").forEach((item) => params.append("Ages", item));
        }
        params.append("Id", parameters["?Id"]);
        params.append("Checkin", parameters.Checkin);
        params.append("Checkout", parameters.Checkout);
        //pass URLParam to Actions for Search
        this.props.searchRoom(`?${params.toString()}`);
    };

    render() {
        let roomLayout;
        const {hotelDetails} = this.props;
        if (hotelDetails && hotelDetails.availabilities && hotelDetails.availabilities.length > 0) {

            if (hotelDetails.availabilities[0].rooms.length === 1) {
                roomLayout = hotelDetails.availabilities.map(m => {
                    return <HotelSingleRoom key={hotelDetails.availabilities.indexOf(m)} hotelFacilities={this.state.Facilities} roomFacilities={this.state.RoomsFacilities} room={m.rooms[0]}
                                            rates={m.rates} date={hotelDetails.date}
                                            currency={this.props.currency} onSelectRoom={this.handleSelectRoom}/>;
                });
            } else {
                roomLayout = hotelDetails.availabilities.map(m => {
                    return <HotelMultipleRoom key={hotelDetails.availabilities.indexOf(m)} rooms={m.rooms} rates={m.rates} date={hotelDetails.date} currency={this.props.currency}
                                              onSelectRoom={this.handleSelectRoom}/>;
                });
            }


        } else {
            roomLayout = <div>
                <Card>
                    <div className="max-width-700">
                        <Empty
                            description={
                                <b className="font-18 red">
                                    Sorry, it appears that there are no rooms available for these dates, try an alternative room or changing your dates
                                </b>
                            }
                        >
                            <a href="#search_form_holder" className="button blue-btn">Start new search </a>
                        </Empty>
                    </div>
                </Card>
            </div>;
        }

        return (
            <>
                <div id="search_form_holder">
                    <FormSearch/>
                </div>
                <div className="container vr-padding">
                    {
                        (hotelDetails) ?
                            <Layout>
                                <div className="hotel-head-info">
                                    <div className="full-width">
                                        <h3 className="hotel-title en-font">{hotelDetails.name}</h3>
                                        <div className="hotel-address en-font">
                                            <Icon type="environment" className="margin-right-light"/>
                                            {hotelDetails.address}
                                        </div>
                                        <div className="hotelRange">
                                            <Rate number={hotelDetails.rating}/>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div>
                                            <b dir="ltr">
                                                <Price currency={this.props.currency}>{(hotelDetails.availabilities[0]) ? hotelDetails.availabilities[0].rates[0].salePrice : 0}</Price>
                                            </b>
                                            <div className="font-14 margin-top-light margin-bottom-light">starting price</div>

                                        </div>
                                        <Button href="#roomSelect" type="primary" htmlType="button">Select room</Button>
                                    </div>
                                </div>
                                <div className="margin-bottom">

                                    {(this.state.photos.length >= 1) ?
                                        // <Carousel autoplay>
                                        //     {this.state.photos.map(photo => {
                                        //         return (<img src={photo.src} alt="hotel"/>)
                                        //     })}
                                        // </Carousel>
                                        <Gallery images={this.state.photos}/>
                                        :
                                        <Alert
                                            message="Images are not supplied from the hotel"
                                            description="You can book this hotel, but unfortunately, we can not show you the images. Please use Google Images or the hotel's website to see images instead."
                                            type="warning"
                                            showIcon
                                        />
                                    }
                                </div>
                                <div className="margin-bottom">
                                    {hotelDetails.features && hotelDetails.features.length > 0 ?
                                        (<Card title="Hotel contact information" className="margin-bottom large-lable">
                                            {
                                                hotelDetails.features && hotelDetails.features.map((item, index) => {
                                                    return (
                                                        <div key={index} className="facilitie-item">
                                                            {item.name === "Object Information" &&
                                                            <div className="margin-bottom">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Hotel contact information</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6}
                                                                                                                            key={detailsIndex}><span>{detailsItem.name}: </span>{detailsItem.value}
                                                                        </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                            {item.name === "Building Information" &&
                                                            <div className="margin-bottom">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Specifications for hotel buildings</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6}
                                                                                                                            key={detailsIndex}><span>{detailsItem.name}: </span>{detailsItem.value}
                                                                        </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                            {(item.name === "Payment") &&
                                                            <div className="margin-bottom">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Valid financial credits for hotel use</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6} key={detailsIndex}>{detailsItem.name} </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                            {(item.name === "Facilities") &&
                                                            <div className="margin-bottom" id="hotel_facilities">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Hotel facilities</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6} key={detailsIndex}>{detailsItem.name} </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                            {(item.name === "Room Facilities") &&
                                                            <div className="margin-bottom">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Room facilities</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6} key={detailsIndex}>{detailsItem.name} </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                            {item.name === "Distances" &&
                                                            <div className="margin-bottom">
                                                                <h5 className="normal-title"><Icon type="right-square"/> Distance from hotel to places</h5>
                                                                <div className="padding-left-20">
                                                                    <Row gutter={15}>
                                                                        {item.items.map((detailsItem, detailsIndex) => <Col xs={24} md={6}
                                                                                                                            key={detailsIndex}>{detailsItem.name}:{detailsItem.value} m </Col>)}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Card>) : null}
                                </div>
                                {
                                    hotelDetails.paragraphs && (hotelDetails.paragraphs.length > 0) &&
                                    <div className="margin-bottom">
                                        <Card title="Hotel Details" className="hotel-details-box large-lable">
                                            {hotelDetails.paragraphs.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <h5 className="normal-title"><Icon type="right-square"/> {item.title}</h5>
                                                        <div className="padding-left-20">
                                                            <p>{item.para}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Card>
                                    </div>
                                    // hotel paragraph
                                }

                                <div id={'roomSelect'} className={"select-room-wrapper"}>
                                    {roomLayout}
                                </div>
                            </Layout>
                            :
                            <div className="loading-section text-center">
                                <div className="margin-bottom">
                                    <Spin/>
                                </div>
                                <div>
                                    <strong>Loading data ...</strong>
                                </div>
                            </div>
                    }

                </div>
            </>

        )
    }
}

const MapStateToProp = (state) => ({
    hotelDetails: state.Hotel.selectedHotel,
    reserveId: state.Hotel.reserveId,
    currency: state.User.currency
});

const MapDispatchToProp = (dispatch) => ({
    searchRoom: (param) => {
        dispatch(searchRoom(param))
    },
    setRooms: (param) => {
        dispatch(setRooms(param))
    },
    setRoomsKey: (param) => {
        dispatch(setRoomsKey(param))
    }
});
const WrappedSearchRooms = withRouter(HotelDetails);
export default connect(MapStateToProp, MapDispatchToProp)(WrappedSearchRooms)
