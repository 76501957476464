//import library
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col, Input, Spin, Result, Card, Statistic, Icon} from "antd";
//import model and action
import {Currency} from "../../../../Enums/Types";
import {getDepositsGateways, sendDepositMethod, getDeposits} from "../../../../Actions/PaymentAction";
import PaymentList from "./PaymentList";
import queryString from "query-string";
import {Urls} from '../../../../Enums/APIAddress';

class AddDeposit extends React.Component {
    state = {
        selectCurrency: this.props.currency,
        amount: 0,
        sendData: false,
        statusPayment: null
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currency !== prevProps.currency) {
            if(this.props.currency){
                let param = new URLSearchParams();
                param.append("Currency", this.props.currency);
                this.props.getDepositsGateways(param);
            }

        }
    }
    changeCurrency = (currency) => {
        this.setState({
            selectCurrency: currency
        }, () => {
            let param = new URLSearchParams();
            param.append("Currency", this.state.selectCurrency);
            this.props.getDepositsGateways(param);
        });
    };
    componentDidMount() {
        //show deposit
        this.props.getDeposits();
        //load payment list
        if(this.props.currency){
            let param = new URLSearchParams();
            param.append("Currency", this.props.currency);
            this.props.getDepositsGateways(param);
        }
        // check payment status
        let parameters = queryString.parse(this.props.location.search);
        if (parameters.status) {
            if (parseInt(parameters.status)) {
                this.setState({
                    statusPayment: "paid"
                })
            } else {
                this.setState({
                    statusPayment: "unpaid"
                })
            }

        }
    }
    bankSelect = (bankID, param) => {

        this.setState({
            sendData: true
        }, () => {
            if (this.state.amount) {
                let params = {
                    userId: this.props.userID,
                    amount: parseInt(this.state.amount),
                    gatewayId: bankID,
                    callbackUrl: `${Urls.Type}${Urls.Local.Url}/payment/deposit`,
                    ipAddress: Urls.Local.Url,
                    formParams: {...param},
                    currencyId: this.state.selectCurrency
                };
                this.props.sendDepositMethod(params);
            }
        })


    };

    render() {
        return (
            <div className="container vr-padding">

                <Row>
                    {(this.props.depositList) ?
                        <Card title="Your deposit" className="margin-bottom">

                            {this.props.depositList.map(item => {
                                return (
                                    <Statistic key={item.currencyId} title={item.currencyId} value={item.amount} prefix={<Icon type="wallet"/>}/>
                                )
                            })}
                        </Card> :
                        <div>
                            <Spin/>
                        </div>
                    }
                </Row>
                {this.state.statusPayment &&
                <Row>

                    <Result
                        status={(this.state.statusPayment === "paid") ? "success" : "error"}
                        title={(this.state.statusPayment === "paid") ? "Payment successful" : "Payment failed"}
                        subTitle={(this.state.statusPayment === "paid") ? "your deposit charged" : "deposit charging failed"}
                    />
                </Row>
                }

                <Row gutter={15} className="margin-bottom">

                    <Col xs={12}>
                        {
                            (this.props.currency &&
                                <>
                                    <label htmlFor={"selCurrency"}>Currency:</label>
                                    <select id={"selCurrency"} defaultValue={this.props.currency} onChange={(e) => this.changeCurrency(e.target.value)}>
                                        {Currency.map((item, index) => <option value={item.value} key={index}>{item.name}</option>)}
                                    </select>
                                </>
                            )
                        }
                    </Col>
                    <Col xs={12}>
                        <label htmlFor={"currency"}>Amount:</label>
                        <Input id={"currency"} onChange={e => this.setState({amount: e.target.value})}></Input>
                        {(this.state.amount === 0) && this.state.sendData && <div>You must enter amount</div>}
                    </Col>
                </Row>
                <div>
                    {(this.props.depositBanks) ?
                        <PaymentList banks={this.props.depositBanks} selectedBankthis={this.bankSelect}/>
                        :
                        <div className="loading-section text-center">
                            <div className="margin-bottom">
                                <Spin/>
                            </div>
                            <div>
                                <strong>Choice Payment Method is loading...</strong>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const stateMapProp = (state) => ({
    currency: state.User.currency,
    depositBanks: state.Payment.depositBanks,
    userID: state.User.id,
    depositList: state.Payment.depositList
});
const dispatchToProp = (dispatch) => ({
    getDepositsGateways: (param) => {
        dispatch(getDepositsGateways(param))
    },
    sendDepositMethod: (param) => {
        dispatch(sendDepositMethod(param))
    },
    getDeposits: () => dispatch(getDeposits())
})
export default connect(stateMapProp, dispatchToProp)(AddDeposit);
