import {default as AccountAPI} from "../APIs/Account";
//Server Address and Port
//local
// export const Urls = {
//     Type: 'http://',
//     User: '192.168.2.32:4005',
//     Hotel: 'hotel.imt.as',
//     HotelV2: 'localhost:21022',
//     Payment: '192.168.2.32:4004',
//     Flight: '192.168.2.32:5500',
//     Local: {Url: 'localhost:21022/', Port: '3000'},
//     FlightTicket: `http://192.168.2.32:5555/en/flight/ticket`,
//     HotelTicket: `http://192.168.2.32:5555/en/Hotel/Voucher`,
//     GraphQL: `localhost:21024/graphql`
// };

//Staging
//
// export const Urls = {
//     Type: 'https://',
//     User: 'auth.imt.as',
//     //User: '192.168.2.32:4005',
//     Hotel: 'hotel.imt.as',
//     HotelV2: 'hotel2.imt.as',
//     //HotelV2: 'localhost:21022',
//     Payment: 'payline.imt.as',
//     Flight: 'sorrow.imt.as',
//     Local: {Url: 'agent.imt.as', Port: '80'},
//     FlightTicket: `https://voucher.imt.as/en/flight/Ticket`,
//     HotelTicket: `https://voucher.imt.as/en/Hotel/Voucher`,
//     GraphQL: `https://graphql.imt.as/graphql`
// };

//global
//
export const Urls = {
    Type: 'https://',
    User: 'auth.imt.as',
    //User: '192.168.2.32:4005',
    Hotel: 'hotel.imt.as',
    HotelV2: 'hotel2.imt.as',
    //HotelV2: 'localhost:21022',
    Payment: 'payline.imt.as',
    Flight: 'toxicity.imt.as',
    Local: {Url: 'Bleach.imt.as', Port: '3000'},
    FlightTicket: `https://voucher.imt.as/en/flight/Ticket`,
    HotelTicket: `https://voucher.imt.as/en/Hotel/Voucher`,
    GraphQL: `https://graphql.imt.as/graphql`,
    IpLocation: 'iplocation.imt.as'
};
///username:peyman@itours.no
///password:1366
//Account API URL service
export const Account = {
    Login: '/api/TokenAuth/Login',//for login user
    GetUser: '/api/services/app/Account/GetUser',//for getting user information
    ForgotPassword: '/api/services/app/Account/ForgotPassword',//send recover email to API
    Confirm: '/api/services/app/Account/ConfirmEmail',//get varification code from API
    ResetPassword: '/api/services/app/Account/ResetPasswordByCode',//get code for reset password
    Update: '/api/services/app/Account/Update',//update user
    ChangePassword: '/api/services/app/Account/ChangePassword',//change password
    GetTenant: '/api/services/app/Tenant/GetByKeyword',//getTenant,
    GetAll: '/api/services/app/User/GetAll',
    ResetPasswordUser: '/api/services/app/User/ResetPassword',
    GetUsers: '/api/services/app/User/Get',
    UpdateUsers: '/api/services/app/User/Update',
    DeleteUsers: '/api/services/app/User/Delete',
    CreateUsers: '/api/services/app/User/Create',
    GetAffiliate: '/api/services/app/Terminal/GetAffiliate'
};
//Flight API URL Service
export const Flight = {
    GetLocation: '/api/services/app/BookingFlight/GetAirport',
    GetAirline: '/api/services/app/BookingFlight/GetAirline',
    GetFlights: '/api/services/app/BookingFlight/LowFareSearch',
    ValidateFlight: '/api/services/app/BookingFlight/Validate',
    GetPNR: '/api/services/app/BookingFlight/ReservePNR',
    GetRules: '/api/services/app/BookingFlight/GetFlightRules',
    GetReserveByID: '/api/services/app/BookingFlight/GetFlightReserveById',
    GetDirectTicketByID: '/api/services/app/BookingFlight/GetDirectTicketById',
    GetPNRDetails: '/api/services/app/BookingFlight/GetPNRDetails',
    PricePNR: '/api/services/app/BookingFlight/PricePNR',
    IssuePNR: '/api/services/app/BookingFlight/IssuePNR',
    VoidTicket: '/api/services/app/BookingFlight/VoidTicket',
    cancelPNR: '/api/services/app/BookingFlight/CancelPNR',
    GetFlightReserveByID: '/api/services/app/BookingFlight/GetFlightReserveById'
};
//payment URL service
export const Payment = {
    GetReserveInformation: '/api/services/app/Reserve/GetReserveById',
    GetBanksInformation: '/api/v1/Reserves/Gateway/GetGateways',
    MakeToken: '/api/v1/Reserves/Gateway/MakeToken',
    GatewayReport: '/api/v1/Payment/GatewayReport',
    GetDeposits: '/api/v1/Deposit/GetDeposits',
    GetDepositsGateway: '/api/v1/Deposit/Gateway/GetGateways',
    DepositMakeToken: '/api/v1/Deposit/Gateway/MakeToken',
    GetDepositBalance: '/api/v1/Reserves/Deposit/Balance',
    ConfirmDeposit: '/api/v1/Reserves/Deposit/ConfirmByDeposit',
    RefundDeposit: '/api/v1/Reserves/Deposit/DepositRefund',

};

export const HotelV2 = {
    GetLocation: '/api/services/app/BookingHotel/GetLocation',
    GetHotel: '/api/services/app/BookingHotel/GetSearch',
    GetHotelDetails: '/api/services/app/BookingHotel/GetHotelById',
    PreReserve: '/api/services/app/BookingHotel/PreReserve',
    GetPreReserveID: '/api/services/app/BookingHotel/GetPreReserveByKey',
    GetReserveByID: '/api/services/app/BookingHotel/GetReserveById',
    PostReserve: '/api/services/app/BookingHotel/Reserve',
    GetCancellationPolicy: '/api/services/app/BookingHotel/GetCancellationPolicy',
    PostBook: '/api/services/app/BookingHotel/Book',
    getPreCancel: '/api/services/app/BookingHotel/GetPreCancel',
    cancel: '/api/services/app/BookingHotel/Cancel',
};

export const IpLocationUrls = {
    GetIpLocation: '/api/services/app/LocationService/GetIpLocation',
    GetLocationByIp: 'api/services/app/LocationService/GetLocationByIP'
};

//Header Request for API
export var HeaderRequest = {
    "Content-Type": "application/json",
    //"Accept-Language":"nb-NO",
    "bs.currency": "USD",
    "bs.terminal": "93F344D3-2AAD-4C13-9A32-E8AF90AA0206",
    "Abp.TenantId": "7"
};
export const getHeaderRequest = (authMode) => {
    let tenant = AccountAPI.getTenant();
    let terminalID = AccountAPI.getTerminalIDCookie();
    if (authMode) {
        let token = AccountAPI.getToken();
        if (token) {
            return {
                "Content-Type": "application/json",
                //"Accept-Language":"nb-NO",
                "bs.currency": HeaderRequest["bs.currency"],
                "bs.terminal": terminalID,
                "Abp.TenantId": tenant,
                "Authorization": `Bearer ${token}`
            }
        } else {
            return {
                "Content-Type": "application/json",
                //"Accept-Language":"nb-NO",
                "bs.currency": HeaderRequest["bs.currency"],
                "bs.terminal": "93F344D3-2AAD-4C13-9A32-E8AF90AA0206",
                "Abp.TenantId": tenant
            }
        }

    } else {
        return {
            "Content-Type": "application/json",
            //"Accept-Language":"nb-NO",
            "bs.currency": HeaderRequest["bs.currency"],
            "bs.terminal": terminalID,
            "Abp.TenantId": tenant
        }
    }
}
