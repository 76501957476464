//create Library
import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import queryString  from 'query-string';
import {
    Form,
    Input,
    Button,
    Layout,
    Breadcrumb,
    Radio,
    Checkbox,
    DatePicker,
    Select,
    notification
} from 'antd';
import {Flight, getHeaderRequest,Urls} from "../../../../Enums/APIAddress";
import {CurrencyType,ReserveType} from "../../../../Enums/Types";
//import Transaction from "../../../commond/Transaction";
const {Content} = Layout;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';
class CommissionCreate extends React.Component {
    state = {
        mode:null,
        confirmDirty: false,
        autoCompleteResult: [],
        value: true,
        description: '',
        data: [],
        currencyType:Object.keys(CurrencyType),
        terminals:[],
        percentMode:false,
        status:"Ready",
        airlineCodes:[],
        originsCode:[],
        destinationCode:[],
        isDo:false


    };
    getInit=()=>{
        let param=queryString.parse(this.props.location.search);
        this.setState({
            mode:param.type
        })
    }
    getAirline=(e)=>{
            if (e.length >=2) {
                this.setState({
                    airlineCodes:[]
                },()=>{
                    axios({
                        method: 'get',
                        url: `${Urls.Type + Urls.Flight +Flight.GetAirline}?value=${e}`,
                        headers: getHeaderRequest(false)
                    }).then((responsive) => {
                        let result = responsive.data.result;
                        if (result.length !== 0) {
                            this.setState({
                                airlineCodes:result
                            })
                        }
                    }).catch((e) => {
                        console.log(e);
                    })
                })

            }
    }
    getLocation=(e,type)=>{
        if (e.length >=3) {
            this.setState({
                [type]:[]
            },()=>{
                axios({
                    method: 'get',
                    url: `${Urls.Type + Urls.Flight +Flight.GetLocation}?value=${e}`,
                    headers: getHeaderRequest(false)
                }).then((responsive) => {
                    let result = responsive.data.result;
                    if (result.length !== 0) {
                        this.setState({
                            [type]:result
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            })

        }
    }
    changeMode=(e)=>{
        this.props.history.push(`/commission/create?type=${e}`);
    }
    _getTerminals=()=>{
        let headers=getHeaderRequest(true)
        axios.post(Urls.GraphQL,
            {
                query:`
                    mutation{
                      terminals(filter:{}){
                        terminals{
                          name,
                          id
                        }
                      }
                    }            
                    `
            }, {headers: {...headers}})
            .then(data=>{
                this.setState({
                    terminals:data.data.data.terminals.terminals
                })
            })
    }
    _getData=async()=>{
        await this._getTerminals();
    }
    componentDidMount() {
        this.getInit();
        this._getData();
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
            this.getInit();
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            isDo:false
        },()=>{
            const btn = (
                <Button type="primary" size="small" onClick={()=>{notification.close('done');this.props.history.push(`/commission`)}}>
                    Ok
                </Button>
            );
            this.props.form.validateFieldsAndScroll((err, values) => {

                if (!err) {
                    this.setState({status:"Processing",isDo:true},()=>{
                        let param={
                            ...values,
                            "dateRange":(values.dateRange.length)&&`${moment(values.dateRange[0]).format("YYYY/MM/DD")}-${moment(values.dateRange[1]).format("YYYY/MM/DD")}`,
                        };
                        let header=getHeaderRequest(true);
                        axios.post(Urls.GraphQL,
                            {
                                query:`
                            mutation($commission:CommissionCreateViewModelInputType!){
                              commissionCreate(commission:$commission){
                                isSuccess
                              }
                            }
                         `,
                                variables: {
                                    "commission": {
                                        ...param
                                    }
                                }
                            }, {headers: {...header}}
                        ).then(data=>{
                            try{
                                this.setState({
                                    isDo:false
                                },()=>{
                                    if(data.data.data.commissionCreate.isSuccess){
                                        notification.open({
                                            type:'success',
                                            message: 'Commission created successfully',
                                            btn,
                                            key:'done',
                                            placement:'topRight'
                                        });
                                    }
                                })
                            }catch (e) {
                                notification.open({
                                    type:'error',
                                    message: 'Create commission failed',
                                    btn,
                                    key:'done',
                                    placement:'topRight'
                                });
                            }

                        })
                    })



                }
            });
        })

    };
    setPercentage=e=>{
        this.setState({
            data:{
                ...this.state.data,
                isPercentage:e.target.checked
            }
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (

                    <Content>
                        <div className="breadcrumpt-section">
                            <div className="container"> 
                                <Breadcrumb>
                                    <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to={'/commission'}>Commission</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item>Create {(this.state.mode===ReserveType.FLIGHT_GLOBAL)?"flight":"hotel"} commission</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>                        
                        <div className="container">
                            <div className={"user-form-box margin-top"}>
                                <Form  className="user-form" onSubmit={this.handleSubmit}>

                                    <Form.Item label="Name">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Name!',
                                                },
                                            ],
                                        })(<Input/>)}
                                    </Form.Item>
                                    <Form.Item label="Type"  >
                                        {getFieldDecorator('typeId', {
                                            initialValue:this.state.mode
                                        })(<Select showSearch placeholder="Select a Type" onChange={this.changeMode} overlay>
                                                {Object.keys(ReserveType).map(item=><Select.Option key={item} value={item}>{item}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Is Enable?" >
                                        {getFieldDecorator('isEnabled', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input  is Enable!',
                                                },
                                            ],
                                        })(
                                            <Radio.Group onChange={this.onChange}>
                                                <Radio value={true}>
                                                    Enable
                                                </Radio>
                                                <Radio value={false}>
                                                    Disable
                                                </Radio>
                                            </Radio.Group>
                                        )}


                                    </Form.Item>
                                    <Form.Item label="Data Range">
                                        {getFieldDecorator('dateRange', {
                                            initialValue:[]
                                        })(
                                            <RangePicker
                                                format={dateFormat}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item  label={'Is Percentage?'}>
                                        {getFieldDecorator('isPercentage', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={()=>{this.setState(prevState=>({percentMode:!prevState.percentMode}))}}><span className={"hidden-xl hidden-lg hidden-md"}>Is Percentage</span></Checkbox>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={'Amount'} className={"commition-amount"}>
                                        {getFieldDecorator('amount', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input amount!'

                                                },
                                            ],
                                        })(<Input placeholder={"Please input amount"}/>)}
                                        {
                                            this.state.percentMode &&<span className="persent-sign">%</span>
                                        }
                                    </Form.Item>
                                    {
                                        !this.state.percentMode &&
                                        <Form.Item label="Currency" >
                                            {getFieldDecorator('currencyId', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select currency!',
                                                    },
                                                ],
                                            })(
                                                <Select  showSearch placeholder="Select a Currency" >
                                                    <Select.Option value={null}>-</Select.Option>
                                                    {this.state.currencyType.map(item=><Select.Option key={item} value={item}>{item}</Select.Option>)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    }
                                    <Form.Item label={"Terminals"}>
                                        {getFieldDecorator('terminalIds', {
                                            initialValue:[]
                                        })(
                                            <Select mode={"multiple"}  showSearch placeholder="Select a Terminals" >
                                                <Select.Option value={null}>All</Select.Option>
                                                {this.state.terminals.map(item=><Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={"Description"}>
                                        {getFieldDecorator('description', {
                                            initialValue:""
                                        })(
                                            <Input.TextArea rows={3}></Input.TextArea>
                                        )}
                                    </Form.Item>
                                    {this.state.mode===ReserveType.FLIGHT_GLOBAL &&
                                    <>
                                        <Form.Item label={"Fare Basis"}>
                                            {getFieldDecorator('fareBasis', {
                                                initialValue:""
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label={"Airline IDs"}>
                                            {getFieldDecorator('airlineIds', {
                                                initialValue:[]
                                            })(
                                                <Select filterOption={false} mode={"multiple"} onSearch={this.getAirline} onFocus={()=>{this.setState({airlineCodes:[]})}}>
                                                    {
                                                        this.state.airlineCodes && this.state.airlineCodes.map(item=>{
                                                            return(
                                                                <Select.Option value={item.Code}>{item.Name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={"Source IDs"}>
                                            {getFieldDecorator('sourceIds', {
                                                initialValue:[]
                                            })(
                                                <Select filterOption={false} mode={"multiple"} onSearch={(e)=>this.getLocation(e,'originsCode')} onFocus={()=>{this.setState({originsCode:[]})}}>
                                                    {
                                                        this.state.originsCode && this.state.originsCode.map(item=>{
                                                            return(
                                                                <Select.Option value={item.Code}>{item.Code}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={"Destination IDs"}>
                                            {getFieldDecorator('destinationIds', {
                                                initialValue:[]
                                            })(
                                                <Select  filterOption={false} mode={"multiple"} onSearch={(e)=>this.getLocation(e,'destinationCode')} onFocus={()=>{this.setState({destinationCode:[]})}}>
                                                    {
                                                        this.state.destinationCode && this.state.destinationCode.map(item=>{
                                                            return(
                                                                <Select.Option key={item.Code} value={item.Code}>{item.Code}</Select.Option>
                                                            )
                                                        })
                                                    }

                                                </Select>
                                            )}
                                        </Form.Item>
                                    </>
                                    }
                                    <div className={"form-submit-row"}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={this.state.isDo}>
                                                Create
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>

                        </div>
                    </Content>


        );
    }
}
const routering=withRouter(CommissionCreate)
export default Form.create({name: 'CommissionCreate'})(routering)
