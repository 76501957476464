import React from 'react';
import {Card, Col, Icon, Row} from "antd";
import defaultRoomImage from "../../../../../Images/design/hotelNoImage.svg";
import PropTypes from 'prop-types';
import _ from 'lodash';
import HotelBundlePrice from "../HotelBundlePrice";
import HotelBoardPolicy from "./HotelBoardPolicy";
import HotelBookButton from "./HotelBookButton";

HotelSingleRoom.propTypes = {
    room: PropTypes.object.isRequired,
    rates: PropTypes.array.isRequired,
    date: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    hotelFacilities: PropTypes.array.isRequired,
    roomFacilities: PropTypes.array.isRequired
};

function HotelSingleRoom(props) {
    const roomFacilities = [];
    props.room.facilities.map(facility => roomFacilities.push(facility.name));
    props.roomFacilities.map(facility => roomFacilities.push(facility.name));
    props.hotelFacilities.map(facility => roomFacilities.push(facility.name));

    return (
        <Card title={props.room.name} className={"margin-bottom"}>
            <Row gutter={[15, 15]}>
                <Col xs={24} md={6} xl={4}>
                    <div className={"room-image-holder"}>
                        <img src={defaultRoomImage} className={"room-image"} alt={"room"}/>
                    </div>
                    <ul className={"bullet-list"}>
                        {roomFacilities.slice(0, 5).map((facility, index) => {
                            return <li key={index}>{facility}</li>
                        })}
                    </ul>
                </Col>
                <Col xs={24} md={18} xl={20}>
                    <div className={"table-holder"}>
                        <table className={"hotel-room-table"}>
                            <thead>
                            <tr>
                                <th>What’s Included</th>
                                <th>Guests</th>
                                <th>Total for stay</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Cancellation element */}
                            {props.rates.map((rate, index) => {
                                return (<tr key={index}>
                                    <td>
                                        <div className="mobile-label">What’s Included:</div>
                                        <div>
                                            <HotelBoardPolicy rate={rate} date={props.date} rooms={[props.room]}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mobile-label">Guests:</div>
                                        <div>
                                            <Icon type="user"/> Fits {props.room.adults + props.room.children}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mobile-label">Total for stay:</div>
                                        <div>
                                            <HotelBundlePrice rate={rate} currency={props.currency} date={props.date}/>
                                        </div>
                                    </td>
                                    <td>
                                        <HotelBookButton className={`button white-btn full-width relative supplier-color _${_.padStart(rate.supplierType, 3, 0)}`} rate={rate}
                                                         onClick={props.onSelectRoom}>Book now</HotelBookButton>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default HotelSingleRoom;