import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Row,Col} from "antd";

class Index extends React.Component{
    render() {
        return(
            <div className="container">
                <div className="vr-padding">
                    <Row gutter={15}>
                        <Col xs={24} md={12}>
                            <Link type={"button"} to={"/profile"}>Agency Profile</Link>
                        </Col>
                        <Col xs={24} md={12}>
                            <Link disabled={true} type={"button"} to={"/"}>Company Management</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default withRouter(Index)