import React from "react";
import queryString from "query-string";
import {replaceAll} from './../../../../Command/StringOperation';
import {Form, Input, Button,Icon} from 'antd';
import {connect} from 'react-redux';
import {resetPassword} from "../../../../Actions/AccountAction";
import {setNotification} from './../../../../Actions/NotificationAction';
import {MessageType} from "../../../../Enums/Types";
import {Link} from 'react-router-dom';

class ResetPassword extends React.Component{
    state={
        password:null,
        confirmPassword:null,
        code:null,
        userId:null
    };
    componentDidMount() {
        let parameters=queryString.parse(this.props.location.search);
        if(parameters.code!==undefined && parameters.userId!==undefined){
            this.setState({
                code:replaceAll(parameters.code,' ','+') ,
                userId:parameters.userId
            });
        }else{
            this.props.setNotification({show:true,content:"you haven't permission to change password",type:MessageType.Error});
        }
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };
    handleSubmit=e=>{
        e.preventDefault();
        this.props.form.validateFields((err,value)=>{
            if(!err){
                this.props.resetPassword({userID:this.state.userId,password:value.newPassword,Code:this.state.code});
            }
        })
    };
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
                    <Form onSubmit={this.handleSubmit}>
                        <div>Change Password</div>
                        <Form.Item>
                            {getFieldDecorator('newPassword', {
                                rules: [{ required: true, message: 'Please input your new password!' }]
                            })(<Input.Password prefix={<Icon type="user" className={'safety'} />} placeholder="new password" />,)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('confirmPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(<Input.Password prefix={<Icon type="user" className={'safety'} />} placeholder="confirm password" />,)}
                        </Form.Item>
                        <Button loading={this.props.showInlineLoad} type="primary"  htmlType={'submit'}>Save Change</Button>
                        <Link to={"/"}>Back to Login</Link>
                    </Form>
        )
    }
}
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(ResetPassword);
//map
const MapStateToProp=(state)=>({
    showInlineLoad:state.Notification.loading.showInline
});
const MapDispatchToProp=(dispatch)=>({
    resetPassword:(input)=>{dispatch(resetPassword(input))},
    setNotification:(input)=>{dispatch(setNotification(input))}

});
export default connect(MapStateToProp,MapDispatchToProp)(WrappedNormalLoginForm)