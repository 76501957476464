import queryString from 'query-string'

export default class objectConverter {
    static jsonDeserialize = (input) => {
        const obj = JSON.parse(input);
        return obj;
    };

    static jsonSerialize = (input) => {
        const json = JSON.stringify(input);
        return json;
    };

    static toQueryString = (input) => {
        // console.log(input);
        // const urlSearchParams = new URLSearchParams();
        // for (const key in input) {
        //     if (input.hasOwnProperty(key)) {
        //         urlSearchParams.append(key.toString(), input[key].toString());
        //     }
        // }
        //
        // return urlSearchParams.toString();
        const data = queryString.stringify(input);
        return data;
    };
}