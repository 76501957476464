//import library
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Empty } from 'antd';
//import model and type
import {Travel} from '../../Enums/Types';
import HotelItem from './HotelItem';
// import FlightItem from './FlightItem';
import Itinerary from "./Itinerary";
class ListItem extends React.Component{
    static propTypes={
        data:PropTypes.any.isRequired,
        last:PropTypes.number.isRequired,
        start:PropTypes.number.isRequired,
        type:PropTypes.string.isRequired
    };
    render() {
        let resultData=this.props.data;
        return(
            <>
            {
                (resultData.length > 0) ?
                    resultData.map((item, index) => {
                        return (index >= this.props.start && index <= this.props.last) &&
                            <>
                                {(this.props.type===Travel.hotel)&&<HotelItem key={item.id} model={item} currency={this.props.currency} params={2} />}
                                {/*{(this.props.type===Travel.flight)&&<FlightItem key={index} model={item} currency={this.props.currency}/>}*/}
                                {(this.props.type===Travel.flight)&&<Itinerary key={item.key} model={item} currency={this.props.currency}/>}
                            </>
                    })
                    :
                    <Empty description="We cant found any item, please try again"/>
            }
            </>

        )
    }

}
//maping
const mapStateToProp=(state)=>({
    currency:state.User.currency
});
export default connect(mapStateToProp)(ListItem)