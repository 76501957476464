import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {editUser, changeCurrency, setTenant, authentication as AuthAction} from './../Actions/AccountAction';
import {UserActions} from './../Enums/ActionTypes';
import Account from './../APIs/Account';
import {setNotification, setLoading} from './../Actions/NotificationAction';
import {MessageType, Currency} from "../Enums/Types";
import {push} from 'connected-react-router';
import {getStorage, removeStorage, setStorage} from "../utils/localstorage";

//fetch general function
function* loginUserFn(action) {
    yield put(setLoading({showAllPage: false, showInline: true}));
    //check tenant
    const responseTenant = yield call(Account.requestTenant, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (responseTenant === null) {
        yield put(setNotification({show: true, content: 'Tenant not found', type: MessageType.Error}));
        yield put(setLoading({showAllPage: false, showInline: false}));
    } else {
        if (responseTenant.isAxiosError === undefined) {
            yield put(setTenant(responseTenant.id));
            yield call(Account.setTenant, responseTenant.id);
            //login the user
            const response = yield call(Account.login, {...action.input});
            yield put(setNotification({show: false, content: '', type: MessageType.Error}));
            yield put(setLoading({showAllPage: false, showInline: false}));
            // //find exception
            if (response.isAxiosError === undefined) {
                //after get token we should get terminal ID
                const terminalID = yield call(Account.getTerminalID, response.accessToken);

                yield call(Account.setTerminal, terminalID.id);
                yield call(Account.setCompanyName, terminalID.company.name);
                //set cookie
                if (action.input.remember) {
                    yield setStorage('token', response.accessToken, 86400 * 30);
                    //yield call(CookieManage.setCookie, "token", response.accessToken, 30 * 24);
                } else {
                    yield setStorage('token', response.accessToken, 86400);
                    //yield call(CookieManage.setCookie, "token", response.accessToken, 24);
                }
                //yield call(CookieManage.setCookie, "currency", Currency[0].value, 30 * 24);
                yield setStorage('currency', Currency[0].value, 86400);
                yield put(editUser(response.user));
                yield put(changeCurrency(Currency[0].value));
                yield put(push('/'));
            } else {
                let message = null;
                (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
                yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            }
            //
        } else {
            let message = null;
            (responseTenant.response) ? message = responseTenant.response.data.error.message : message = "Unknown Error,Please check your internet connection";
            yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            yield put(setLoading({showAllPage: false, showInline: false}));
        }
    }


}

function* authUserFN(action) {

    yield put(setLoading({showAllPage: true, showInline: false}));
    const haveTenant = yield call(Account.checkTenant);
    if (haveTenant) {
        yield  put(setTenant(haveTenant));
        const response = yield call(Account.authentication, action.input.officeID);
        // //find exception
        if (response.isAxiosError === undefined) {
            let currency = yield getStorage('currency'); // yield call(CookieManage.getCookie, "currency");
            yield put(editUser(response));

            yield put(changeCurrency(currency));
            yield put(setLoading({showAllPage: false, showInline: false}));

        } else {
            // let message = null;
            // (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
            //yield put(setNotification({show: true, content: message}));
            yield put(setLoading({showAllPage: false, showInline: false}));

        }

    }

}

function* sendRecoverEmailFN(action) {
    yield put(setLoading({showAllPage: false, showInline: true}));
    const response = yield call(Account.sendRecoverEmail, {...action.input});
    // //find exception
    yield put(setLoading({showAllPage: false, showInline: false}));
    if (response.isAxiosError === undefined) {
        if (response) {
            yield put(setNotification({show: true, content: "Recovery email send", type: MessageType.Success}));
        } else {
            yield put(setNotification({show: true, content: "Recovery email doesnt send", type: MessageType.Error}))

        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
}

function* changePasswordFN(action) {
    yield put(setLoading({showAllPage: false, showInline: true}));
    const response = yield call(Account.changePassword, {...action.input});
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    yield put(setLoading({showAllPage: false, showInline: false}));
    if (response.isAxiosError === undefined) {
        if (response) {
            yield put(setNotification({show: true, content: "Password changed", type: MessageType.Success}));
        } else {
            yield put(setNotification({show: true, content: "Password can't change. Please try again.", type: MessageType.Error}))

        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
}

function* logoutUserFn() {
    yield removeStorage('token');
    yield removeStorage('currency');
    yield removeStorage('ag_it_tenant');
    yield removeStorage('ag_it_terminal');

    // yield call(CookieManage.deleteCookie, 'token');
    // yield call(CookieManage.deleteCookie, 'currency');
    // yield call(CookieManage.deleteCookie, 'ag_it_tenant');
    // yield call(CookieManage.deleteCookie, 'ag_it_terminal');
}

function* checkCurrencyFN(action) {
    yield setStorage('currency', action.input, 86400 * 30);
    //yield call(CookieManage.setCookie, 'currency', action.input, 30 * 24);

}

function* resetPasswordFN(action) {

    yield put(setLoading({showAllPage: false, showInline: true}));
    const response = yield call(Account.resetPassword, {...action.input});
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    yield put(setLoading({showAllPage: false, showInline: false}));
    if (response.isAxiosError === undefined) {
        if (response) {
            yield put(setNotification({show: true, content: "Password changed", type: MessageType.Success}));
        } else {
            yield put(setNotification({show: true, content: "Password can't change. Please try again", type: MessageType.Error}))

        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
}

function* updateUserFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    const response = yield call(Account.editUser, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    yield put(setLoading({showAllPage: false, showInline: false}));
    if (response.isAxiosError === undefined) {

        let officeID = getStorage('token');// CookieManage.getCookie("token");
        let token = getStorage('ag_it_tenant');// CookieManage.getCookie("ag_it_tenant");
        yield put(AuthAction({token, officeID}));
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
}

function* getTenantFN(action) {
    yield put(setLoading({showAllPage: false, showInline: true}));
    const response = yield call(Account.requestTenant, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response === null) {
        yield put(setNotification({show: true, content: 'Tenant not found', type: MessageType.Error}));
        yield put(setLoading({showAllPage: false, showInline: false}));
    } else {
        if (response.isAxiosError === undefined) {
            yield  put(setTenant(response.id));
            yield call(Account.setTenant, response.id)
            yield put(setLoading({showAllPage: false, showInline: false}));

        } else {
            let message = null;
            (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
            yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            yield put(setLoading({showAllPage: false, showInline: false}));
        }
    }

}

function* getIpLocation() {
    const response = yield call(Account.getIpLocation);
    console.log(response);
}

//flow function
export function* loginUser() {
    yield takeEvery(UserActions.Login, loginUserFn);
}

export function* authentication() {
    yield takeEvery(UserActions.Authentication, authUserFN);
}

export function* sendRecoverEmail() {
    yield takeEvery(UserActions.SendRecoverEmail, sendRecoverEmailFN)
}

export function* changePassword() {
    yield takeEvery(UserActions.ChangePassword, changePasswordFN)
}

export function* logoutUser() {
    yield takeEvery(UserActions.Logout, logoutUserFn)
}

export function* checkCurrency() {
    yield takeEvery(UserActions.ChangeCurrency, checkCurrencyFN)

}

export function* resetPassword() {
    yield takeEvery(UserActions.ResetPassword, resetPasswordFN)
}

export function* updateUser() {
    yield  takeEvery(UserActions.UpdateUser, updateUserFN)
}

export function* getTenant() {
    yield takeEvery(UserActions.GetTenant, getTenantFN)
}

export function* watchIpLocation() {
    yield takeLatest(UserActions.GetIpLocation, getIpLocation);
}
