//import library
import {takeEvery,put,call,select} from 'redux-saga/effects';
//import actionType and action
import {PaymentActions} from '../Enums/ActionTypes';
import {bookStatus} from '../Enums/Types';
import Payment from '../APIs/Payment';
import Hotel from '../APIs/Hotel';
import {
    setReserveInformation,
    setPaymentList,
    setDeposits,
    setDepositsGateways,
    setDepositBalance,
    setBookStatus,

} from "../Actions/PaymentAction";
import {getHotelSelected,book} from '../Actions/HotelAction';
import {setSelectedFlight} from '../Actions/FlightAction'
import {IssuePNR} from "../Actions/FlightAction";
import {setLoading, setNotification} from "../Actions/NotificationAction";
import {MessageType,bookStatus as BookStatus} from "../Enums/Types";
import {Urls} from "../Enums/APIAddress";
import Flight from "../APIs/Flight";
//fetch general function
function* getReserveByIDFN(action){

    yield put(setLoading({showAllPage:true,showInline:false}));
    const response=yield call(Payment.getReserveID,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setReserveInformation(response));
        if(response.type==="HotelGlobal"){
            yield put(getHotelSelected(action.input));
        }
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    yield put(setLoading({showAllPage:false,showInline:false}));
}
function* getPaymentListFN(action){

    //clear model
    yield put(setPaymentList(null));
    const response=yield call(Payment.getPaymentList,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setPaymentList(response));
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
}
function* setPaymentInformationFN(action){
    yield put(setLoading({showAllPage:true,showInline:false}));
    const response=yield call(Payment.sendPaymentMethod,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        window.location.href=`${Urls.Type}${Urls.Payment}/en/Reserves/Payment/PaymentRequest?tokenId=${response.tokenId}`
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    yield put(setLoading({showAllPage:false,showInline:false}));

}
function* getDepositsFN() {
    const response = yield call(Payment.getDeposits);
    //clear model
    yield put(setDeposits(null))
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setDeposits(response))
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
}
function* getDepositsGatewaysFN(action){
    yield put(setLoading({showAllPage:true,showInline:false}));
    //clean model before get
    yield put(setDepositsGateways(null));
    const response=yield call(Payment.getDepositsGateway,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setDepositsGateways(response));
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    yield put(setLoading({showAllPage:false,showInline:false}));
}
function* setDepositInformationFN(action){
    debugger;
    yield put(setLoading({showAllPage:true,showInline:false}));
    const response=yield call(Payment.sendDepositMethod,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        window.location.href=`${Urls.Type}${Urls.Payment}/en/Deposit/Payment/PaymentRequest?tokenId=${response.tokenId}`
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    yield put(setLoading({showAllPage:false,showInline:false}));
}
function* getDepositBalanceFN(action){

    yield put(setDepositBalance(null));
    const response=yield call(Payment.getDepositsBalance,action.input);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setDepositBalance(response));
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
}
function* confirmDepositFN(action) {

    yield put(setBookStatus(BookStatus.process));
    const response=yield call(Payment.confirmDeposit,action.input);
    if (response.status===200) {
        //first we detect Flight or Hotel
        let getParam=new URLSearchParams();
        getParam.append("reserveId",action.input.reserveId);
        getParam.append("username",action.input.username);
        const type=yield call(Payment.getReserveID,getParam);
        if(type.type==="HotelGlobal"){
            //book hotel;
            const bookStatus=yield call(Hotel.book,action.input);
            if (bookStatus.isAxiosError === undefined) {
                yield put(setBookStatus(BookStatus.done))
            }else{
                yield call(Payment.refundDeposit,action.input);
                yield put(setBookStatus(BookStatus.error));
            }
        }
        else if(type.type==="FlightGlobal"){
            //issue Flight
            const flightPNRStatus=yield call(Flight.getReserveID,getParam);
            if(flightPNRStatus.isAxiosError===undefined){
                if(flightPNRStatus.paymentBeforePNR)
                {
                    //reserve hasnt PNR and It must be Booked
                    const response=yield call(Flight.GetDirectTicket,getParam);
                    if (response.isAxiosError === undefined) {
                        //window.location.href=`${Urls.Type}${Urls.Local.Url}/flight/ticketing?pnrCode=${response.pnrCode}&supplierId=${response.supplierId}`
                        //goto ReserveMenu
                        //if PNR not made result is > 0
                        if(response){
                            yield put(setBookStatus(BookStatus.error));
                            yield call(Payment.refundDeposit,action.input);
                        }else{
                            //if PNR made result is 0
                            yield put(setBookStatus(BookStatus.done));

                        }
                    }
                    else {
                        yield call(Payment.refundDeposit,action.input);
                        yield put(setBookStatus(BookStatus.error));
                    }
                }
                else{

                    if(flightPNRStatus.isBookNowRequested){
                        yield put(setBookStatus(BookStatus.now))
                    }else{
                        //reserve has PNR and it must be issued
                        const response=yield call(Flight.IssuePNR,`reserveId=${action.input.reserveId}&username=${action.input.username}`);
                        if (response.isAxiosError === undefined) {
                            yield put(setBookStatus(BookStatus.done));
                            window.location.href=`${Urls.Type}${Urls.Local.Url}/flight/ticketing?pnrCode=${response.pnrCode}&supplierId=${response.supplierId}`
                        }else {
                            yield call(Payment.refundDeposit,action.input);
                            yield put(setBookStatus(BookStatus.error));
                        }
                    }

                }
            }
            else{
                yield call(Payment.refundDeposit,action.input);
                yield put(setBookStatus(bookStatus.error));
            }

        }

    }
    else {
        yield put(setBookStatus(BookStatus.error));
        yield call(Payment.refundDeposit,action.input);
    }
}
function* confirmPaymentFN(action){
    //create param for get request
    let param=new URLSearchParams();
    param.set("reserveId",action.input.reserveId);
    param.set("username",action.input.username);
    //set book status to idle
    yield put(setBookStatus(bookStatus.idle));
    //a-inside function
    yield put(setLoading({showAllPage:true,showInline:false}));
    const response=yield call(Payment.getReserveID,param);

    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setReserveInformation(response));
        if(response.type==="HotelGlobal"){
            yield put(getHotelSelected(param));
        }else if(response.type==="FlightGlobal"){
            const flightResponse=yield call(Flight.GetFlightByReserveID,param);
            yield put(setSelectedFlight(flightResponse));
        }
    }else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    yield put(setLoading({showAllPage:false,showInline:false}));
    //a-inside function
    //get deposit information
    //b-inside function
    yield put(setDepositBalance(null));
    const responseD=yield call(Payment.getDepositsBalance,param);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (responseD.isAxiosError === undefined) {
        yield put(setDepositBalance(responseD));
    }else {
        let message = null;
        (responseD.response) ? message = responseD.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    //b-inside function
    //c-inside function
    yield put(setPaymentList(null));
    const responseE=yield call(Payment.getPaymentList,param);
    yield put(setNotification({show:false,content:'',type:MessageType.Error}));
    if (responseE.isAxiosError === undefined) {
        yield put(setPaymentList(responseE));
    }else {
        let message = null;
        (responseE.response) ? message = responseE.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message,type:MessageType.Error}))
    }
    //c-inside function
    const reserverInformation=yield select(state => state.Payment.reserverInformation);
    let status=parseInt(action.input.status);
    if(status>0){
        yield put(setBookStatus(bookStatus.process));
        if(reserverInformation.type==="HotelGlobal"){
            yield put(book({reserveId:action.input.reserveId,username:action.input.username}))
        }else if(reserverInformation.type==="FlightGlobal"){
            //yield put(IssuePNR(`reserveId=${action.input.reserveId}`))
            yield put(IssuePNR(param))

        }
    }else if(status===0){
        yield put(setBookStatus(bookStatus.error));

    }else{
        yield put(setBookStatus(bookStatus.idle));

    }

}
//flow function
export function* getReserveByID(){
    yield takeEvery(PaymentActions.getReserveByID,getReserveByIDFN);
}
export function* getPaymentList(){
    yield takeEvery(PaymentActions.getPaymentInformation,getPaymentListFN);
}
export function* setPaymentInformation(){
    yield takeEvery(PaymentActions.setPaymentMethod,setPaymentInformationFN);
}
export function* getDeposits(){
    yield takeEvery(PaymentActions.getDeposits,getDepositsFN)
}
export function* getDepositsGateways(){
    yield takeEvery(PaymentActions.getDepositsGateways,getDepositsGatewaysFN)
}
export function* setDepositInformation(){
    yield takeEvery(PaymentActions.setDepositMethod,setDepositInformationFN)
}
export function* getDepositBalance(){
    yield takeEvery(PaymentActions.getDepositBalance,getDepositBalanceFN)
}
export function* confirmDeposit(){
    yield takeEvery(PaymentActions.confirmDeposit,confirmDepositFN)
}
export function* confirmPayment(){
    yield takeEvery(PaymentActions.confirmPayment,confirmPaymentFN)
}