import moment from "moment";
import {Link} from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import {
    Form,
    Input,
    Button,
    Layout,
    Breadcrumb,
    Radio,
    Spin,
    Icon, notification,
    DatePicker
} from 'antd';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {getHeaderRequest, Urls, Account as AccountURL} from "../../../../Enums/APIAddress";

const {Content} = Layout;

class UserCreate extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        value: true,
        description: '',
        data: [],
        currencyType: [],
        supplierType: [],
        spinner: false
    };

    getFullNumber = () => {
        let phoneNumber;
        const phoneNumberInput = this.phoneNumberElement.current.state.value;
        const dialCode = this.phoneNumberElement.current.state.dialCode;
        if (phoneNumberInput.startsWith(dialCode)) {
            phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
        } else {
            phoneNumber = phoneNumberInput;
        }
        phoneNumber = dialCode.concat(phoneNumber);
        phoneNumber = phoneNumber.replace(/\s/g, '');
        return phoneNumber;
    }

    phoneNumberElement = React.createRef();

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({spinner: true});

                let headers = getHeaderRequest(true);
                values.birthday = moment(values.birthday).format("YYYY-MM-DD");

                values.phoneNumber = this.getFullNumber();
                axios.post(`${Urls.Type}${Urls.User}${AccountURL.CreateUsers}`, values, {
                    headers: {
                        ...headers
                    },
                }).then(({data}) => {
                    if (data.error) {
                        notification.info({
                            message: "Error",
                            description: data.error.message,
                        });
                    } else {
                        notification.info({
                            message: "Success",
                            description: "User created successfully",
                        });
                        this.props.history.push(`/user`);
                    }
                    this.setState({spinner: false})
                }).catch((data) => {
                    this.setState({spinner: false})
                });
            } else {
                notification.info({
                    message: `Warning`,
                    description:
                        'Please complete the form',
                });
            }
        });

    };

    render() {
        const {getFieldDecorator} = this.props.form;
        let {spinner} = this.state;
        return (
            <Content>
                <div className="breadcrumpt-section">
                    <div className="container">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/user'}>User</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Create</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="container">
                    <div className={"user-form-box margin-top"}>
                        <Form className="user-form" onSubmit={this.onSubmit}>
                            <Form.Item label="First name">
                                {getFieldDecorator('firstname', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter first name',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>
                            <Form.Item label="Last name">
                                {getFieldDecorator('lastname', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter last name',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>
                            <Form.Item label="Birthday">
                                {getFieldDecorator('birthday', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter birthday',
                                        },
                                    ],
                                })(<DatePicker/>)}
                            </Form.Item>
                            <Form.Item label="Email address">
                                {getFieldDecorator('emailAddress', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter email address',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>
                            <Form.Item label="Phone number">
                                    <IntlTelInput
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control"
                                        fieldName={"phoneNumber"}
                                        placeholder={"Phone number"}
                                        useMobileFullscreenDropdown={true}
                                        ref={this.phoneNumberElement}
                                        separateDialCode={true}
                                    />
                            </Form.Item>

                            <Form.Item label="Password">
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter password',
                                        },
                                    ],
                                })(<Input type={"password"}/>)}
                            </Form.Item>
                            <Form.Item hasFeedback label="Status">
                                {getFieldDecorator('isActive', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select activation status',
                                        },
                                    ],
                                })(
                                    <Radio.Group>
                                        <Radio value={true}>
                                            Active
                                        </Radio>
                                        <Radio value={false}>
                                            Deactive
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item hasFeedback label="Gender">
                                {getFieldDecorator('gender', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select gender',
                                        },
                                    ],
                                })(
                                    <Radio.Group>
                                        <Radio value={true}>
                                            Male
                                        </Radio>
                                        <Radio value={false}>
                                            Female
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item hasFeedback label="Email status">
                                {getFieldDecorator('isEmailConfirmed', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select email status',
                                        },
                                    ],
                                })(
                                    <Radio.Group>
                                        <Radio value={true}>
                                            Enable
                                        </Radio>
                                        <Radio value={false}>
                                            Disable
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item hasFeedback label="Newsletter status">
                                {getFieldDecorator('isNewsLater', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select newsletter subscription status',
                                        },
                                    ],
                                })(
                                    <Radio.Group>
                                        <Radio value={true}>
                                            Enable
                                        </Radio>
                                        <Radio value={false}>
                                            Disable
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <div className={"form-submit-row"}>
                                <Form.Item>
                                    <Button disabled={spinner} type="primary" htmlType="submit">
                                        {
                                            spinner ?
                                                <>
                                                    <Spin indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}/>
                                                    Create
                                                </>
                                                :
                                                "Create"
                                        }

                                    </Button>
                                </Form.Item>
                            </div>

                        </Form>
                    </div>
                </div>
            </Content>

        );
    }
}

export default Form.create({name: 'UserCreate'})(UserCreate)
