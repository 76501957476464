import React from "react";
import moment from "moment";
import Price from "./Price";
import {Table} from "antd";
export default class Gateways extends React.Component{
    render() {
        const column=[
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Confirmed',
                dataIndex: 'isConfirmed',
                key: 'isConfirmed',
                render:text=>(text)?'Confirmed':'Not Confirmed'
            },
            {
                title: 'Bank',
                dataIndex: 'gateway',
                key: 'gateway',
                render:text=>text&&text.name
            },
            {
                title: 'Request Date',
                dataIndex: 'requestDateTime',
                key: 'RrequestDateTime',
                render:date=>date&&moment(date, "YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")
            },
            {
                title: 'Response Date',
                dataIndex: 'responseDateTime',
                key: 'responseDateTime',
                render:date=>date&&moment(date, "YYYY-MM-DDTHH:mm:SS").format("YYYY/MM/DD")
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render:text=><Price currency={null}> {text}</Price>
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'currency',
                render:currency=>currency.code
            },
        ];
        return(
            <div className={"Transaction"}>
                <div className={"title"}>Transaction List</div>
                <Table className="itours-table" dataSource={this.props.model} columns={column}/>
            </div>
        )
    }
}