//import library and model
import {push} from 'connected-react-router';
import queryString from "query-string";
import {call, put, takeEvery} from 'redux-saga/effects';
import {FlightActions} from '../Enums/ActionTypes'
import Flight from '../APIs/Flight';
//import actions
import {setFlightLoading, saveFlightResult, applyFlightFilter, setSelectedFlight, setPNRFlight, setPNRDetails} from '../Actions/FlightAction';
import {setBookStatus} from '../Actions/PaymentAction';
import {setNotification, setLoading} from "../Actions/NotificationAction";
import {MessageType, bookStatus, bookStatus as BookStatus, FlightAvailable} from "../Enums/Types";
import {Urls} from "../Enums/APIAddress";
import Payment from "../APIs/Payment";

//fetch function
export function* searchFlightFN(action) {
    //clear result with putting null model in Reducer
    yield put(saveFlightResult(new FlightAvailable()));
    yield put(setFlightLoading(true));
    const response = yield call(Flight.searchFlight, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if(!response.cancelled) {
        if (response.isAxiosError === undefined) {
            yield put(saveFlightResult(response))
        } else {
            let message = null;
            (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
            yield put(setNotification({show: true, content: message, type: MessageType.Error}))
        }
        yield put(setFlightLoading(false));
    }



}

export function* applyFlightFN(action) {
    yield put(applyFlightFilter())
}

export function* selectFlightFN(action) {
    yield put(setFlightLoading(true));
    //clear flightSelect failed in reducer before ajax req
    yield put(setSelectedFlight(null));
    const response = yield call(Flight.validateFlight, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setSelectedFlight(response))
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setFlightLoading(false));

}

export function* getFlightPNRFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    const response = yield call(Flight.getPNR, action.input.param);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        //we have check flight has PNR
        if (action.input.hasPayment) {
            //reserve has not PNR
            yield put(push(`/payment/choice?reserveId=${response}&username=${null}`))
        } else {
            //reserve has PNR
            //first clear PNR Information
            yield put(setPNRDetails(null));
            let paramGet = new URLSearchParams();
            paramGet.append("reserveId", response);
            const PNRInformation = yield call(Flight.getReserveID, paramGet);
            if (PNRInformation.isAxiosError === undefined) {
                yield put(setPNRFlight(PNRInformation))
            } else {
                let message = null;
                (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
                yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            }
        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setLoading({showAllPage: false, showInline: false}));
}

export function* getFlightPNRDetailsFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    //clear PNRDetails
    setPNRDetails(null);
    const response = yield call(Flight.getPNRDetails, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setPNRDetails(response));
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setLoading({showAllPage: false, showInline: false}));
}

export function* getPricePNRFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    //clear PNRDetails
    setPNRDetails(null);
    const response = yield call(Flight.getPNRPrice, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        yield put(setPNRDetails(response));
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setLoading({showAllPage: false, showInline: false}));
}

export function* issuePNRFN(action) {

    const flightPNRStatus = yield call(Flight.getReserveID, action.input);
    //let getParam = new URLSearchParams();
    //getParam.append("reserveId", action.input.reserveId);
    //getParam.append("username", action.input.username);
    let getParam=action.input;
    if (flightPNRStatus.isAxiosError === undefined) {
        if (flightPNRStatus.paymentBeforePNR) {
            //reserve hasnt PNR and It must be Booked
            const response = yield call(Flight.GetDirectTicket, getParam);////getDirectticketbyId
            if (response.isAxiosError === undefined) {
                if (response) {
                    yield put(setBookStatus(BookStatus.error));
                } else {
                    //if PNR made result is 0
                    yield put(setBookStatus(BookStatus.done));

                }
                //window.location.href=`${Urls.Type}${Urls.Local.Url}/flight/ticketing?pnrCode=${response.pnrCode}&supplierId=${response.supplierId}`
                //goto ReserveMenu
            } else {
                yield put(setBookStatus(bookStatus.error))
            }
        } else {
            if(flightPNRStatus.isBookNowRequested){
                yield put(setBookStatus(bookStatus.now))
            }else{
                //reserve has PNR and it must be issued
                let bullsheetParam=queryString.parse(getParam.toString());
                bullsheetParam=`reserveid=${bullsheetParam.reserveId}&username=${bullsheetParam.username}`;
                const response = yield call(Flight.IssuePNR, bullsheetParam);
                if (response.isAxiosError === undefined) {
                    yield put(setBookStatus(bookStatus.done))
                    window.location.href = `${Urls.Type}${Urls.Local.Url}/flight/ticketing?pnrCode=${response.pnrCode}&supplierId=${response.supplierId}`
                } else {
                    yield put(setBookStatus(bookStatus.error))
                }
            }



        }
    } else {
        yield put(setBookStatus(bookStatus.error))
    }

}

export function* cancelPNRFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    const response = yield call(Flight.cancelPNR, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        if (response.toUpperCase() === "OK") {

            //after cancel show PNR details
            //clear PNR details
            yield put(setPNRDetails(null));
            let getParam = new URLSearchParams();
            getParam.append("PNRCode", action.input.pnrCode);
            getParam.append("SupplierId", action.input.supplierId);
            const responsePNR = yield call(Flight.getPNRDetails, getParam);
            yield put(setNotification({show: false, content: '', type: MessageType.Error}));
            if (responsePNR.isAxiosError === undefined) {

                yield put(setPNRDetails(responsePNR));
                yield call(Payment.refundDeposit, {"reserveId": responsePNR.reserveId});
            } else {
                let message = null;
                (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
                yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            }
            ////
        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setLoading({showAllPage: false, showInline: false}));
}

export function* voidTicketFN(action) {
    yield put(setLoading({showAllPage: true, showInline: false}));
    const response = yield call(Flight.voidPNR, action.input);
    yield put(setNotification({show: false, content: '', type: MessageType.Error}));
    if (response.isAxiosError === undefined) {
        if (response) {
            //after cancel show PNR details
            //clear
            debugger;
            yield put(setPNRDetails(null));
            let getParam = new URLSearchParams();
            getParam.append("PNRCode", action.input.pnrCode);
            getParam.append("SupplierId", action.input.supplierId);
            const responsePNR = yield call(Flight.getPNRDetails, getParam);
            yield put(setNotification({show: false, content: '', type: MessageType.Error}));
            if (responsePNR.isAxiosError === undefined) {
                yield put(setPNRDetails(responsePNR));
            } else {
                let message = null;
                (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
                yield put(setNotification({show: true, content: message, type: MessageType.Error}))
            }
            ////
        }
    } else {
        let message = null;
        (response.response) ? message = response.response.data.error.message : message = "Unknow Error,Please check your internet connection";
        yield put(setNotification({show: true, content: message, type: MessageType.Error}))
    }
    yield put(setLoading({showAllPage: false, showInline: false}));
}

//flow function
export function* searchFlight() {
    yield takeEvery(FlightActions.SearchFlight, searchFlightFN);
}

export function* applyFlight() {
    yield takeEvery(FlightActions.SetFlightFilter, applyFlightFN);
}

export function* selectFlight() {
    yield takeEvery(FlightActions.ValidateFlight, selectFlightFN);
}

export function* getFlightPNR() {
    yield takeEvery(FlightActions.getFlightPNR, getFlightPNRFN)
}

export function* getFlightPNRDetails() {
    yield takeEvery(FlightActions.getPNRDetails, getFlightPNRDetailsFN)
}

export function* getPricePNR() {
    yield takeEvery(FlightActions.pricePNR, getPricePNRFN)
}

export function* issuePNR() {
    yield takeEvery(FlightActions.issuePNR, issuePNRFN)
}

export function* cancelPNR() {
    yield takeEvery(FlightActions.cancelPNR, cancelPNRFN)
}

export function* voidTicket() {
    yield takeEvery(FlightActions.voidTicket, voidTicketFN)
}
