import React from 'react';
import {connect} from 'react-redux';
import {Form, Input, Button, Icon, Col, Row, Select, Card, Radio, Checkbox} from 'antd';
import moment from "moment";
import {Country,CountryCode} from "./../../../../Enums/Types";
//import model and action

import {updateUser} from "../../../../Actions/AccountAction";
import IntlTelInput from "react-intl-tel-input";
class ChangeInformation extends React.Component{
    phoneNumberElement = React.createRef();
    handleSubmit=e=>{
        e.preventDefault();
        this.props.form.validateFields((err,value)=>{

            if(!err){
                value.phoneNumber = this.getFullNumber();
                this.props.updateUser({...value})
            }
        })
    };
    state={
        title:this.props.userInformation.gender,
        firstName:this.props.userInformation.firstName,
        lastName:this.props.userInformation.lastName,
        birthday:this.props.userInformation.birthDay && moment(this.props.userInformation.birthDay,"YYYY-MM-DDTHH:mm:SS"),
        nationalityId:this.props.userInformation.nationalityId,
        isNewsLater:this.props.userInformation.isNewsLater,
        editMode:false,
    };
    getFullNumber = () => {
        let phoneNumber;
        const phoneNumberInput = this.phoneNumberElement.current.state.value;
        const dialCode = this.phoneNumberElement.current.state.dialCode;
        if (phoneNumberInput.startsWith(dialCode)) {
            phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
        } else {
            phoneNumber = phoneNumberInput;
        }
        phoneNumber = dialCode.concat(phoneNumber);
        phoneNumber = phoneNumber.replace(/\s/g, '');
        return phoneNumber;
    }
    activeEdit=()=>{
        this.setState({
            editMode:true
        })
    }
    deactiveEdit=()=>{
        this.setState({
            editMode:false
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {userInformation}=this.props;
        return (
            <Form onSubmit={this.handleSubmit} className="editInformation-form">
                <Card className="min-height-400">
                    <Row gutter={15}>
                        <Col xs={24} lg={20} xl={18} >
                            <Form.Item>
                                {getFieldDecorator('active', {
                                    initialValue:true
                                })(
                                    <Radio.Group>
                                        <Radio  disabled={true}  value={false}>Disable</Radio>
                                        <Radio value={true}>Enable</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                            <Row gutter={15}>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Gender</label>
                                        <Form.Item>
                                            {getFieldDecorator('gender', {
                                                initialValue:this.state.title,
                                            })( <Select style={{ width: '100%' }} disabled={(this.state.editMode)?false:true } onChange={(value)=>{this.setState({title:value})}}>
                                                <Select.Option value={"true"}>Mr</Select.Option>
                                                <Select.Option value={"false"}>Mrs</Select.Option>
                                            </Select>)}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8}  className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">First name</label>
                                        <Form.Item>
                                            {getFieldDecorator('firstName', {
                                                initialValue:this.state.firstName,
                                                rules: [{ required: true, message: 'Please input your first name' }]
                                            })(<Input prefix={<Icon type="user" className={'user'} />}  disabled={(this.state.editMode)?false:true} onChange={(e)=>{this.setState({firstName:e.target.value})}}/>,)}
                                        </Form.Item>

                                    </div>
                                </Col>
                                <Col xs={24} sm={8}  className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Last name</label>
                                        <Form.Item>
                                            {getFieldDecorator('lastName', {
                                                initialValue:this.state.lastName,
                                                rules: [{ required: true, message: 'Please input your first name' }]
                                            })(<Input prefix={<Icon type="user" className={'user'} />} disabled={(this.state.editMode)?false:true} onChange={(e)=>{this.setState({lastName:e.target.value})}}/>,)}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Email - LoginName</label>
                                        <Form.Item>
                                            {getFieldDecorator('userName', {
                                                initialValue:userInformation.userName
                                            })(
                                                <Input disabled={true} readOnly={true} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">User Role</label>
                                        <Form.Item>
                                            {getFieldDecorator('userrole', {
                                                initialValue:userInformation.roleNames
                                            })(
                                                <Input disabled={true} readOnly={true}/>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Nationality</label>
                                        <Form.Item>
                                            {getFieldDecorator('nationalityId', {
                                                initialValue:this.state.nationalityId,
                                                rules: [{ required: true, message: 'Please input your nationality' }]
                                            })(
                                                <select className="full-width"  disabled={(this.state.editMode)?false:true} onChange={(value)=>{this.setState({nationalityId:value})}}>
                                                    {CountryCode.map((item,index)=><option key={index} value={item.value}>{item.code}</option>)}
                                                </select>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col xs={24} sm={8}>
                                    <div className="gutter-box">
                                        <label className="form-label">Country</label>
                                        <Form.Item>
                                            {getFieldDecorator('country', {
                                                initialValue:userInformation.nationalityId
                                            })(
                                                <select disabled={true}>
                                                    {Country.map((item,index)=><option  key={index} value={item.Code}>{item.Country}</option>)}
                                                </select>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <div className="gutter-box">
                                        <label className="form-label">City</label>
                                        <Form.Item>
                                            {getFieldDecorator('city', {
                                            })(
                                                <Input disabled={true} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <div className="gutter-box">
                                        <label className="form-label">Postal Code</label>
                                        <Form.Item>
                                            {getFieldDecorator('postalCode', {
                                            })(
                                                <Input disabled={true} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col xs={24} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Address</label>
                                        <Form.Item>
                                            {getFieldDecorator('address', {
                                                initialValue:null
                                            })(
                                                <Input.TextArea className="full-width"  disabled={true} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Telephone</label>
                                        <Form.Item>
                                            {getFieldDecorator('telephone', {
                                            })(
                                                <Input disabled={true} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Mobile Number</label>
                                        {/*<Form.Item>*/}
                                        {/*    {getFieldDecorator('phonenumber', {*/}
                                        {/*        initialValue:userInformation.phoneNumber*/}
                                        {/*    })(*/}
                                        {/*        <Input disabled={(this.state.editMode)?false:true}/>*/}
                                        {/*    )}*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item>
                                            <IntlTelInput
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control"
                                                fieldName={"phoneNumber"}
                                                placeholder={"Mobile number"}
                                                defaultValue={userInformation.phoneNumber === null ? '' : userInformation.phoneNumber}
                                                useMobileFullscreenDropdown={true}
                                                ref={this.phoneNumberElement}
                                                separateDialCode={true}
                                                disabled={true}
                                                allowDropdown={false}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} className="gutter-row">
                                    <div className="gutter-box">
                                        <label className="form-label">Language</label>
                                        <Form.Item>
                                            {getFieldDecorator('nationalityId', {
                                            })(
                                                <select className="full-width"  disabled={true}>
                                                    <option>English</option>
                                                </select>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Item>
                                {getFieldDecorator('isNewsLater', {
                                    initialChecked:this.state.isNewsLater,
                                })(
                                    <Checkbox disabled={(this.state.editMode)?false:true} >Subscribe new offers</Checkbox>
                                )}
                            </Form.Item>
                            <div>
                                <label className="form-label">Remark</label>
                                <Form.Item>
                                    {getFieldDecorator('remark', {
                                    })(
                                        <Input.TextArea className="full-width"  disabled={true}>
                                            <option>English</option>
                                        </Input.TextArea>
                                    )}
                                </Form.Item>
                                {
                                    (this.state.editMode)?
                                        <>
                                            <Button htmlType={"button"} className="margin-right" onClick={this.deactiveEdit}>Cancel</Button>
                                            <Button loading={this.props.showInlineLoad} type="primary" htmlType={'submit'} >Save changes</Button>
                                        </>
                                        :
                                        <Button type="primary" htmlType={"button"} onClick={this.activeEdit}>Edit informations</Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }
}
const mapStateToProps=(state)=>({
    userInformation:state.User
});
const mapDispatchToProps=(dispatch)=>({
    updateUser:(param)=>{dispatch(updateUser(param))}
})
const WrappedEditInformationForm = Form.create({ name: 'editInformation-form' })(ChangeInformation);
export default connect(mapStateToProps,mapDispatchToProps)(WrappedEditInformationForm)