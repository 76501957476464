import React from 'react';
import {Tabs} from 'antd';
import ChangePassword from "./changePassword";
import ChangeInformation from "./changeProfile";
export default class Index extends React.Component{
    render() {
        const { TabPane } = Tabs;
        return(
            <div className="container">
                <div className="vr-padding user-profile-page">
                    <Tabs type="card">
                        <TabPane tab={"User Details"} key="1">
                            <ChangeInformation />
                        </TabPane>
                        <TabPane tab={"Change Password"} key="2">
                            <ChangePassword />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )

    }
}