//type of message
export const MessageType={
    'Success':'success',
    'Error':'error',
    'Warning':'warning',
    'Info':'info'
};
Object.freeze(MessageType);
//type of currency
export const Currency=[
    {name:'US Dollar',value:'USD'},
    {name:'Euro',value:'EUR'},
    {name:'Swedish Krona',value:'SEK'},
    {name:'Norwegian Krone',value:'NOK'},
    {name:'Danish Krone',value:'DKK'},
    {name:'Iranian Rial',value:'IRR'},
    {name:'Emirati Dirham',value:'AED'}
]
//user information model
export class Account{
    token=null;
    username=null;
    firstName=null;
    lastName=null;
    emailAddress=null;
    isActive=false;
    displayName=null;
    gender=true;
    avator=null;
    birthday=null;
    nationalityId=null;
    isEmailConfrimed=false;
    phoneNumber=null;
    id=0;
    roleName=null;
    currency=null;
    tenant=null;
}
//Notification(visual element) information
export class Notification{
    notificationMessage={
        show:false,
        content:null,
        type:MessageType.Success
    };
    loading={
        showAllPage:false,
        showInline:false
    };
}
//nationality list
export const Country=[
    {
        "Code": "AD",
        "Country": "Andorra",
        "ISO": 53,
        "Nationality": "Andorian"
    },
    {
        "Code": "AE",
        "Country": "United Arab Emirates",
        "ISO": 126,
        "Nationality": "Emirian"
    },
    {
        "Code": "AF",
        "Country": "Afghanistan",
        "ISO": 133,
        "Nationality": "Afghani"
    },
    {
        "Code": "AI",
        "Country": "Anguilla",
        "ISO": 55,
        "Nationality": "Anguillan"
    },
    {
        "Code": "AM",
        "Country": "Armenia",
        "ISO": 58,
        "Nationality": "Armenian"
    },
    {
        "Code": "AO",
        "Country": "Angola",
        "ISO": 54,
        "Nationality": "Angolian"
    },
    {
        "Code": "AQ",
        "Country": "Antarctica",
        "ISO": 55,
        "Nationality": "Antarctic"
    },
    {
        "Code": "AR",
        "Country": "Argentina",
        "ISO": 57,
        "Nationality": "Argentine"
    },
    {
        "Code": "AS",
        "Country": "Austria",
        "ISO": 3,
        "Nationality": "Austrian"
    },
    {
        "Code": "AU",
        "Country": "Australia",
        "ISO": 2,
        "Nationality": "Australian"
    },
    {
        "Code": "AW",
        "Country": "Aruba",
        "ISO": 59,
        "Nationality": "Arubian"
    },
    {
        "Code": "BA",
        "Country": "Bangladesh",
        "ISO": 45,
        "Nationality": "Bangladeshi"
    },
    {
        "Code": "BB",
        "Country": "Barbados",
        "ISO": 63,
        "Nationality": "Barbadian"
    },
    {
        "Code": "BE",
        "Country": "Belgium",
        "ISO": 4,
        "Nationality": "Belgian"
    },
    {
        "Code": "BH",
        "Country": "Bahrain",
        "ISO": 62,
        "Nationality": "Bahrainian"
    },
    {
        "Code": "BM",
        "Country": "Bermuda",
        "ISO": 67,
        "Nationality": "Bermuda"
    },
    {
        "Code": "BO",
        "Country": "Bolivia",
        "ISO": 69,
        "Nationality": "Bolivian"
    },
    {
        "Code": "BR",
        "Country": "Brazil",
        "ISO": 43,
        "Nationality": "Brazilian"
    },
    {
        "Code": "BS",
        "Country": "Bahamas",
        "ISO": 61,
        "Nationality": "Bahameese"
    },
    {
        "Code": "BT",
        "Country": "Bhutan",
        "ISO": 68,
        "Nationality": "Bhutanese"
    },
    {
        "Code": "BU",
        "Country": "Bulgaria",
        "ISO": 44,
        "Nationality": "Bulgarian"
    },
    {
        "Code": "BY",
        "Country": "Belarus",
        "ISO": 64,
        "Nationality": "Belarusian"
    },
    {
        "Code": "BZ",
        "Country": "Belize",
        "ISO": 65,
        "Nationality": "Belizean"
    },
    {
        "Code": "CA",
        "Country": "Canada",
        "ISO": 6,
        "Nationality": "Canadian"
    },
    {
        "Code": "CG",
        "Country": "Congo",
        "ISO": 72,
        "Nationality": "Congolese"
    },
    {
        "Code": "CH",
        "Country": "China",
        "ISO": 51,
        "Nationality": "Chinese"
    },
    {
        "Code": "CH",
        "Country": "Switzerland",
        "ISO": 35,
        "Nationality": "Swiss"
    },
    {
        "Code": "CL",
        "Country": "Chile",
        "ISO": 71,
        "Nationality": "Chilean"
    },
    {
        "Code": "CM",
        "Country": "Cambodia",
        "ISO": 5,
        "Nationality": "Cambodian"
    },
    {
        "Code": "CM",
        "Country": "Cameroon",
        "ISO": 70,
        "Nationality": "Cameroonian"
    },
    {
        "Code": "CO",
        "Country": "Columbia",
        "ISO": 46,
        "Nationality": "Columbian"
    },
    {
        "Code": "CR",
        "Country": "Czech Republic",
        "ISO": 50,
        "Nationality": "Czech"
    },
    {
        "Code": "CR",
        "Country": "Costa Rica",
        "ISO": 73,
        "Nationality": "Costa Rican"
    },
    {
        "Code": "CU",
        "Country": "Cuba",
        "ISO": 75,
        "Nationality": "Cuban"
    },
    {
        "Code": "CY",
        "Country": "Cyprus",
        "ISO": 76,
        "Nationality": "Cypriot"
    },
    {
        "Code": "DE",
        "Country": "Germany",
        "ISO": 10,
        "Nationality": "German"
    },
    {
        "Code": "DK",
        "Country": "Denmark",
        "ISO": 7,
        "Nationality": "Danish"
    },
    {
        "Code": "DM",
        "Country": "Dominica",
        "ISO": 77,
        "Nationality": "Dominican"
    },
    {
        "Code": "EC",
        "Country": "Ecuador",
        "ISO": 78,
        "Nationality": "Ecuadorean"
    },
    {
        "Code": "EE",
        "Country": "Estonia",
        "ISO": 79,
        "Nationality": "Estonian"
    },
    {
        "Code": "EG",
        "Country": "Egypt",
        "ISO": 8,
        "Nationality": "Egyptian"
    },
    {
        "Code": "ET",
        "Country": "Ethiopia",
        "ISO": 80,
        "Nationality": "Ethiopian"
    },
    {
        "Code": "FI",
        "Country": "Finland",
        "ISO": 82,
        "Nationality": "Finnish"
    },
    {
        "Code": "FJ",
        "Country": "Fiji",
        "ISO": 81,
        "Nationality": "Fijian"
    },
    {
        "Code": "FR",
        "Country": "France",
        "ISO": 9,
        "Nationality": "French"
    },
    {
        "Code": "GB",
        "Country": "United Kingdom",
        "ISO": 39,
        "Nationality": "British"
    },
    {
        "Code": "GE",
        "Country": "Georgia",
        "ISO": 83,
        "Nationality": "Georgian"
    },
    {
        "Code": "GH",
        "Country": "Ghana",
        "ISO": 84,
        "Nationality": "Ghanaian"
    },
    {
        "Code": "GN",
        "Country": "Guinea",
        "ISO": 86,
        "Nationality": "Guinean"
    },
    {
        "Code": "GR",
        "Country": "Greece",
        "ISO": 11,
        "Nationality": "Greek"
    },
    {
        "Code": "GY",
        "Country": "Guyana",
        "ISO": 87,
        "Nationality": "Guyanese"
    },
    {
        "Code": "HK",
        "Country": "Hong Kong",
        "ISO": 13,
        "Nationality": "Chinese"
    },
    {
        "Code": "HR",
        "Country": "Croatia",
        "ISO": 74,
        "Nationality": "Croatian"
    },
    {
        "Code": "HU",
        "Country": "Hungary",
        "ISO": 14,
        "Nationality": "Hungarian"
    },
    {
        "Code": "ID",
        "Country": "Indonesia",
        "ISO": 16,
        "Nationality": "Indonesian"
    },
    {
        "Code": "IE",
        "Country": "Ireland",
        "ISO": 90,
        "Nationality": "Irish"
    },
    {
        "Code": "IN",
        "Country": "India",
        "ISO": 15,
        "Nationality": "Indian"
    },
    {
        "Code": "IQ",
        "Country": "Iraq",
        "ISO": 89,
        "Nationality": "Iraqi"
    },
    {
        "Code": "IR",
        "Country": "Iran",
        "ISO": 17,
        "Nationality": "Iranian"
    },
    {
        "Code": "IS",
        "Country": "Israel",
        "ISO": 18,
        "Nationality": "Israeli"
    },
    {
        "Code": "IS",
        "Country": "Iceland",
        "ISO": 88,
        "Nationality": "Icelander"
    },
    {
        "Code": "IT",
        "Country": "Italy",
        "ISO": 19,
        "Nationality": "Italian"
    },
    {
        "Code": "JM",
        "Country": "Jamaica",
        "ISO": 91,
        "Nationality": "Jamaican"
    },
    {
        "Code": "JO",
        "Country": "Jordan",
        "ISO": 92,
        "Nationality": "Jordanian"
    },
    {
        "Code": "JP",
        "Country": "Japan",
        "ISO": 20,
        "Nationality": "Japanese"
    },
    {
        "Code": "KE",
        "Country": "Kenya",
        "ISO": 94,
        "Nationality": "Kenyan"
    },
    {
        "Code": "KO",
        "Country": "Korea",
        "ISO": 21,
        "Nationality": "Korean"
    },
    {
        "Code": "KW",
        "Country": "Kuwait",
        "ISO": 95,
        "Nationality": "Kuwaiti"
    },
    {
        "Code": "KZ",
        "Country": "Kazakhstan",
        "ISO": 134,
        "Nationality": "Kazakhstani"
    },
    {
        "Code": "KZ",
        "Country": "Kazakhstan",
        "ISO": 93,
        "Nationality": "Kazakhstani"
    },
    {
        "Code": "LB",
        "Country": "Lebanon",
        "ISO": 96,
        "Nationality": "Lebanese"
    },
    {
        "Code": "LK",
        "Country": "Sri Lanka",
        "ISO": 33,
        "Nationality": "Sri Lankan"
    },
    {
        "Code": "LT",
        "Country": "Lithuania",
        "ISO": 97,
        "Nationality": "Lithuanian"
    },
    {
        "Code": "LU",
        "Country": "Luxembourg",
        "ISO": 98,
        "Nationality": "Luxembourger"
    },
    {
        "Code": "MA",
        "Country": "Morocco",
        "ISO": 104,
        "Nationality": "Moroccan"
    },
    {
        "Code": "MC",
        "Country": "Monaco",
        "ISO": 102,
        "Nationality": "Monacan"
    },
    {
        "Code": "ME",
        "Country": "Mexico",
        "ISO": 47,
        "Nationality": "Mexican"
    },
    {
        "Code": "MM",
        "Country": "Myanmar",
        "ISO": 105,
        "Nationality": "Mayanmarese"
    },
    {
        "Code": "MN",
        "Country": "Mongolia",
        "ISO": 103,
        "Nationality": "Mongolian"
    },
    {
        "Code": "MO",
        "Country": "Macau",
        "ISO": 42,
        "Nationality": "Macau"
    },
    {
        "Code": "MU",
        "Country": "Mauritius",
        "ISO": 100,
        "Nationality": "Mauritian"
    },
    {
        "Code": "MV",
        "Country": "Maldives",
        "ISO": 99,
        "Nationality": "Maldivan"
    },
    {
        "Code": "MY",
        "Country": "Malaysia",
        "ISO": 22,
        "Nationality": "Malaysian"
    },
    {
        "Code": "NA",
        "Country": "Namibia",
        "ISO": 106,
        "Nationality": "Namibian"
    },
    {
        "Code": "NG",
        "Country": "Nigeria",
        "ISO": 108,
        "Nationality": "Nigerian"
    },
    {
        "Code": "NL",
        "Country": "Netherland",
        "ISO": 12,
        "Nationality": "Dutch"
    },
    {
        "Code": "NO",
        "Country": "Norway",
        "ISO": 24,
        "Nationality": "Norwegian"
    },
    {
        "Code": "NP",
        "Country": "Nepal",
        "ISO": 107,
        "Nationality": "Nepalese"
    },
    {
        "Code": "NZ",
        "Country": "New Zealand",
        "ISO": 23,
        "Nationality": "New Zealander"
    },
    {
        "Code": "OM",
        "Country": "Oman",
        "ISO": 109,
        "Nationality": "Omani"
    },
    {
        "Code": "PA",
        "Country": "Panama",
        "ISO": 110,
        "Nationality": "Panamanian"
    },
    {
        "Code": "PE",
        "Country": "Peru",
        "ISO": 112,
        "Nationality": "Peruvian"
    },
    {
        "Code": "PH",
        "Country": "Philippines",
        "ISO": 27,
        "Nationality": "Filipino"
    },
    {
        "Code": "PK",
        "Country": "Pakistan",
        "ISO": 26,
        "Nationality": "Pakistani"
    },
    {
        "Code": "PO",
        "Country": "Poland",
        "ISO": 28,
        "Nationality": "Polish"
    },
    {
        "Code": "PT",
        "Country": "Portugal",
        "ISO": 113,
        "Nationality": "Portugees"
    },
    {
        "Code": "PY",
        "Country": "Paraguay",
        "ISO": 111,
        "Nationality": "Paraguayan"
    },
    {
        "Code": "QA",
        "Country": "Qatar",
        "ISO": 115,
        "Nationality": "Qatari"
    },
    {
        "Code": "RO",
        "Country": "Romania",
        "ISO": 48,
        "Nationality": "Romanian"
    },
    {
        "Code": "RU",
        "Country": "Russia",
        "ISO": 29,
        "Nationality": "Russian"
    },
    {
        "Code": "SA",
        "Country": "Saudi Arabia",
        "ISO": 116,
        "Nationality": "Saudi Arabian"
    },
    {
        "Code": "SC",
        "Country": "Seychelles",
        "ISO": 119,
        "Nationality": "Seychellois"
    },
    {
        "Code": "SE",
        "Country": "Sweden",
        "ISO": 34,
        "Nationality": "Swedish"
    },
    {
        "Code": "SG",
        "Country": "Singapore",
        "ISO": 30,
        "Nationality": "Singaporean"
    },
    {
        "Code": "SK",
        "Country": "Slovakia",
        "ISO": 120,
        "Nationality": "Slovakian"
    },
    {
        "Code": "SN",
        "Country": "Senegal",
        "ISO": 117,
        "Nationality": "Senegalese"
    },
    {
        "Code": "SO",
        "Country": "Somalia",
        "ISO": 121,
        "Nationality": "Somali"
    },
    {
        "Code": "SP",
        "Country": "Spain",
        "ISO": 32,
        "Nationality": "Spanish"
    },
    {
        "Code": "TH",
        "Country": "Thailand",
        "ISO": 37,
        "Nationality": "Thai"
    },
    {
        "Code": "TN",
        "Country": "Tunisia",
        "ISO": 123,
        "Nationality": "Tunisian"
    },
    {
        "Code": "TR",
        "Country": "Turkey",
        "ISO": 38,
        "Nationality": "Turkish"
    },
    {
        "Code": "TW",
        "Country": "Taiwan",
        "ISO": 36,
        "Nationality": "Taiwanese"
    },
    {
        "Code": "TZ",
        "Country": "Tanzania",
        "ISO": 122,
        "Nationality": "Tanzanian"
    },
    {
        "Code": "UA",
        "Country": "Ukraine",
        "ISO": 125,
        "Nationality": "Ukrainian"
    },
    {
        "Code": "UG",
        "Country": "Uganda",
        "ISO": 124,
        "Nationality": "Ugandan"
    },
    {
        "Code": "US",
        "Country": "United States of America",
        "ISO": 40,
        "Nationality": "American"
    },
    {
        "Code": "UY",
        "Country": "Uruguay",
        "ISO": 127,
        "Nationality": "Uruguayan"
    },
    {
        "Code": "UZ",
        "Country": "Uzbekistan",
        "ISO": 128,
        "Nationality": "Uzbekistani"
    },
    {
        "Code": "VE",
        "Country": "Venezuela",
        "ISO": 49,
        "Nationality": "Venezuelan"
    },
    {
        "Code": "VN",
        "Country": "Vietnam",
        "ISO": 1,
        "Nationality": "Vietnamese"
    },
    {
        "Code": "YE",
        "Country": "Yemen",
        "ISO": 130,
        "Nationality": "Yemeni"
    },
    {
        "Code": "ZA",
        "Country": "South Africa",
        "ISO": 31,
        "Nationality": "South African"
    },
    {
        "Code": "ZM",
        "Country": "Zambia",
        "ISO": 131,
        "Nationality": "Zambian"
    },
    {
        "Code": "ZW",
        "Country": "Zimbabwe",
        "ISO": 132,
        "Nationality": "Zimbabwean"
    }
];
//filter type
export const FilterType={
    Intersection:"Intersection",
    Union:"Union",
    Like:"Like"
};
export const FilterName={
    rating:"rating",
    feature:"features",
    boards:"boards",
    regions:"regions",
    name:"name",
    stop:"stop",
    class:"class",
    airline:"airline",
    departureDateTime: "departureDateTime",
    arrivalDateTime: "arrivalDateTime",
    airlinePrice:"airlinePrice"
};
//filter
export class Filter{
    rating={value:[],type:FilterType.Union};
    features={value:[],type:FilterType.Intersection};
    boards={value:[],type:FilterType.Union};
    regions={value:[],type:FilterType.Union};
    name={value:null,type:FilterType.Like};
    stop={value:[],type:FilterType.Union};
    class={value:[],type:FilterType.Union};
    airline={value:[],type:FilterType.Union};
    departureDateTime={value:[],type:FilterType.Union};
    arrivalDateTime={value:[],type:FilterType.Union}
    airlinePrice={value:[],type:FilterType.Union}

}
//hotel Model
export class Flight{
    loadingFlight=false;
    flights=[];
    flightBackup=[];
    filterShow=new Filter();
    filterSelect=new Filter();
    flightSelect=null;
    PNRInformation=null;
    PNRDetails=null;

}
export class Hotel{
    city={
        id:0,
        name:null,
        code:null
    };
    country={
        id:0,
        name:null,
        code:null
    };
    date={
        dateFrom:null,
        dateTo:null,
        duration:null
    };
    hotels=[];
    hotelsBackup=[];
    filterShow=new Filter();
    filterSelect=new Filter();
    searchParameters=[];
    selectedHotel=null;
    selectedRooms=null;
    reserveId=null;
    tokenId=null;
    reserveHotel=[];
    searchURL=null;
    loadingHotel=false;
    cancelInfo=null
}

//type of item
export const Travel={
    "hotel":"hotel",
    "flight":"flight",
    "car":"car",
    "train":"train"
}
//type sort
export const Sort={
    price:"salePrice",
    name:"name",
    rate:"rating",
    flightPrice:"totalPrice",
    flightTime:"totaltimeTravel"
};
//type Payment
export class Payment{
    reserveId=null;
    username=null;
    paymentType=null;
    paymentList=null;
    depositList=null;
    depositBanks=null;
    depositBalance=null;
    bookStatus=null;
};
//Payment status

//list of country
export const CountryPostalCode=[
{value:"0093",code:"Afghanistan (+93)"},
{value:"00355",code:"Albania (+355)"},
{value:"00213",code:"Algeria (+213)"},
{value:"001-684",code:"American Samoa (+1-684)"},
{value:"00376",code:"Andorra (+376)"},
{value:"00244",code:"Angola (+244)"},
{value:"001-264",code:"Anguilla (+1-264)"},
{value:"00672",code:"Antarctica (+672)"},
{value:"001-268",code:"Antigua and Barbuda (+1-268)"},
{value:"0054",code:"Argentina (+54)"},
{value:"00374",code:"Armenia (+374)"},
{value:"00297",code:"Aruba (+297)"},
{value:"0061",code:"Australia (+61)"},
{value:"0043",code:"Austria (+43)"},
{value:"00994",code:"Azerbaijan (+994)"},
{value:"001-242",code:"Bahamas (+1-242)"},
{value:"00973",code:"Bahrain (+973)"},
{value:"00880",code:"Bangladesh (+880)"},
{value:"001-246",code:"Barbados (+1-246)"},
{value:"00375",code:"Belarus (+375)"},
{value:"0032",code:"Belgium (+32)"},
{value:"00501",code:"Belize (+501)"},
{value:"00229",code:"Benin (+229)"},
{value:"001-441",code:"Bermuda (+1-441)"},
{value:"00975",code:"Bhutan (+975)"},
{value:"00591",code:"Bolivia (+591)"},
{value:"00387",code:"Bosnia and Herzegovina (+387)"},
{value:"00267",code:"Botswana (+267)"},
{value:"0055",code:"Brazil (+55)"},
{value:"00246",code:"British Indian Ocean Territory (+246)"},
{value:"001-284",code:"British Virgin Islands (+1-284)"},
{value:"00673",code:"Brunei (+673)"},
{value:"00359",code:"Bulgaria (+359)"},
{value:"00226",code:"Burkina Faso (+226)"},
{value:"00257",code:"Burundi (+257)"},
{value:"00855",code:"Cambodia (+855)"},
{value:"00237",code:"Cameroon (+237)"},
{value:"001",code:"Canada (+1)"},
{value:"00238",code:"Cape Verde (+238)"},
{value:"001-345",code:"Cayman Islands (+1-345)"},
{value:"00236",code:"Central African Republic (+236)"},
{value:"00235",code:"Chad (+235)"},
{value:"0056",code:"Chile (+56)"},
{value:"0086",code:"China (+86)"},
{value:"0061",code:"Christmas Island (+61)"},
{value:"0061",code:"Cocos Islands (+61)"},
{value:"0057",code:"Colombia (+57)"},
{value:"00269",code:"Comoros (+269)"},
{value:"00682",code:"Cook Islands (+682)"},
{value:"00506",code:"Costa Rica (+506)"},
{value:"00385",code:"Croatia (+385)"},
{value:"0053",code:"Cuba (+53)"},
{value:"00599",code:"Curacao (+599)"},
{value:"00357",code:"Cyprus (+357)"},
{value:"00420",code:"Czech Republic (+420)"},
{value:"00243",code:"Democratic Republic of the Congo (+243)"},
{value:"0045",code:"Denmark (+45)"},
{value:"00253",code:"Djibouti (+253)"},
{value:"001-767",code:"Dominica (+1-767)"},
{value:"001-809, 1-829, 1-849",code:"Dominican Republic (+1-809, 1-829, 1-849)"},
{value:"00670",code:"East Timor (+670)"},
{value:"00593",code:"Ecuador (+593)"},
{value:"0020",code:"Egypt (+20)"},
{value:"00503",code:"El Salvador (+503)"},
{value:"00240",code:"Equatorial Guinea (+240)"},
{value:"00291",code:"Eritrea (+291)"},
{value:"00372",code:"Estonia (+372)"},
{value:"00251",code:"Ethiopia (+251)"},
{value:"00500",code:"Falkland Islands (+500)"},
{value:"00298",code:"Faroe Islands (+298)"},
{value:"00679",code:"Fiji (+679)"},
{value:"00358",code:"Finland (+358)"},
{value:"0033",code:"France (+33)"},
{value:"00689",code:"French Polynesia (+689)"},
{value:"00241",code:"Gabon (+241)"},
{value:"00220",code:"Gambia (+220)"},
{value:"00995",code:"Georgia (+995)"},
{value:"0049",code:"Germany (+49)"},
{value:"00233",code:"Ghana (+233)"},
{value:"00350",code:"Gibraltar (+350)"},
{value:"0030",code:"Greece (+30)"},
{value:"00299",code:"Greenland (+299)"},
{value:"001-473",code:"Grenada (+1-473)"},
{value:"001-671",code:"Guam (+1-671)"},
{value:"00502",code:"Guatemala (+502)"},
{value:"0044-1481",code:"Guernsey (+44-1481)"},
{value:"00224",code:"Guinea (+224)"},
{value:"00245",code:"Guinea-Bissau (+245)"},
{value:"00592",code:"Guyana (+592)"},
{value:"00509",code:"Haiti (+509)"},
{value:"00504",code:"Honduras (+504)"},
{value:"00852",code:"Hong Kong (+852)"},
{value:"0036",code:"Hungary (+36)"},
{value:"00354",code:"Iceland (+354)"},
{value:"0091",code:"India (+91)"},
{value:"0062",code:"Indonesia (+62)"},
{value:"0098",code:"Iran (+98)"},
{value:"00964",code:"Iraq (+964)"},
{value:"00353",code:"Ireland (+353)"},
{value:"00972",code:"Israel (+972)"},
{value:"0039",code:"Italy (+39)"},
{value:"00225",code:"Ivory Coast (+225)"},
{value:"001-876",code:"Jamaica (+1-876)"},
{value:"0081",code:"Japan (+81)"},
{value:"0044-1534",code:"Jersey (+44-1534)"},
{value:"00962",code:"Jordan (+962)"},
{value:"007",code:"Kazakhstan (+7)"},
{value:"00254",code:"Kenya (+254)"},
{value:"00686",code:"Kiribati (+686)"},
{value:"00383",code:"Kosovo (+383)"},
{value:"00965",code:"Kuwait (+965)"},
{value:"00996",code:"Kyrgyzstan (+996)"},
{value:"00856",code:"Laos (+856)"},
{value:"00371",code:"Latvia (+371)"},
{value:"00961",code:"Lebanon (+961)"},
{value:"00266",code:"Lesotho (+266)"},
{value:"00231",code:"Liberia (+231)"},
{value:"00218",code:"Libya (+218)"},
{value:"00423",code:"Liechtenstein (+423)"},
{value:"00370",code:"Lithuania (+370)"},
{value:"00352",code:"Luxembourg (+352)"},
{value:"00853",code:"Macau (+853)"},
{value:"00389",code:"Macedonia (+389)"},
{value:"00261",code:"Madagascar (+261)"},
{value:"00265",code:"Malawi (+265)"},
{value:"0060",code:"Malaysia (+60)"},
{value:"00960",code:"Maldives (+960)"},
{value:"00223",code:"Mali (+223)"},
{value:"00356",code:"Malta (+356)"},
{value:"00692",code:"Marshall Islands (+692)"},
{value:"00222",code:"Mauritania (+222)"},
{value:"00230",code:"Mauritius (+230)"},
{value:"00262",code:"Mayotte (+262)"},
{value:"0052",code:"Mexico (+52)"},
{value:"00691",code:"Micronesia (+691)"},
{value:"00373",code:"Moldova (+373)"},
{value:"00377",code:"Monaco (+377)"},
{value:"00976",code:"Mongolia (+976)"},
{value:"00382",code:"Montenegro (+382)"},
{value:"001-664",code:"Montserrat (+1-664)"},
{value:"00212",code:"Morocco (+212)"},
{value:"00258",code:"Mozambique (+258)"},
{value:"0095",code:"Myanmar (+95)"},
{value:"00264",code:"Namibia (+264)"},
{value:"00674",code:"Nauru (+674)"},
{value:"00977",code:"Nepal (+977)"},
{value:"0031",code:"Netherlands (+31)"},
{value:"00599",code:"Netherlands Antilles (+599)"},
{value:"00687",code:"New Caledonia (+687)"},
{value:"0064",code:"New Zealand (+64)"},
{value:"00505",code:"Nicaragua (+505)"},
{value:"00227",code:"Niger (+227)"},
{value:"00234",code:"Nigeria (+234)"},
{value:"00683",code:"Niue (+683)"},
{value:"00850",code:"North Korea (+850)"},
{value:"001-670",code:"Northern Mariana Islands (+1-670)"},
{value:"0047",code:"Norway (+47)"},
{value:"00968",code:"Oman (+968)"},
{value:"0092",code:"Pakistan (+92)"},
{value:"00680",code:"Palau (+680)"},
{value:"00970",code:"Palestine (+970)"},
{value:"00507",code:"Panama (+507)"},
{value:"00675",code:"Papua New Guinea (+675)"},
{value:"00595",code:"Paraguay (+595)"},
{value:"0051",code:"Peru (+51)"},
{value:"0063",code:"Philippines (+63)"},
{value:"0064",code:"Pitcairn (+64)"},
{value:"0048",code:"Poland (+48)"},
{value:"00351",code:"Portugal (+351)"},
{value:"001-787,1-939",code:"Puerto Rico (+1-787,1-939)"},
{value:"00974",code:"Qatar (+974)"},
{value:"00242",code:"Republic of the Congo (+242)"},
{value:"00262",code:"Reunion (+262)"},
{value:"0040",code:"Romania (+40)"},
{value:"007",code:"Russia (+7)"},
{value:"00250",code:"Rwanda (+250)"},
{value:"00590",code:"Saint Barthelemy (+590)"},
{value:"00290",code:"Saint Helena (+290)"},
{value:"001-869",code:"Saint Kitts and Nevis (+1-869)"},
{value:"001-758",code:"Saint Lucia (+1-758)"},
{value:"00590",code:"Saint Martin (+590)"},
{value:"00508",code:"Saint Pierre and Miquelon (+508)"},
{value:"001-784",code:"Saint Vincent and the Grenadines (+1-784)"},
{value:"00685",code:"Samoa (+685)"},
{value:"00378",code:"San Marino (+378)"},
{value:"00966",code:"Saudi Arabia (+966)"},
{value:"00221",code:"Senegal (+221)"},
{value:"00381",code:"Serbia (+381)"},
{value:"00248",code:"Seychelles (+248)"},
{value:"00232",code:"Sierra Leone (+232)"},
{value:"0065",code:"Singapore (+65)"},
{value:"001-721",code:"Sint Maarten (+1-721)"},
{value:"00421",code:"Slovakia (+421)"},
{value:"00386",code:"Slovenia (+386)"},
{value:"00677",code:"Solomon Islands (+677)"},
{value:"00252",code:"Somalia (+252)"},
{value:"0027",code:"South Africa (+27)"},
{value:"0082",code:"South Korea (+82)"},
{value:"00211",code:"South Sudan (+211)"},
{value:"0034",code:"Spain (+34)"},
{value:"0094",code:"Sri Lanka (+94)"},
{value:"00249",code:"Sudan (+249)"},
{value:"00597",code:"Suriname (+597)"},
{value:"0047",code:"Svalbard and Jan Mayen (+47)"},
{value:"00268",code:"Swaziland (+268)"},
{value:"0046",code:"Sweden (+46)"},
{value:"0041",code:"Switzerland (+41)"},
{value:"00963",code:"Syria (+963)"},
{value:"00886",code:"Taiwan (+886)"},
{value:"00992",code:"Tajikistan (+992)"},
{value:"00255",code:"Tanzania (+255)"},
{value:"0066",code:"Thailand (+66)"},
{value:"00228",code:"Togo (+228)"},
{value:"00690",code:"Tokelau (+690)"},
{value:"00676",code:"Tonga (+676)"},
{value:"001-868",code:"Trinidad and Tobago (+1-868)"},
{value:"00216",code:"Tunisia (+216)"},
{value:"0090",code:"Turkey (+90)"},
{value:"00993",code:"Turkmenistan (+993)"},
{value:"001-649",code:"Turks and Caicos Islands (+1-649)"},
{value:"00688",code:"Tuvalu (+688)"},
{value:"001-340",code:"US Virgin Islands (+1-340)"},
{value:"00256",code:"Uganda (+256)"},
{value:"00380",code:"Ukraine (+380)"},
{value:"00971",code:"United Arab Emirates (+971)"},
{value:"0044",code:"United Kingdom (+44)"},
{value:"001",code:"United States (+1)"},
{value:"00598",code:"Uruguay (+598)"},
{value:"00998",code:"Uzbekistan (+998)"},
{value:"00678",code:"Vanuatu (+678)"},
{value:"00379",code:"Vatican (+379)"},
{value:"0058",code:"Venezuela (+58)"},
{value:"0084",code:"Vietnam (+84)"},
{value:"00681",code:"Wallis and Futuna (+681)"},
{value:"00212",code:"Western Sahara (+212)"},
{value:"00967",code:"Yemen (+967)"},
{value:"00260",code:"Zambia (+260)"},
{value:"00263",code:"Zimbabwe (+263)"},
{value:"0044-1624",code:"isle of man (+44-1624)"},
{value:"00239",code:"sao tome and principe (+239)"}
];
export const CountryCode=[
    {"value":"AF","code":"Afghanistan"},
    {"value":"AX","code":"land Islands"},
    {"value":"AL","code":"Albania"},
    {"value":"DZ","code":"Algeria"},
    {"value":"AS","code":"American Samoa"},
    {"value":"AD","code":"Andorra"},
    {"value":"AO","code":"Angola"},
    {"value":"AI","code":"Anguilla"},
    {"value":"AQ","code":"Antarctica"},
    {"value":"AG","code":"Antigua and Barbuda"},
    {"value":"AR","code":"Argentina"},
    {"value":"AM","code":"Armenia"},
    {"value":"AW","code":"Aruba"},
    {"value":"AU","code":"Australia"},
    {"value":"AT","code":"Austria"},
    {"value":"AZ","code":"Azerbaijan"},
    {"value":"BS","code":"Bahamas"},
    {"value":"BH","code":"Bahrain"},
    {"value":"BD","code":"Bangladesh"},
    {"value":"BB","code":"Barbados"},
    {"value":"BY","code":"Belarus"},
    {"value":"BE","code":"Belgium"},
    {"value":"BZ","code":"Belize"},
    {"value":"BJ","code":"Benin"},
    {"value":"BM","code":"Bermuda"},
    {"value":"BT","code":"Bhutan"},
    {"value":"BO","code":"Bolivia (Plurinational State of)"},
    {"value":"BQ","code":"Bonaire, Sint Eustatius and Saba"},
    {"value":"BA","code":"Bosnia and Herzegovina"},
    {"value":"BW","code":"Botswana"},
    {"value":"BV","code":"Bouvet Island"},
    {"value":"BR","code":"Brazil"},
    {"value":"IO","code":"British Indian Ocean Territory"},
    {"value":"BN","code":"Brunei Darussalam"},
    {"value":"BG","code":"Bulgaria"},
    {"value":"BF","code":"Burkina Faso"},
    {"value":"BI","code":"Burundi"},
    {"value":"CV","code":"Cabo Verde"},
    {"value":"KH","code":"Cambodia"},
    {"value":"CM","code":"Cameroon"},
    {"value":"CA","code":"Canada"},
    {"value":"KY","code":"Cayman Islands"},
    {"value":"CF","code":"Central African Republic"},
    {"value":"TD","code":"Chad"},
    {"value":"CL","code":"Chile"},
    {"value":"CN","code":"China"},
    {"value":"CX","code":"Christmas Island"},
    {"value":"CC","code":"Cocos (Keeling) Islands"},
    {"value":"CO","code":"Colombia"},
    {"value":"KM","code":"Comoros"},
    {"value":"CG","code":"Congo"},
    {"value":"CD","code":"Congo (Democratic Republic of the)"},
    {"value":"CK","code":"Cook Islands"},
    {"value":"CR","code":"Costa Rica"},
    {"value":"CI","code":"Cote d'Ivoire"},
    {"value":"HR","code":"Croatia"},
    {"value":"CU","code":"Cuba"},
    {"value":"CW","code":"Curacao"},
    {"value":"CY","code":"Cyprus"},
    {"value":"CZ","code":"Czech Republic"},
    {"value":"DK","code":"Denmark"},
    {"value":"DJ","code":"Djibouti"},
    {"value":"DM","code":"Dominica"},
    {"value":"DO","code":"Dominican Republic"},
    {"value":"EC","code":"Ecuador"},
    {"value":"EG","code":"Egypt"},
    {"value":"SV","code":"El Salvador"},
    {"value":"GQ","code":"Equatorial Guinea"},
    {"value":"ER","code":"Eritrea"},
    {"value":"EE","code":"Estonia"},
    {"value":"ET","code":"Ethiopia"},
    {"value":"FK","code":"Falkland Islands (Malvinas)"},
    {"value":"FO","code":"Faroe Islands"},
    {"value":"FJ","code":"Fiji"},
    {"value":"FI","code":"Finland"},
    {"value":"FR","code":"France"},
    {"value":"GF","code":"French Guiana"},
    {"value":"PF","code":"French Polynesia"},
    {"value":"TF","code":"French Southern Territories"},
    {"value":"GA","code":"Gabon"},
    {"value":"GM","code":"Gambia"},
    {"value":"GE","code":"Georgia"},
    {"value":"DE","code":"Germany"},
    {"value":"GH","code":"Ghana"},
    {"value":"GI","code":"Gibraltar"},
    {"value":"GR","code":"Greece"},
    {"value":"GL","code":"Greenland"},
    {"value":"GD","code":"Grenada"},
    {"value":"GP","code":"Guadeloupe"},
    {"value":"GU","code":"Guam"},
    {"value":"GT","code":"Guatemala"},
    {"value":"GG","code":"Guernsey"},
    {"value":"GN","code":"Guinea"},
    {"value":"GW","code":"Guinea-Bissau"},
    {"value":"GY","code":"Guyana"},
    {"value":"HT","code":"Haiti"},
    {"value":"HM","code":"Heard Island and McDonald Islands"},
    {"value":"VA","code":"Holy See"},
    {"value":"HN","code":"Honduras"},
    {"value":"HK","code":"Hong Kong"},
    {"value":"HU","code":"Hungary"},
    {"value":"IS","code":"Iceland"},
    {"value":"IN","code":"India"},
    {"value":"ID","code":"Indonesia"},
    {"value":"IR","code":"Iran"},
    {"value":"IQ","code":"Iraq"},
    {"value":"IE","code":"Ireland"},
    {"value":"IM","code":"Isle of Man"},
    {"value":"IL","code":"Israel"},
    {"value":"IT","code":"Italy"},
    {"value":"JM","code":"Jamaica"},
    {"value":"JP","code":"Japan"},
    {"value":"JE","code":"Jersey"},
    {"value":"JO","code":"Jordan"},
    {"value":"KZ","code":"Kazakhstan"},
    {"value":"KE","code":"Kenya"},
    {"value":"KI","code":"Kiribati"},
    {"value":"KP","code":"Korea (Democratic People's Republic of)"},
    {"value":"KR","code":"Korea (Republic of)"},
    {"value":"KW","code":"Kuwait"},
    {"value":"KG","code":"Kyrgyzstan"},
    {"value":"LA","code":"Lao People's Democratic Republic"},
    {"value":"LV","code":"Latvia"},
    {"value":"LB","code":"Lebanon"},
    {"value":"LS","code":"Lesotho"},
    {"value":"LR","code":"Liberia"},
    {"value":"LY","code":"Libya"},
    {"value":"LI","code":"Liechtenstein"},
    {"value":"LT","code":"Lithuania"},
    {"value":"LU","code":"Luxembourg"},
    {"value":"MO","code":"Macao"},
    {"value":"MK","code":"Macedonia (the former Yugoslav Republic of)"},
    {"value":"MG","code":"Madagascar"},
    {"value":"MW","code":"Malawi"},
    {"value":"MY","code":"Malaysia"},
    {"value":"MV","code":"Maldives"},
    {"value":"ML","code":"Mali"},
    {"value":"MT","code":"Malta"},
    {"value":"MH","code":"Marshall Islands"},
    {"value":"MQ","code":"Martinique"},
    {"value":"MR","code":"Mauritania"},
    {"value":"MU","code":"Mauritius"},
    {"value":"YT","code":"Mayotte"},
    {"value":"MX","code":"Mexico"},
    {"value":"FM","code":"Micronesia (Federated States of)"},
    {"value":"MD","code":"Moldova (Republic of)"},
    {"value":"MC","code":"Monaco"},
    {"value":"MN","code":"Mongolia"},
    {"value":"ME","code":"Montenegro"},
    {"value":"MS","code":"Montserrat"},
    {"value":"MA","code":"Morocco"},
    {"value":"MZ","code":"Mozambique"},
    {"value":"MM","code":"Myanmar"},
    {"value":"NA","code":"Namibia"},
    {"value":"NR","code":"Nauru"},
    {"value":"NP","code":"Nepal"},
    {"value":"NL","code":"Netherlands"},
    {"value":"NC","code":"New Caledonia"},
    {"value":"NZ","code":"New Zealand"},
    {"value":"NI","code":"Nicaragua"},
    {"value":"NE","code":"Niger"},
    {"value":"NG","code":"Nigeria"},
    {"value":"NU","code":"Niue"},
    {"value":"NF","code":"Norfolk Island"},
    {"value":"MP","code":"Northern Mariana Islands"},
    {"value":"NO","code":"Norway"},
    {"value":"OM","code":"Oman"},
    {"value":"PK","code":"Pakistan"},
    {"value":"PW","code":"Palau"},
    {"value":"PS","code":"Palestine, State of"},
    {"value":"PA","code":"Panama"},
    {"value":"PG","code":"Papua New Guinea"},
    {"value":"PY","code":"Paraguay"},
    {"value":"PE","code":"Peru"},
    {"value":"PH","code":"Philippines"},
    {"value":"PN","code":"Pitcairn"},
    {"value":"PL","code":"Poland"},
    {"value":"PT","code":"Portugal"},
    {"value":"PR","code":"Puerto Rico"},
    {"value":"QA","code":"Qatar"},
    {"value":"RE","code":"Réunion"},
    {"value":"RO","code":"Romania"},
    {"value":"RU","code":"Russian Federation"},
    {"value":"RW","code":"Rwanda"},
    {"value":"BL","code":"Saint Barthelemy"},
    {"value":"SH","code":"Saint Helena, Ascension and Tristan da Cunha"},
    {"value":"KN","code":"Saint Kitts and Nevis"},
    {"value":"LC","code":"Saint Lucia"},
    {"value":"MF","code":"Saint Martin (French part)"},
    {"value":"PM","code":"Saint Pierre and Miquelon"},
    {"value":"VC","code":"Saint Vincent and the Grenadines"},
    {"value":"WS","code":"Samoa"},
    {"value":"SM","code":"San Marino"},
    {"value":"ST","code":"Sao Tome and Principe"},
    {"value":"SA","code":"Saudi Arabia"},
    {"value":"SN","code":"Senegal"},
    {"value":"RS","code":"Serbia"},
    {"value":"SC","code":"Seychelles"},
    {"value":"SL","code":"Sierra Leone"},
    {"value":"SG","code":"Singapore"},
    {"value":"SX","code":"Sint Maarten (Dutch part)"},
    {"value":"SK","code":"Slovakia"},
    {"value":"SI","code":"Slovenia"},
    {"value":"SB","code":"Solomon Islands"},
    {"value":"SO","code":"Somalia"},
    {"value":"ZA","code":"South Africa"},
    {"value":"GS","code":"South Georgia and the South Sandwich Islands"},
    {"value":"SS","code":"South Sudan"},
    {"value":"ES","code":"Spain"},
    {"value":"LK","code":"Sri Lanka"},
    {"value":"SD","code":"Sudan"},
    {"value":"SR","code":"Suriname"},
    {"value":"SJ","code":"Svalbard and Jan Mayen"},
    {"value":"SZ","code":"Swaziland"},
    {"value":"SE","code":"Sweden"},
    {"value":"CH","code":"Switzerland"},
    {"value":"SY","code":"Syrian Arab Republic"},
    {"value":"TW","code":"Taiwan, Province of China[a]"},
    {"value":"TJ","code":"Tajikistan"},
    {"value":"TZ","code":"Tanzania, United Republic of"},
    {"value":"TH","code":"Thailand"},
    {"value":"TL","code":"Timor-Leste"},
    {"value":"TG","code":"Togo"},
    {"value":"TK","code":"Tokelau"},
    {"value":"TO","code":"Tonga"},
    {"value":"TT","code":"Trinidad and Tobago"},
    {"value":"TN","code":"Tunisia"},
    {"value":"TR","code":"Turkey"},
    {"value":"TM","code":"Turkmenistan"},
    {"value":"TC","code":"Turks and Caicos Islands"},
    {"value":"TV","code":"Tuvalu"},
    {"value":"UG","code":"Uganda"},
    {"value":"UA","code":"Ukraine"},
    {"value":"AE","code":"United Arab Emirates"},
    {"value":"GB","code":"United Kingdom of Great Britain and Northern Ireland"},
    {"value":"US","code":"United States of America"},
    {"value":"UM","code":"United States Minor Outlying Islands"},
    {"value":"UY","code":"Uruguay"},
    {"value":"UZ","code":"Uzbekistan"},
    {"value":"VU","code":"Vanuatu"},
    {"value":"VE","code":"Venezuela (Bolivarian Republic of)"},
    {"value":"VN","code":"Viet Nam"},
    {"value":"VG","code":"Virgin Islands (British)"},
    {"value":"VI","code":"Virgin Islands (U.S.)"},
    {"value":"WF","code":"Wallis and Futuna"},
    {"value":"EH","code":"Western Sahara"},
    {"value":"YE","code":"Yemen"},
    {"value":"ZM","code":"Zambia"},
    {"value":"ZW","code":"Zimbabwe"}
];
//flight PNR mode
export const SupplierType=[
    {name:'TotalStay',value:1},
    {name:'Sabre',value:2},
    {name:'HotelBeds',value:3},
    {name:'Stuba',value:4},
    {name:'Pegasus',value:5},
    {name:'Restel',value:6},
    {name:'HotelsPro',value:7},
    {name:'Amadeus',value:8},
    {name:'TravelFusion',value:9},
    {name:'AmadeusThr',value:10},
    {name:'Mahan',value:11},
    {name:'Farelogix',value:12}
];
export const TrackSupplierType=[
    {name:'Sabre',value:2},
    {name:'Amadeus',value:8},
    {name:'AmadeusThr',value:10},
    {name:'Farelogix',value:12}
];
export const FareTypeEnum=[
    {text:"Publish",value:"Publish"},
    {text:"Ethnic",value:"Ethnic"},
    {text:"Package",value:"Package"}

]
export const PNRStatus={
    Made:"Made",Priced:"Priced",Issued:"Issued",Voided:"Voided",Canceled: "Canceled"
}
export const bookStatus={
    process:"process",
    done:"done",
    now:"now",
    idle:"idle",
    error:"error"
};
export const CabinClass=['All','Economy','Premium','Business','Firstclass']
//qraphQL
export class ReserveInput{
    reserveTypeIds=null;
    reserveId=null;
    phoneNumber=null;
    email=null;
    lastname=null;
    reference=null;
    departureFrom=null;
    departureTo=null;
    createFrom=null;
    createTo=null;
    reserveStatus=null;
    pageNumber=0;
    pageSize=20;
}
export class ReserveItem{
    firstName=null;
    lastName=null;
    phoneNumber=null;
    email=null;
    id=null;
    createDate=null;
    departureDate=null;
    salePrice=null;
    saleCurrency=null;
    introPrice=null;
    introCurrency=null;
    discountPercent=null;
    discountPrice=null;
    statusId=null;
    terminal=null;
}
export class Reserves{
    reserveList=null;
    transactionList=null;
    gatewaysLogs=null;
    flightDetails=null;
    hotelDetails=null;
    reserveStatus=null;
    filterParam=new ReserveInput()
}
//Currency
export const CurrencyType={
    USD:"USD",
    EUR:"EUR",
    SEK:"SEK",
    NOK:"NOK",
    DKK:"DKK",
    IRR:"IRR",
    AED:"AED",
    GBP:"GBP",
    CNY:"CNY",
    CHF:"CHF",
    XOF:"XOF",
    CAD:"CAD",
    THB:"THB",
}
export const ReserveType={
    FLIGHT_GLOBAL:"FLIGHT_GLOBAL",
    HOTEL_GLOBAL:"HOTEL_GLOBAL",

}
export const PaymentType={
    Pending:"PENDING",
    Issued:"ISSUED",
    Canceled:"CANCELED",
    PaymentSuccessful:"PAYMENT_SUCCESSFUL",
    Refunded:"REFUNDED",
    Voided:"VOIDED",
    Priced:"PRICED",
    Failed:["PRICE_CHANGE","UNAVAILABLE","WEB_SERVICE_UNSUCCESSFUL"]

};
//available result API model
export class FlightAvailable{
    currency=null;
    airTripType=null;
    pricedItineraries=[];
}
export class HotelAvailable{
    country=null;
    city=null;
    date=null;
    hotels=[];
}
export const className=[
    "All","Economy","Premium","Business","Firstclass"
]
