//import library
import axios from 'axios';
import {Urls,Payment as PaymentURL,getHeaderRequest} from '../Enums/APIAddress';
class Payment{
    getReserveID=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.get(`${Urls.Type}${Urls.HotelV2}${PaymentURL.GetReserveInformation}?${param}`,{headers:{...header}});
            return response.data.result;
        }catch(error){
            return error
        }
    };
    getPaymentList=async(param)=>{

        let header=getHeaderRequest(true);
        try{
            let response=await axios.get(`${Urls.Type}${Urls.Payment}${PaymentURL.GetBanksInformation}?${param}`,{headers:{...header}});
            return response.data.result;
        }catch(error){
            return error
        }
    };
    sendPaymentMethod=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.post(`${Urls.Type}${Urls.Payment}${PaymentURL.MakeToken}`,param,{headers:{...header}});
            return response.data.result;
        }catch (error) {
            return error
        }
    };
    getDeposits=async()=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.get(`${Urls.Type}${Urls.Payment}${PaymentURL.GetDeposits}`,{headers:{...header}});
            return response.data.result;
        }catch(error){
            return error
        }
    };
    getDepositsGateway=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.get(`${Urls.Type}${Urls.Payment}${PaymentURL.GetDepositsGateway}?${param}`,{headers:{...header}});
            return response.data.result;
        }catch(error){
            return error
        }
    }
    sendDepositMethod=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.post(`${Urls.Type}${Urls.Payment}${PaymentURL.DepositMakeToken}`,param,{headers:{...header}});
            return response.data.result;
        }catch (error) {
            return error
        }
    }
    getDepositsBalance=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.get(`${Urls.Type}${Urls.Payment}${PaymentURL.GetDepositBalance}?${param}`,{headers:{...header}});
            return response.data.result;
        }catch(error){
            return error
        }
    }
    confirmDeposit=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.post(`${Urls.Type}${Urls.Payment}${PaymentURL.ConfirmDeposit}`,param,{headers:{...header}});
            return response;
        }catch(error){
            return error
        }
    }
    refundDeposit=async(param)=>{
        let header=getHeaderRequest(true);
        try{
            let response=await axios.post(`${Urls.Type}${Urls.Payment}${PaymentURL.RefundDeposit}`,param,{headers:{...header}});
            return response;
        }catch(error){
            return error
        }
    }
}
export default new Payment();