import {ReserveActions} from '../Enums/ActionTypes';
export const getReserves=(param)=>({type:ReserveActions.GetReserves,input:param});
export const setReserves=(param)=>({type:ReserveActions.SetReserves,input:param});
export const getFlightInfo=(id)=>({type:ReserveActions.getFlightInfo,input:id});
export const setFlightInfo=(param)=>({type:ReserveActions.setFlightInfo,input:param});
export const getHotelInfo=(id)=>({type:ReserveActions.getHotelInfo,input:id});
export const setHotelInfo=(param)=>({type:ReserveActions.setHotelInfo,input:param});
export const getTransactionInfo=(id)=>({type:ReserveActions.getTransaction,input:id});
export const  setTransactionInfo=(param)=>({type:ReserveActions.setTransaction,input:param});
export const getGatewaysInfo=(id)=>({type:ReserveActions.getGatewaysLog,input:id});
export const setGatewaysInfo=(param)=>({type:ReserveActions.setGatewaysLog,input:param});
export const getReserveStatus=()=>({type:ReserveActions.getReserveStatus});
export const setReserveStatus=(param)=>({type:ReserveActions.setReserveStatus,input:param});

